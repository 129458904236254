/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */

import { useEffect, useRef } from 'react';

const DEFAULT_OPTIONS = {
  zoomFactor: 2.25,
  hoverBoundingBox: true,
  handleTouch: false,
};

// global Drift Image
const useZoom = (container, picture, options = {}) => {
  const ref = useRef();
  useEffect(() => {
    let drift;
    const hideDrift = () => {
      if (drift) {
        drift.trigger._hide();
      }
    };

    if (options.enableZoom) {
      const node = ref.current.querySelector('img');
      /* Preload zoom image to avoid loader */
      const image = new Image();
      image.src = node.getAttribute('data-zoom');
      // eslint-disable-next-line no-undef
      drift = new Drift(node, {
        paneContainer: container.current,
        ...DEFAULT_OPTIONS,
        ...options,
      });

      document.addEventListener('scroll', hideDrift);
    }
    return () => {
      document.removeEventListener('scroll', hideDrift);
      if (drift) {
        drift.destroy();
      }
    };
  }, [container, picture]); // No cambiemos esto que rompe el zoom de la galería.
  return ref;
};

export default useZoom;
