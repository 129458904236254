import { SET_SMART_ANSWER, OPEN_QUESTION_FROM_SA_MODAL, CLOSE_SMART_ANSWER_MODAL } from '../../../../actions/vip/types';
import { MAKE_QUESTION_SA, SMART_ANSWER_MODAL } from '../../../../components/qadb/qadb.constants';

const setSmartAnswer = (state, action) => {
  let smart = state.components.qadb.qadb_data.components.find(component => component.id === SMART_ANSWER_MODAL);
  const makeQuestion = state.components.qadb.qadb_data.components.find(component => component.id === MAKE_QUESTION_SA);

  makeQuestion.initialValue = '';

  if (smart) {
    smart = action.payload;
  } else {
    state.components.qadb.qadb_data.components.push(action.payload);
  }

  return {
    ...state,
    components: {
      ...state.components,
      qadb: {
        ...state.components.qadb,
        smartAnswer: action.payload,
        smartAnswerModal: { isOpen: true, isAsking: false },
        makequestion: {
          ...state.components.qadb.makequestion,
          disabled: false,
        },
      },
    },
  };
};

const openQuestionFromSAModal = state => ({
  ...state,
  components: {
    ...state.components,
    qadb: {
      ...state.components.qadb,
      smartAnswerModal: {
        isOpen: false,
        isAsking: true,
      },
    },
  },
});

const closeSmartAnswerModal = state => ({
  ...state,
  components: {
    ...state.components,
    qadb: {
      ...state.components.qadb,
      smartAnswerModal: {
        isOpen: false,
        isAsking: false,
      },
    },
  },
});

const questionsMapper = {
  [SET_SMART_ANSWER]: setSmartAnswer,
  [OPEN_QUESTION_FROM_SA_MODAL]: openQuestionFromSAModal,
  [CLOSE_SMART_ANSWER_MODAL]: closeSmartAnswerModal,
};

export { setSmartAnswer, openQuestionFromSAModal, closeSmartAnswerModal };
export default questionsMapper;
