import { loadable } from 'nordic/lazy';

import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import * as actions from '../../../../actions/vip';
import withRow from '../../../../components/grid/row/with-row';

const InstallationService = loadable(() =>
  import('../../../../components/installation-service/components/container-installation'),
);
const FEATURE_NAME = 'installations';

const mapStateToProps = ({ components: { [FEATURE_NAME]: installations } }) => installations;

const mapDispatchToProps = dispatch => ({
  onUpdateInstallation: agency => dispatch(actions.updateInstallation(agency)),
  onRemoveInstallation: () => dispatch(actions.removeInstallation()),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(InstallationService);
