const priceMapper = ({ price } = {}) => {
  const { currency_symbol, value } = price;
  if (!currency_symbol || !value) {
    return null;
  }
  return {
    currencySymbol: currency_symbol,
    value,
  };
};

export default priceMapper;
