import {
  FETCH_QUESTION_AI_START,
  FETCH_QUESTION_AI_COMPLETE,
  FETCH_QUESTION_AI_ERROR,
  INITIAL_QUESION_AI,
} from '../../../../actions/vip/types';
import { getQuestions } from '../../../helpers/components';

const fetchQuestionAiStart = (state, action) => {
  const questionsComponent = getQuestions(action);
  return {
    ...state,
    questions: {
      ...state[questionsComponent],
      questions_ai: {
        ...state[questionsComponent].questions_ai,
        isFetching: true,
      },
    },
  };
};

const fetchQuestionAiComplete = (state, action) => {
  const questionsComponent = getQuestions(action);
  return {
    ...state,
    questions: {
      ...state[questionsComponent],
      questions_ai: {
        ...state[questionsComponent].questions_ai,
        ...action.payload,
        isFetching: false,
      },
    },
  };
};

const fetchQuestionAiError = (state, action) => {
  const questionsComponent = getQuestions(action);
  return {
    ...state,
    questions: {
      ...state[questionsComponent],
      questions_ai: {
        ...state[questionsComponent].questions_ai,
        isFetching: false,
      },
    },
    error: action.error,
  };
};

const initialQuesionAi = (state, action) => {
  const questionsComponent = getQuestions(action);
  return {
    ...state,
    [questionsComponent]: {
      ...state[questionsComponent],
      questions_ai: {
        ...state[questionsComponent].questions_ai,
        questions_ai_form: {
          ...state[questionsComponent].questions_ai.questions_ai_form,
          form: {
            ...state[questionsComponent].questions_ai?.questions_ai_form?.form,
            is_ask_seller: false,
          },
        },
      },
    },
  };
};

const questionAiMapper = {
  [FETCH_QUESTION_AI_START]: fetchQuestionAiStart,
  [FETCH_QUESTION_AI_COMPLETE]: fetchQuestionAiComplete,
  [FETCH_QUESTION_AI_ERROR]: fetchQuestionAiError,
  [INITIAL_QUESION_AI]: initialQuesionAi,
};

export { fetchQuestionAiStart, fetchQuestionAiComplete, fetchQuestionAiError, initialQuesionAi };
export default questionAiMapper;
