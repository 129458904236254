import { loadable } from 'nordic/lazy';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';

const BillboardAdn = loadable(() => import('../../../../components/advertising-adn'));

const FEATURE_NAME = 'billboard_context_adn';

const mapStateToProps = ({
  components: {
    [FEATURE_NAME]: { type, content, title },
  },
}) => ({ type, content, title });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(BillboardAdn);
