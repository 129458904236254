import React, { useState } from 'react';
import { string, func } from 'prop-types';

const namespace = 'ui-pdp-recaptcha-v2';

const ReCaptcha = ({ verifyCallback, id, recaptchaPublicKey, align }) => {
  let recaptchaV2 = null;
  const [isDisplayed, setIsDisplayed] = useState(false);

  const renderCallBack = token => {
    setIsDisplayed(true);
    if (verifyCallback) {
      verifyCallback(token);
    }
  };

  const renderRecaptcha = () => {
    recaptchaV2 = window.grecaptcha;
    if (recaptchaV2 && !isDisplayed) {
      recaptchaV2.enterprise.ready(() => {
        recaptchaV2.enterprise.render(id, {
          sitekey: recaptchaPublicKey,
          callback: renderCallBack,
        });
      });
    }
  };

  return (
    <div className={namespace}>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <div id={id} align={align} />
      {window && window.grecaptcha && renderRecaptcha()}
    </div>
  );
};

ReCaptcha.propTypes = {
  verifyCallback: func,
  id: string,
  recaptchaPublicKey: string,
  align: string,
};

ReCaptcha.defaultProps = {
  verifyCallback: null,
  id: null,
  recaptchaPublicKey: null,
  align: null,
};
export default ReCaptcha;
