import React from 'react';
import { shape, string, arrayOf } from 'prop-types';
import StyledLabel from '../../../styled-label';

const namespace = 'ui-vpp-highlighted-specs__highlighted-attributes-list';

const HSHighlightedAttributesList = ({ attributes }) => (
  <div className={namespace}>
    {attributes.map(({ name, value }) => (
      <div className={`${namespace}__attribute`} key={name.text}>
        <StyledLabel {...name} />
        <StyledLabel {...value} />
      </div>
    ))}
  </div>
);

HSHighlightedAttributesList.propTypes = {
  attributes: arrayOf(
    shape({
      name: shape({
        text: string,
        color: string,
      }),
      value: shape({
        text: string,
        color: string,
      }),
    }),
  ).isRequired,
};

export default HSHighlightedAttributesList;
