import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withRow from '../../../../components/grid/row/with-row';
import Message from '../../../../components/redirected-from/redirected-from';

const FEATURE_NAME = 'redirect_context';

const mapStateToProps = ({ redirect_context }) => ({ redirect_context });

const enhance = compose(
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(Message);
