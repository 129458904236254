const formatBulkQuantity = quantity => {
  if (!Number(quantity) || quantity === 0) {
    return '';
  }

  return quantity.toLocaleString('de-DE', {
    maximumFractionDigits: 2,
    useGrouping: false,
  });
};

export default formatBulkQuantity;
