import React from 'react';
import { string, node } from 'prop-types';

const colorMap = {
  BLACK: '0, 0, 0',
  WHITE: '255, 255, 255',
};

const StyledOverlay = ({ children, color, overlayAlpha, app, className }) => {
  const namespace = app === 'pdp' ? 'ui-pdp-overlay' : 'ui-vip-overlay';

  const colorValue = colorMap[color.toUpperCase()] || color;

  return (
    <div className={`${className} ${namespace}`} style={{ backgroundColor: `rgba(${colorValue}, ${overlayAlpha})` }}>
      {children}
    </div>
  );
};

StyledOverlay.propTypes = {
  className: string.isRequired,
  overlayAlpha: string.isRequired,
  children: node,
  color: string.isRequired,
  app: string,
};

export default StyledOverlay;
