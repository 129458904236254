import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import * as actions from '../../../../actions/vip';

const FEATURE_NAME = 'preload';

const mapStateToProps = ({ app, preload }) => ({
  app,
  preload,
});

const mapDispatchToProps = dispatch => ({
  preloadIframeClose: () => dispatch(actions.preloadIframeClose()),
  preloadIframeUpdate: params => dispatch(actions.preloadIframeUpdate(params)),
  preloadIframeLoaded: () => dispatch(actions.preloadIframeLoaded()),
  onDemandIframeUpdate: params => dispatch(actions.onDemandIframeUpdate(params)),
  showSnackbar: snackbar => dispatch(actions.showSnackbar(snackbar)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME, { pathToComponent: `${FEATURE_NAME}` }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const preloadIframe = PreloadIframe => enhance(PreloadIframe);

export default preloadIframe;
export { mapDispatchToProps };
