import React from 'react';
import { string } from 'prop-types';

const ICON_ID = 'poly_star';

const IconStarEmpty = ({ className }) => (
  <svg className={className} width="16.8" height="16" viewBox="0 0 11 10">
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconStarEmpty.propTypes = {
  className: string,
};

IconStarEmpty.defaultProps = {
  className: '',
};

export default React.memo(IconStarEmpty);
export { ICON_ID };
