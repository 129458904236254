import React, { useContext } from 'react';
import StaticPropsContext from '../../context/static-props';
import QuantitySelectorDesktop from './components/quantity-selector/quantity-selector.desktop';
import QuantityNative from './components/quantity-native/quantity-native';
import QuantityTrigger from './components/quantity-trigger/quantity-trigger';
import StyledLabel from '../../styled-label';
import Quantity from './quantity';
import customOptions from '../../../utils/quantity-custom-options';
import { quantityDesktopPropTypes } from './model';

const QuantityDesktop = ({
  className,
  quantity_selector,
  picker,
  showInput,
  label,
  type,
  showQuantityInput = false,
  showInputLowend = true,
  onSetQuantity,
  onShowInput = false,
  buttonFocus = false,
}) => {
  const yieldValue = quantity_selector?.selector?.template?.yield;
  const bulkSale = !!yieldValue;
  const { lowEnd } = useContext(StaticPropsContext);
  const hasCustomOptions = customOptions.has(quantity_selector?.selector?.template?.custom_options);

  if (lowEnd && !showInputLowend) {
    // @TODO check diff between PDS & PDP until UX has a better alternative
    return null;
  }

  if (type === 'label_component') {
    return (
      <Quantity className={className} picker={picker}>
        <StyledLabel {...label} />
      </Quantity>
    );
  }

  if (picker) {
    if (lowEnd) {
      return <QuantityNative message={picker.description} quantity={picker.selected} />;
    }

    return (
      <Quantity className={className} picker={picker} hasCustomOptions={hasCustomOptions}>
        {quantity_selector ? (
          <QuantitySelectorDesktop
            message={picker.description}
            setQuantity={onSetQuantity}
            showInput={showQuantityInput || showInput}
            onShowInput={onShowInput}
            picker={picker}
            quantitySelector={quantity_selector}
            buttonFocus={buttonFocus}
          />
        ) : (
          <QuantityTrigger
            title={picker.title}
            selectedLabel={picker.selected_label && picker.selected_label.text}
            message={picker.description}
            active={false}
            bulkSale={bulkSale}
            disabled
          />
        )}
      </Quantity>
    );
  }

  return null;
};

QuantityDesktop.propTypes = quantityDesktopPropTypes;

export default QuantityDesktop;
