import { arrayOf, bool, shape, string } from 'prop-types';

const LabelType = shape({
  text: string.isRequired,
});

const ActionType = shape({
  label: LabelType.isRequired,
});

const RowType = arrayOf(
  shape({
    key: string,
    value: string,
  }),
);

const TableType = shape({
  headers: arrayOf(string),
  rows: arrayOf(RowType),
});

const MessageType = shape({
  label: shape({ text: string }).isRequired,
  label2: shape({ text: string }),
  action: shape({ label: shape({ text: string }), action_type: string }),
  type: string.isRequired,
});

const ItemType = shape({
  input: string,
  value: string,
});

const ValueType = shape({
  attribute_id: string,
  value_id: string.isRequired,
  value_name: string.isRequired,
});

const CompatibleValuePropType = {
  label: string,
  value: string,
  values: arrayOf(ValueType),
};

const CompatibleValueType = shape(CompatibleValuePropType);

const InputType = shape({
  label: shape({
    id: string,
    label: string,
  }),
  required: bool,
  values: arrayOf(CompatibleValueType),
});

const ModalType = shape({
  title: string.isRequired,
  description: string.isRequired,
  help_text: string.isRequired,
  action: ActionType,
  user_selection: string,
  table: TableType,
  message: MessageType,
});

export { ActionType, CompatibleValueType, InputType, LabelType, MessageType, ModalType, RowType, TableType, ItemType };
