import React from 'react';
import { string, oneOf } from 'prop-types';
import classnames from 'classnames';
import Message from '../message';
import { validateSnackbarColor } from '../../utils/validators';

const namespace = 'ui-pdp-flash-message';

const FlashMessage = ({ type, text, className }) => (
  <Message className={classnames(namespace, className)} color={validateSnackbarColor(type)} closable hierarchy="loud">
    <div className={`${namespace}__text`}>{text}</div>
  </Message>
);

FlashMessage.propTypes = {
  className: string,
  text: string.isRequired,
  type: oneOf(['neutral', 'success', 'warning', 'error']),
};

FlashMessage.defaultProps = {
  className: null,
  type: null,
};

export default FlashMessage;
