import React from 'react';
import { arrayOf, shape, string, number } from 'prop-types';
import serialize from 'serialize-javascript';
import { Script } from 'nordic/script';
import { generateKeyToComponent } from '../../utils/validators';
import { urlWithSanitizedTitle } from '../../lib/id-to-url';

const Schema = ({ schemaData, sanitizedTitle }) => {
  if (schemaData) {
    return schemaData.map(schema => {
      if (schema.image) {
        schema.image = urlWithSanitizedTitle(schema.image, sanitizedTitle);
      }
      return (
        <Script key={generateKeyToComponent(schema)} type="application/ld+json">
          {serialize(schema, { isJSON: true })}
        </Script>
      );
    });
  }
  return null;
};

Schema.propTypes = {
  schemaData: arrayOf(
    shape({
      schema: string,
      i: number,
    }),
  ),
  sanitizedTitle: string,
};

Schema.defaultProps = {
  schemaData: null,
  sanitizedTitle: null,
};

export default Schema;
