import {
  FETCH_COMPATIBLE_POSIBLE_VALUES_START,
  FETCH_COMPATIBLE_POSIBLE_VALUES_ERROR,
  FETCH_COMPATIBLE_POSIBLE_VALUES_COMPLETE,
} from '../../../../actions/vip/types';

const fetchCompatiblePosibleValuesStart = state => ({
  ...state,
  components: {
    ...state.components,
    compatibilities: {
      ...state.components.compatibilities,
      isFetchingFilter: true,
      successFilter: null,
    },
  },
});

const fetchCompatiblePosibleValuesError = state => ({
  ...state,
  components: {
    ...state.components,
    compatibilities: {
      ...state.components.compatibilities,
      isFetchingFilter: false,
      successFilter: false,
    },
  },
});

const fetchCompatiblePosibleValuesComplete = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    compatibilities: {
      ...state.components.compatibilities,
      isFetchingFilter: false,
      successFilter: true,
      inputs: action.inputs,
      message: action.message,
    },
  },
});

const compatibleMapper = {
  [FETCH_COMPATIBLE_POSIBLE_VALUES_START]: fetchCompatiblePosibleValuesStart,
  [FETCH_COMPATIBLE_POSIBLE_VALUES_ERROR]: fetchCompatiblePosibleValuesError,
  [FETCH_COMPATIBLE_POSIBLE_VALUES_COMPLETE]: fetchCompatiblePosibleValuesComplete,
};

export { fetchCompatiblePosibleValuesStart, fetchCompatiblePosibleValuesError, fetchCompatiblePosibleValuesComplete };
export default compatibleMapper;
