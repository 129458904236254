const findActionSelected = (state, actionKey) => {
  const {
    components: {
      main_actions: { actions },
    },
  } = state;

  const findAction =
    actions.find(action => action.label.text === actionKey) || actions.find(action => action.action_type === actionKey);

  if (findAction) {
    return findAction;
  }

  return {};
};

export default findActionSelected;
