const mapJsonToProps = (
  { id, type, state, components, viewport_track },
  recommendations_context,
  globalContext,
  ownProps,
) => ({
  id,
  type,
  state,
  components,
  viewport_track,
  recommendations_context,
  globalContext,
  ...ownProps,
});

export default mapJsonToProps;
