import { PROVIDER } from '../../figure/constants';

const getLightboxPictures = ({ figures, filterImagesAndClips }) =>
  figures.filter(filterImagesAndClips).map(item => {
    if (item.provider === PROVIDER.CLIP) {
      return item;
    }

    return {
      ...item,
      src: (item.zoom && item.zoom.src) || item.src,
      msrc: item.src,
      thumbnail: item.src,
    };
  });

export default getLightboxPictures;
