import React from 'react';
import { bool, shape, string, number } from 'prop-types';
import { ActionCollapsable } from '../action-collapsable';
import Description from './description';
import { isCollapsable } from './helpers';
import classnames from 'classnames';
import updateComponentPositionWhenSticky from '../../utils/update-component-position-when-sticky/update-component-position-when-sticky';

const namespace = 'ui-pdp-description-collapse';
const namespaceRex = 'ui-pdp-description-collapse-rex';
const FEATURE_NAME_REX = 'description_rex';

const DescriptionDesktop = ({
  action,
  className,
  content,
  custom_message,
  title,
  collapsable,
  show_track,
  component_id,
  forceShowAction,
}) => {
  const handleClick = () => {
    updateComponentPositionWhenSticky('center');
  };

  return isCollapsable(collapsable) ? (
    <ActionCollapsable
      action={action}
      scrollToTop={false}
      onClickAction={handleClick}
      maxCollapsed={collapsable?.height}
      collapseBy={collapsable?.collapse_by}
      className={classnames(namespace, {
        [namespaceRex]: FEATURE_NAME_REX === component_id,
      })}
      show_track={show_track}
      forceShowAction={forceShowAction}
    >
      <Description
        className={className}
        content={content}
        title={title}
        custom_message={custom_message}
        show_track={show_track}
      />
    </ActionCollapsable>
  ) : (
    <Description
      className={className}
      content={content}
      title={title}
      custom_message={custom_message}
      show_track={show_track}
    />
  );
};

DescriptionDesktop.propTypes = {
  action: shape({
    label: shape({
      text: string.isRequired,
      color: string,
    }).isRequired,
    target: string,
  }).isRequired,
  className: string,
  content: string.isRequired,
  custom_message: shape({
    state: string,
    body: shape({ test: string }),
    closeable: bool,
    hierarchy: string,
    message_type: string,
  }),
  title: string.isRequired,
  collapsable: shape({ height: number, collapse_by: string }),
  component_id: string.isRequired,
  show_track: shape({}),
  forceShowAction: bool,
};

DescriptionDesktop.defaultProps = {
  className: '',
  custom_message: null,
  collapsable: null,
  show_track: null,
  forceShowAction: false,
};

export default DescriptionDesktop;
