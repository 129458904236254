import React from 'react';
import { shape, func } from 'prop-types';
import classnames from 'classnames';
import colornames from '../../../lib/colornames';
import { namespace } from '../utils';
import IconChevron from '../../icons/chevron';
import customPropTypes from '../utils/proptypes';
import { trackEvent } from '../../../lib/tracking';

const SeeMoreVariations = ({ picker, limitedVariations, setPickersCollapse }) => (
  <p>
    <span
      data-testid="see-more-variations"
      className={classnames(`${namespace}__expand-action`, colornames(limitedVariations.label_dto.color))}
      onClick={() => {
        setPickersCollapse(picker.Id);
        trackEvent(limitedVariations.track);
      }}
      role="button"
      tabIndex="0"
      onKeyUp={() => {
        setPickersCollapse(picker.Id);
        trackEvent(limitedVariations.track);
      }}
    >
      {limitedVariations.label_dto.text}
      <IconChevron aria-hidden="true" className={classnames(`${namespace}__chevron ui-pdp-icon--chevron-down`)} />
    </span>
  </p>
);

SeeMoreVariations.propTypes = {
  picker: customPropTypes.picker,
  limitedVariations: shape(),
  setPickersCollapse: func,
};

export default SeeMoreVariations;
