import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import componentEnhance from '../../../../lib/component-enhance';
import classnames from 'classnames';
import applyClasses from '../../../../lib/apply-classes';

const RatingLevelsTitle = ({ className, title }) => (
  <p
    className={classnames(
      className,
      applyClasses({
        color: title.color,
        size: title.font_size,
        family: title.font_family,
      }),
    )}
  >
    {componentEnhance.jsx(title.text, title.values)}
  </p>
);

RatingLevelsTitle.propTypes = {
  className: string.isRequired,
  title: shape({
    text: string.isRequired,
    color: string,
    font_size: string,
    font_family: string,
    values: arrayOf(
      shape({
        value: shape({
          text: string.isRequired,
          color: string,
          font_size: string,
          font_family: string,
        }).isRequired,
      }),
    ),
  }).isRequired,
};

export default RatingLevelsTitle;
