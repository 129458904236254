const FETCH_START = 'FETCH_START';
const FETCH_END = 'FETCH_END';
const FETCH_ERROR = 'FETCH_ERROR';
const FETCH_ON_SET_BOOKMARK_COMPLETE = 'FETCH_ON_SET_BOOKMARK_COMPLETE';
const TOGGLE_BOOKMARK_OPTIMISTICALLY = 'TOGGLE_BOOKMARK_OPTIMISTICALLY';
const FETCH_COMPLETE = 'FETCH_COMPLETE';
const QUANTITY_SHOW_INPUT_CHANGE = 'QUANTITY_SHOW_INPUT_CHANGE';
const FETCH_ON_SET_QUANTITY_START = 'FETCH_ON_SET_QUANTITY_START';
const FETCH_ON_SET_QUANTITY_COMPLETE = 'FETCH_ON_SET_QUANTITY_COMPLETE';
const FETCH_ON_SET_HI_QUANTITY_COMPLETE = 'FETCH_ON_SET_HI_QUANTITY_COMPLETE';
const FETCH_ON_SHIPPING_UPDATE_COMPLETE = 'FETCH_ON_SHIPPING_UPDATE_COMPLETE';
const FETCH_ON_SHIPPING_UPDATE_START = 'FETCH_ON_SHIPPING_UPDATE_START';
const FETCH_PRODUCT_VARIATIONS_DETAILS = 'FETCH_PRODUCT_VARIATIONS_DETAILS';
const SHOW_HI_QUANTITY_ERROR = 'SHOW_HI_QUANTITY_ERROR';
const HIDE_HI_QUANTITY_ERROR = 'HIDE_HI_QUANTITY_ERROR';
const SET_QUANTITY = 'SET_QUANTITY';
const SHOW_QUANTITY_ERROR = 'SHOW_QUANTITY_ERROR';
const SHOW_VARIATIONS_ERROR_DESKTOP = 'SHOW_VARIATIONS_ERROR_DESKTOP';
const SHOW_VARIATIONS_ERROR_MOBILE = 'SHOW_VARIATIONS_ERROR_MOBILE';
const TOGGLE_BOOKMARKED = 'TOGGLE_BOOKMARKED';

/* Questions */
const FETCH_MORE_QUESTIONS_START = 'FETCH_MORE_QUESTIONS_START';
const FETCH_MORE_QUESTIONS_COMPLETE_DESKTOP = 'FETCH_MORE_QUESTIONS_COMPLETE_DESKTOP';
const FETCH_MORE_QUESTIONS_COMPLETE_MOBILE = 'FETCH_MORE_QUESTIONS_COMPLETE_MOBILE';
const CREATE_QUESTION_START = 'CREATE_QUESTION_START';
const CREATE_QUESTION_COMPLETE = 'CREATE_QUESTION_COMPLETE';
const CREATE_QUESTION_FROM_AI_COMPLETE = 'CREATE_QUESTION_FROM_AI_COMPLETE';
const CREATE_QUESTION_COMPLETE_VIEW = 'CREATE_QUESTION_COMPLETE_VIEW';
const CREATE_QUESTION_ERROR = 'CREATE_QUESTION_ERROR';
const MAKE_QUESTION = 'MAKE_QUESTION';
const MAKE_QUESTION_SUCCESS = 'MAKE_QUESTION_SUCCESS';
const MAKE_QUESTION_ERROR = 'MAKE_QUESTION_ERROR';
const SET_SMART_ANSWER = 'SET_SMART_ANSWER';
const CLOSE_SMART_ANSWER_MODAL = 'CLOSE_SMART_ANSWER_MODAL';
const OPEN_QUESTION_FROM_SA_MODAL = 'OPEN_QUESTION_FROM_SA_MODAL';
/* A2C */
const ADD_TO_CART_UPDATE_START = 'ADD_TO_CART_UPDATE_START';
const ADD_TO_CART_UPDATE_COMPLETE = 'ADD_TO_CART_UPDATE_COMPLETE';
const ADD_TO_CART_UPDATE_ERROR = 'ADD_TO_CART_UPDATE_ERROR';

/* Compatibilities */
const FETCH_COMPATIBLE_POSIBLE_VALUES_START = 'FETCH_COMPATIBLE_POSIBLE_VALUES_START';
const FETCH_COMPATIBLE_POSIBLE_VALUES_COMPLETE = 'FETCH_COMPATIBLE_POSIBLE_VALUES_COMPLETE';
const FETCH_COMPATIBLE_POSIBLE_VALUES_ERROR = 'FETCH_COMPATIBLE_POSIBLE_VALUES_ERROR';
const SET_COMPATIBILITIES_INPUT_VALUE = 'SET_COMPATIBILITIES_INPUT_VALUE';
const FETCH_COMPATIBILITIES_WITH_USER_SELECTION_START = 'FETCH_COMPATIBILITIES_WITH_USER_SELECTION_START';
const FETCH_COMPATIBILITIES_WITH_USER_SELECTION_COMPLETE = 'FETCH_COMPATIBILITIES_WITH_USER_SELECTION_COMPLETE';
const FETCH_COMPATIBILITIES_WITH_USER_SELECTION_ERROR = 'FETCH_COMPATIBILITIES_WITH_USER_SELECTION_ERROR';

/* Compats Widget */
const FETCH_COMPATS_SEARCH_START = 'FETCH_COMPATS_SEARCH_START';
const FETCH_COMPATS_SEARCH_COMPLETE = 'FETCH_COMPATS_SEARCH_COMPLETE';
const FETCH_COMPATS_SEARCH_COMPLETE_MOBILE = 'FETCH_COMPATS_SEARCH_COMPLETE_MOBILE';
const FETCH_COMPATS_SEARCH_STICKY_START = 'FETCH_COMPATS_SEARCH_STICKY_START';
const FETCH_COMPATS_SEARCH_STICKY_COMPLETE = 'FETCH_COMPATS_SEARCH_STICKY_COMPLETE';
const FETCH_COMPATS_SEARCH_STICKY_COMPLETE_MOBILE = 'FETCH_COMPATS_SEARCH_STICKY_COMPLETE_MOBILE';
const FETCH_COMPATS_SEARCH_ERROR = 'FETCH_COMPATS_SEARCH_ERROR';
const FETCH_COMPATS_DELETE_START = 'FETCH_COMPATS_DELETE_START';
const FETCH_COMPATS_DELETE_COMPLETE = 'FETCH_COMPATS_DELETE_COMPLETE';
const FETCH_COMPATS_DELETE_COMPLETE_MOBILE = 'FETCH_COMPATS_DELETE_COMPLETE_MOBILE';
const FETCH_COMPATS_DELETE_ERROR = 'FETCH_COMPATS_DELETE_ERROR';
const FETCH_COMPATS_FEEDBACK_CLOSE = 'FETCH_COMPATS_FEEDBACK_CLOSE';

/* Coupons */
const FETCH_ON_COUPON_UPDATE_START = 'FETCH_ON_COUPON_UPDATE_START';
const FETCH_ON_COUPON_UPDATE_COMPLETE = 'FETCH_ON_COUPON_UPDATE_COMPLETE';
const FETCH_ON_COUPON_UPDATE_ERROR = 'FETCH_ON_COUPON_UPDATE_ERROR';

/* Meliplus */
const FETCH_ON_MELIPLUS_UPDATE_START = 'FETCH_ON_MELIPLUS_UPDATE_START';
const FETCH_ON_MELIPLUS_UPDATE_COMPLETE = 'FETCH_ON_MELIPLUS_UPDATE_COMPLETE';

const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
const HIDE_SNACKBAR = 'HIDE_SNACKBAR';

/* Cart Congrats */
const CART_CONGRATS_UPDATE = 'CART_CONGRATS_UPDATE';
const ON_DEMAND_IFRAME = 'ON_DEMAND_IFRAME';
const FETCH_ON_DEMAND_IFRAME = 'FETCH_ON_DEMAND_IFRAME';

/* 1pay4all */
const FETCH_SEQUENCER = 'FETCH_SEQUENCER';
const PRELOAD_IFRAME = 'PRELOAD_IFRAME';

/* Wishlist */
const WISHLIST_SHOW_BOTTOMSHEET_MODAL = 'WISHLIST_SHOW_BOTTOMSHEET_MODAL';
const WISHLIST_CLOSE_BOTTOMSHEET_MODAL = 'WISHLIST_CLOSE_BOTTOMSHEET_MODAL';
const UPDATE_GIFT_REGISTRY = 'UPDATE_GIFT_REGISTRY';
const UPDATE_GIFT_REGISTRY_CHECKBOX = 'UPDATE_GIFT_REGISTRY_CHECKBOX';

/* Followers */
const TOGGLE_FOLLOW_OPTIMISTICALLY = 'TOGGLE_FOLLOW_OPTIMISTICALLY';
const FETCH_ON_SET_FOLLOW_COMPLETE = 'FETCH_ON_SET_FOLLOW_COMPLETE';

/* Questions AI */
const FETCH_QUESTION_AI_START = 'FETCH_QUESTION_AI_START';
const FETCH_QUESTION_AI_COMPLETE = 'FETCH_QUESTION_AI_COMPLETE';
const FETCH_UPDATE_QUANTITY = 'FETCH_UPDATE_QUANTITY';
const FETCH_UPDATE_CARD = 'FETCH_UPDATE_CARD';
const FETCH_QUESTION_AI_ERROR = 'FETCH_QUESTION_AI_ERROR';
const INITIAL_QUESION_AI = 'INITIAL_QUESION_AI';

/* Installations */
const FETCH_ON_CHANGE_INSTALLATION_COMPLETE = 'FETCH_ON_CHANGE_INSTALLATION_COMPLETE';
const FETCH_ON_CHANGE_INSTALLATION_START = 'FETCH_ON_CHANGE_INSTALLATION_START';
const FETCH_ON_CHANGE_INSTALLATION_END = 'FETCH_ON_CHANGE_INSTALLATION_END';

export {
  FETCH_SEQUENCER,
  FETCH_START,
  FETCH_END,
  FETCH_ERROR,
  FETCH_ON_SET_BOOKMARK_COMPLETE,
  TOGGLE_BOOKMARK_OPTIMISTICALLY,
  FETCH_COMPLETE,
  TOGGLE_BOOKMARKED,
  QUANTITY_SHOW_INPUT_CHANGE,
  FETCH_ON_SET_QUANTITY_START,
  FETCH_ON_SET_QUANTITY_COMPLETE,
  FETCH_ON_SET_HI_QUANTITY_COMPLETE,
  FETCH_ON_SHIPPING_UPDATE_START,
  FETCH_ON_SHIPPING_UPDATE_COMPLETE,
  FETCH_PRODUCT_VARIATIONS_DETAILS,
  SHOW_HI_QUANTITY_ERROR,
  HIDE_HI_QUANTITY_ERROR,
  SET_QUANTITY,
  SHOW_QUANTITY_ERROR,
  SHOW_VARIATIONS_ERROR_DESKTOP,
  SHOW_VARIATIONS_ERROR_MOBILE,
  FETCH_MORE_QUESTIONS_START,
  FETCH_MORE_QUESTIONS_COMPLETE_DESKTOP,
  FETCH_MORE_QUESTIONS_COMPLETE_MOBILE,
  CREATE_QUESTION_START,
  CREATE_QUESTION_COMPLETE,
  CREATE_QUESTION_FROM_AI_COMPLETE,
  CREATE_QUESTION_COMPLETE_VIEW,
  CREATE_QUESTION_ERROR,
  MAKE_QUESTION,
  MAKE_QUESTION_SUCCESS,
  MAKE_QUESTION_ERROR,
  SET_SMART_ANSWER,
  CLOSE_SMART_ANSWER_MODAL,
  OPEN_QUESTION_FROM_SA_MODAL,
  ADD_TO_CART_UPDATE_START,
  ADD_TO_CART_UPDATE_COMPLETE,
  ADD_TO_CART_UPDATE_ERROR,
  FETCH_COMPATIBLE_POSIBLE_VALUES_START,
  FETCH_COMPATIBLE_POSIBLE_VALUES_COMPLETE,
  FETCH_COMPATIBLE_POSIBLE_VALUES_ERROR,
  SET_COMPATIBILITIES_INPUT_VALUE,
  FETCH_COMPATIBILITIES_WITH_USER_SELECTION_START,
  FETCH_COMPATIBILITIES_WITH_USER_SELECTION_COMPLETE,
  FETCH_COMPATIBILITIES_WITH_USER_SELECTION_ERROR,
  FETCH_COMPATS_SEARCH_START,
  FETCH_COMPATS_SEARCH_COMPLETE,
  FETCH_COMPATS_SEARCH_COMPLETE_MOBILE,
  FETCH_COMPATS_SEARCH_STICKY_START,
  FETCH_COMPATS_SEARCH_STICKY_COMPLETE,
  FETCH_COMPATS_SEARCH_STICKY_COMPLETE_MOBILE,
  FETCH_COMPATS_SEARCH_ERROR,
  FETCH_COMPATS_DELETE_START,
  FETCH_COMPATS_DELETE_COMPLETE,
  FETCH_COMPATS_DELETE_COMPLETE_MOBILE,
  FETCH_COMPATS_DELETE_ERROR,
  FETCH_COMPATS_FEEDBACK_CLOSE,
  FETCH_ON_COUPON_UPDATE_START,
  FETCH_ON_COUPON_UPDATE_COMPLETE,
  FETCH_ON_COUPON_UPDATE_ERROR,
  FETCH_ON_MELIPLUS_UPDATE_START,
  FETCH_ON_MELIPLUS_UPDATE_COMPLETE,
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
  PRELOAD_IFRAME,
  ON_DEMAND_IFRAME,
  CART_CONGRATS_UPDATE,
  WISHLIST_SHOW_BOTTOMSHEET_MODAL,
  WISHLIST_CLOSE_BOTTOMSHEET_MODAL,
  FETCH_ON_DEMAND_IFRAME,
  UPDATE_GIFT_REGISTRY,
  TOGGLE_FOLLOW_OPTIMISTICALLY,
  FETCH_ON_SET_FOLLOW_COMPLETE,
  FETCH_QUESTION_AI_START,
  FETCH_QUESTION_AI_COMPLETE,
  FETCH_UPDATE_QUANTITY,
  FETCH_UPDATE_CARD,
  FETCH_QUESTION_AI_ERROR,
  UPDATE_GIFT_REGISTRY_CHECKBOX,
  INITIAL_QUESION_AI,
  FETCH_ON_CHANGE_INSTALLATION_COMPLETE,
  FETCH_ON_CHANGE_INSTALLATION_START,
  FETCH_ON_CHANGE_INSTALLATION_END,
};
