import React, { useState, useEffect } from 'react';

const useGetPositionCompatsSticky = ref => {
  const [initialPosition, setInitialPosition] = useState();
  const [verticalPosition, setVerticalPosition] = useState();
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [ticking, setTicking] = useState(false);

  /* istanbul ignore next */
  const getPosition = () => {
    const y = ref?.current?.offsetTop;
    setVerticalPosition(y);

    if (!ticking) {
      window.requestAnimationFrame(() => {
        if (verticalPosition > initialPosition) {
          setIsIntersecting(true);
        } else {
          setIsIntersecting(false);
        }
        setTicking(false);
      });
      setTicking(true);
    }
  };

  useEffect(() => {
    setInitialPosition(ref?.current?.offsetTop);
  }, [ref]);

  useEffect(() => {
    window.addEventListener('scroll', getPosition);
    return () => {
      window.removeEventListener('scroll', getPosition);
    };
  });

  return [isIntersecting];
};

const useGetCompatsSticky = (ref, active) => {
  const [isStickyActive, setIsStickyActive] = useState(false);

  useEffect(() => {
    /* istanbul ignore next */
    const handleScroll = () => {
      if (active) {
        const stickyElement = ref.current;
        if (stickyElement) {
          const stickyPosition = stickyElement.getBoundingClientRect();
          setIsStickyActive(stickyPosition.top <= 0);
        }
      }
    };
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [active, ref]);

  return [isStickyActive];
};

export { useGetPositionCompatsSticky, useGetCompatsSticky };
