import DEFAULT_CONFIG from '../config/default-config';
import galleryMapper from '../mappers/gallery-mapper';

const galleryConfig = {
  ...DEFAULT_CONFIG,
  isCritical: false,
  mapComponentInfoToProps: galleryMapper,
};

export default galleryConfig;
