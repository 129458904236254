import React, { useState } from 'react';
import { string, shape, func, arrayOf, bool } from 'prop-types';
import classnames from 'classnames';
import ProgressIndicatorCircular from '@andes/progress-indicator-circular';
import MediaAction from '../../media/media-action';
import { Recaptchav2, Recaptchav3 } from '../../recaptcha';

const namespace = 'ui-seller-info__status-info';

const StatusInfoVis = ({
  className,
  iconClassName,
  titleClassName,
  subtitleClassName,
  icon,
  title,
  subtitle,
  actionLink,
  itemId,
  fetchContactInfo,
  phones,
  isFetchingData,
  recaptchaPublicKey,
  recaptchaSiteKey,
}) => {
  const [recaptchaV2Token, setRecaptchaV2Token] = useState(null);

  const fetchPhones = e => {
    e.preventDefault();
    return (
      fetchContactInfo &&
      fetchContactInfo({
        itemId,
        contactType: 'show-phones',
        action: 'classi_contact',
        recaptchav2token: recaptchaV2Token,
      })
    );
  };

  const buildPhone = () => {
    if (isFetchingData) {
      const spinner = {
        modifier: 'inline',
      };
      return <ProgressIndicatorCircular {...spinner} />;
    }
    if (phones) {
      return (
        <div className={classnames(`${namespace}__subtitle`, subtitleClassName)}>
          <ul>
            {phones.map(phone => (
              <li key={phone.text}>{phone.text}</li>
            ))}
          </ul>
        </div>
      );
    }
    return (
      <div className={`${namespace}-phones`}>
        {recaptchaPublicKey && (
          <Recaptchav2
            recaptchaPublicKey={recaptchaPublicKey}
            required
            lowEnd
            id={`${namespace}-recaptcha`}
            verifyCallback={token => {
              setRecaptchaV2Token(token);
            }}
          />
        )}
        <MediaAction label={actionLink.label} onClick={e => fetchPhones(e)} />
      </div>
    );
  };

  return (
    <div className={classnames(namespace, className)}>
      {icon && (
        <figure aria-hidden="true" className={classnames(`${namespace}__icon`, iconClassName)}>
          {icon}
        </figure>
      )}
      <div>
        <h3 className={classnames(`${namespace}__title`, titleClassName)}>{title}</h3>
        <p className={classnames(`${namespace}__subtitle`, subtitleClassName)}>{subtitle}</p>
        {actionLink && <div className={classnames(`${namespace}__container-phones`)}>{buildPhone()}</div>}
      </div>
      {actionLink && <Recaptchav3 siteKey={recaptchaSiteKey} />}
    </div>
  );
};

StatusInfoVis.propTypes = {
  className: string,
  iconClassName: string,
  titleClassName: string,
  subtitleClassName: string,
  icon: shape({ id: string }),
  title: string,
  subtitle: string,
  actionLink: shape({ label: shape({ text: string }) }),
  itemId: string,
  fetchContactInfo: func,
  phones: arrayOf(
    shape({
      target: string,
      label: shape({
        text: string,
      }),
    }),
  ),
  isFetchingData: bool,
  recaptchaPublicKey: string,
  recaptchaSiteKey: string,
};

StatusInfoVis.defaultProps = {
  className: null,
  iconClassName: null,
  titleClassName: null,
  subtitleClassName: null,
  icon: null,
  title: null,
  subtitle: null,
  actionLink: null,
  itemId: null,
  fetchContactInfo: null,
  phones: null,
  isFetchingData: null,
  recaptchaPublicKey: null,
  recaptchaSiteKey: null,
};

export default StatusInfoVis;
