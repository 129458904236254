import React, { useState } from 'react';
import { arrayOf, bool, string, shape, func, oneOfType, node, number } from 'prop-types';
import classnames from 'classnames';
import { trackEvent } from '../../lib/tracking';
import Media from '../media';
import colornames from '../../lib/colornames';
import componentEnhance from '../../lib/component-enhance';
import StyledLabel from '../styled-label';
import onIframeModalResize from '../../lib/on-iframe-modal-resize';
import getAction from './_get-action';

const namespace = 'ui-vpp-coupons'; // // Make The Modal with iframe

const Coupons = ({
  action,
  children,
  className,
  onCouponUpdate,
  showSnackbar,
  subtitles,
  title,
  isFetching,
  snackbar,
  handleOnSubtitleClick,
  device,
  actionModalRef,
  actionModalProps,
}) => {
  const [snackbarMessage, setSnackbarMessage] = useState(false);

  let adaptedSubtitles = null;

  if (subtitles) {
    adaptedSubtitles = subtitles.slice();
  }

  const subtitleOnClick = subtitle => () => {
    handleOnSubtitleClick();
    if (subtitle?.action?.track) {
      trackEvent(subtitle.action.track);
    }
  };

  if (snackbar && snackbar.message && snackbarMessage !== snackbar.message) {
    setSnackbarMessage(snackbar.message);
    showSnackbar({
      ...snackbar,
      delay: snackbar.duration,
      called_from: 'coupon_summary',
    });
  }

  return (
    <div className={classnames(namespace, className)}>
      {title && (
        <Media
          className={classnames(`${namespace}--md`, `${namespace}__title`, colornames(title?.color))}
          title={title}
        />
      )}

      {children && <div className={`${namespace}__pills-container`}>{children}</div>}

      {subtitles &&
        adaptedSubtitles.map(subtitle => (
          <StyledLabel
            id="coupon_summary-subtitles-style-label"
            className={`${namespace}__text`}
            key={subtitle.text}
            as="p"
            onClick={subtitleOnClick(subtitle)}
            {...subtitle}
            text={componentEnhance.jsx(subtitle.text, subtitle.values)}
          />
        ))}

      {action && (
        <Media
          className={classnames(`${namespace}__action`)}
          action={
            action &&
            getAction(
              namespace,
              action,
              onIframeModalResize(
                'coupons_height',
                'height',
                '.ui-pdp-iframe-modal',
                '.andes-modal__header',
                'width',
                device,
              ),
              onCouponUpdate,
              actionModalRef,
              actionModalProps,
            )
          }
          isFetching={isFetching}
        />
      )}
    </div>
  );
};

Coupons.propTypes = {
  snackbar: shape({
    duration: number,
    type: string,
    message: string,
  }),
  onCouponUpdate: func.isRequired,
  showSnackbar: func,
  handleOnSubtitleClick: func.isRequired,
  action: oneOfType([
    shape({
      label: shape({
        text: string.isRequired,
        color: string,
      }).isRequired,
      target: string.isRequired,
      onClick: func,
    }),
    node,
    func,
  ]),
  children: node,
  className: string,
  device: string.isRequired,
  isFetching: bool,
  subtitles: arrayOf(
    shape({
      text: string,
      color: string,
      values: shape({
        price: shape({
          type: string,
          value: number,
          original_value: number,
          currency_symbol: string,
        }),
      }),
    }),
  ),
  title: shape({}).isRequired,
  id: string,
  actionModalRef: shape({}),
  actionModalProps: shape({
    className: string,
    maxWidth: number,
    maxHeight: number,
  }),
};

Coupons.defaultProps = {
  action: null,
  children: null,
  className: null,
  subtitles: null,
  device: null,
  isFetching: null,
  onCouponUpdate: null,
  showSnackbar: null,
  snackbar: null,
  openModal: null,
  actionModalProps: null,
};

export default React.memo(Coupons);
