import React from 'react';
import classNames from 'classnames';
import { namespace, quantityTitlePropTypes } from './model';

const QuantityTitle = ({ title, showError = false }) => (
  <span
    className={classNames({
      [`${namespace}__title`]: !showError,
      [`${namespace}__title-error`]: showError,
    })}
  >
    {title}
  </span>
);

QuantityTitle.propTypes = quantityTitlePropTypes;
export default QuantityTitle;
