import React from 'react';
import { string } from 'prop-types';
import { Image } from 'nordic/image';

const ICON_ID = 'empty_reviews';

const IconEmptyReviews = ({ className }) => (
  <div className={className}>
    <Image src="empty-reviews.svg" alt="" />
  </div>
);

IconEmptyReviews.propTypes = {
  className: string,
};

IconEmptyReviews.defaultProps = {
  className: '',
};

export default React.memo(IconEmptyReviews);
export { ICON_ID };
