import React from 'react';
import { bool, shape, string, func } from 'prop-types';
import classnames from 'classnames';
import IconFactory from '../../icons/factory-builder';

const ButtonContent = ({ collapsed, namespace, text }) => (
  <>
    {text}
    {IconFactory(
      { id: 'chevron' },
      classnames(`${namespace}__chevron ui-pdp-icon--chevron-down`, {
        'ui-pdp-icon--chevron-up--active': collapsed,
      }),
    )}
  </>
);
ButtonContent.propTypes = {
  text: string.isRequired,
  collapsed: bool,
  namespace: string.isRequired,
};

ButtonContent.defaultProps = {
  collapsed: false,
};

const Button = ({ action, collapsed, namespace, onClick }) =>
  action.target ? (
    <a className={`${namespace}__action`} title={action.label.text} onClick={onClick} href={action.target}>
      <ButtonContent collapsed={collapsed} namespace={namespace} text={action.label.text} />
    </a>
  ) : (
    <span
      className={`${namespace}__action`}
      title={action.label.text}
      onClick={onClick}
      onKeyUp={onClick}
      role="button"
      tabIndex="-1"
    >
      <ButtonContent collapsed={collapsed} namespace={namespace} text={action.label.text} />
    </span>
  );

Button.propTypes = {
  action: shape({
    label: shape({
      text: string.isRequired,
      color: string,
    }).isRequired,
    target: string,
  }).isRequired,
  collapsed: bool,
  namespace: string.isRequired,
  onClick: func.isRequired,
};

Button.defaultProps = {
  collapsed: false,
};

export default Button;
