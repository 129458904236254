import compose from '../../lib/compose';
import connect from '../../store/connect';
import withFeatureFlag from '../feature-flag/with-feature-flag';
import withRow from '../grid/row/with-row';

const FEATURE_NAME = 'reviews_capability_v3';

const mapStateToProps = ({ components: { [FEATURE_NAME]: Reviews }, id, app, history }) => ({
  ...Reviews,
  id: history && history.parent_product_id ? history.parent_product_id : id,
  is_item: app !== 'pdp',
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

const enhanceReviews = Reviews => enhance(Reviews);

export default enhanceReviews;
