import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import jsonToProps from '../generic-summary/shipping-mapper';
import * as actions from '../../../../actions/vip';

const FEATURE_NAME = 'meliplus_summary';

const mapStateToProps = ({ components: { [FEATURE_NAME]: meliplusSummary }, isFetching }) =>
  jsonToProps({ ...meliplusSummary, isFetching, noTitle: true });

const mapDispatchToProps = dispatch => ({
  meliplusOnDeliveryDayChange: () => {
    dispatch(actions.fetchItemOnMeliplusUpdate('change_day'));
  },
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const meliplusSummary = MeliplusSummary => enhance(MeliplusSummary);

export default meliplusSummary;
