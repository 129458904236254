import {
  CREATE_QUESTION_ERROR,
  CREATE_QUESTION_START,
  CREATE_QUESTION_COMPLETE,
  CREATE_QUESTION_FROM_AI_COMPLETE,
} from '../../../../actions/vip/types';
import { messagesErrorScreen } from '../../../../lib/get-message-error';
import { getQuestions } from '../../../helpers/components';

const createQuestionStart = (state, action) => {
  const questionsComponent = getQuestions(action);
  return {
    ...state,
    [questionsComponent]: {
      ...state[questionsComponent],
      questions_ai: {
        ...state[questionsComponent].questions_ai,
        isFetching: false,
      },
      messages: null,
      disabled: true,
      isFetching: true,
    },
  };
};

const createQuestionComplete = (state, action) => {
  const questionsComponent = getQuestions(action);
  return {
    ...state,
    components: {
      ...state.components,
      onboarding: action.payload.push_notification_modal,
    },
    [questionsComponent]: {
      ...state[questionsComponent],
      disabled: false,
      messages: {
        ...action.payload.display_message,
        type: action.payload.success === true ? 'success' : 'error',
      },
      isFetching: false,
      stockModal: action.payload.stock_modal,
      stockTrack: action.payload.stock_track,
      empty_state: action.payload.model ? action.payload.model.empty_state : null,
      ...action.payload.model,
    },
  };
};

const createQuestionFromAIComplete = (state, action) => {
  const questionsComponent = getQuestions(action);
  return {
    ...state,
    components: {
      ...state.components,
      onboarding: action.payload.push_notification_modal,
    },
    [questionsComponent]: {
      ...state[questionsComponent],
      questions_ai: {
        ...state[questionsComponent].questions_ai,
        questions_ai_form: {
          ...state[questionsComponent].questions_ai.questions_ai_form,
          form: {
            ...state[questionsComponent].questions_ai?.questions_ai_form?.form,
            is_ask_seller: true,
          },
        },
        questions_ai_suggestion: {
          state: 'HIDDEN',
        },
        isFetching: false,
      },
      disabled: false,
      isFetching: false,
      stockModal: action.payload.stock_modal,
      stockTrack: action.payload.stock_track,
      empty_state: action.payload.model ? action.payload.model.empty_state : null,
      ...action.payload.model,
    },
  };
};

const createQuestionError = (state, action) => {
  const questionsComponent = getQuestions(action);
  const { title, description } = messagesErrorScreen(state.siteId);
  const errorText = `${title} - ${description}`;
  return {
    ...state,
    [questionsComponent]: {
      ...state[questionsComponent],
      questions_ai: {
        ...state[questionsComponent].questions_ai,
        isFetching: false,
      },
      messages: {
        text: errorText,
        type: 'error',
      },
      disabled: false,
      isFetching: false,
    },
  };
};

const createQuestionsMapper = {
  [CREATE_QUESTION_ERROR]: createQuestionError,
  [CREATE_QUESTION_START]: createQuestionStart,
  [CREATE_QUESTION_COMPLETE]: createQuestionComplete,
  [CREATE_QUESTION_FROM_AI_COMPLETE]: createQuestionFromAIComplete,
};

export { createQuestionComplete, createQuestionError, createQuestionStart, createQuestionFromAIComplete };
export default createQuestionsMapper;
