import APIService from '../services/api';

const feStatsdHelper = ({ key, additionalTags = {}, additionalInfo, shouldLogMessage, statsdConfig, messageTitle }) => {
  if (statsdConfig) {
    const { keyPrefix, baseTags = {} } = statsdConfig;

    APIService.saveFrontendStatsd({
      key: `${keyPrefix}.${key}`,
      tags: {
        ...baseTags,
        ...additionalTags,
      },
      info: shouldLogMessage && additionalInfo,
      messageTitle,
    });
  }
};

export { feStatsdHelper };
