import React, { useContext, useEffect, useState } from 'react';
import { bool, func, node, oneOf, oneOfType, string, number } from 'prop-types';
import Modal from '@andes/modal';
import classNames from 'classnames';
import ProgressIndicatorCircular from '@andes/progress-indicator-circular';
import StaticPropsContext from '../../context/static-props';
import ModalContentAutoheight from './modal-content-autoheight';
import { forEach } from '../../../lib/dom-utils';

const namespace = 'ui-pdp-managed-modal';

const BaseModal = ({
  actions,
  autoHeight,
  children,
  closable,
  closeModalLabel,
  closeUrl,
  id,
  isOpen,
  title,
  type,
  onClose,
  className,
  showCloseButton,
  modalUrl,
  maxHeight,
  maxWidth,
}) => {
  const { deviceType, isWebview } = useContext(StaticPropsContext);
  const [state, setState] = useState({ isOpen, isLoading: false });

  useEffect(() => {
    if (isOpen) {
      if (deviceType !== 'mobile') {
        // Se calculan en base al react-remove-scroll-bar y cumplen la función de suplantar el espacio
        // dejado por la scrollbar en los casos que se utilice mouse
        // checks para omitir el test del dom
        const main = document.querySelectorAll('body > header, body > main') || [];

        forEach(main, element => {
          element.classList.add('with-scroll-bars-hidden');
        });
      }
    }
    return () => {
      // Limpiar la suscripción
      if (deviceType !== 'mobile') {
        // Se calculan en base al react-remove-scroll-bar y cumplen la función de suplantar el espacio
        // dejado por la scrollbar en los casos que se utilice mouse
        const main = document.querySelectorAll('body > header, body > main') || [];

        forEach(main, element => {
          element.classList.remove('with-scroll-bars-hidden');
        });
      }
    };
  }, [deviceType, isOpen]);

  const innerContent = (
    <>
      {state.isLoading && <ProgressIndicatorCircular modifier="fullscreen" size="large" />}
      {children}
    </>
  );

  const onOpenInternal = e => {
    if (!e.ctrlKey && !e.metaKey) {
      e.preventDefault();
      setState({ isOpen: true, isLoading: false });
    }
  };

  const onCloseInternal = () => {
    onClose();
    setState({ isOpen: false, isLoading: false });
  };

  return (
    <div id={`managed-modal-${id}`}>
      <Modal
        actions={actions}
        closeUrl={closeUrl}
        closeButtonSrLabel={closeModalLabel}
        className={classNames(`${namespace}__modal`, id, `${namespace}__${id}`, className)}
        open={state.isOpen}
        onClose={onCloseInternal}
        onOpen={onOpenInternal}
        title={title}
        type={type}
        showCloseButton={showCloseButton && !isWebview}
        dialog={!closable}
        url={modalUrl}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
      >
        {autoHeight ? <ModalContentAutoheight modalId={id}>{innerContent}</ModalContentAutoheight> : innerContent}
      </Modal>
    </div>
  );
};

BaseModal.propTypes = {
  actions: node,
  autoHeight: bool,
  children: node,
  closable: bool,
  closeModalLabel: string,
  closeUrl: string,
  showCloseButton: bool,
  id: string.isRequired,
  isOpen: bool,
  maxHeight: number,
  maxWidth: number,
  title: oneOfType([string, node]),
  type: oneOf(['card', 'full', 'small', 'large']).isRequired,
  onClose: func.isRequired,
  className: string,
  modalUrl: string,
};

BaseModal.defaultProps = {
  actions: null,
  autoHeight: true,
  children: null,
  closable: true,
  closeModalLabel: ' ', // Evitamos que tire warning cuando el modal no tiene boton de cierre
  closeUrl: null,
  isOpen: false,
  className: null,
  showCloseButton: true,
  title: null,
  maxHeight: null,
  maxWidth: null,
  modalUrl: null,
};

export default BaseModal;
