import { loadable } from 'nordic/lazy';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import mapStateToProps from '../../../../components/recommendations/state-mapper-base';

const RecommendationsListPads = loadable(() => import('../../../../components/recommendations/list-pads'));

const enhance = compose(
  withFeatureFlag(),
  connect(mapStateToProps()),
);

export default enhance(RecommendationsListPads);
