import {
  ADD_TO_CART_UPDATE_START,
  ADD_TO_CART_UPDATE_COMPLETE,
  ADD_TO_CART_UPDATE_ERROR,
} from '../../../../actions/vip/types';

const addToCartUpdateStart = state => ({
  ...state,
  components: {
    ...state.components,
    cart_actions: {
      ...state.components.cart_actions,
      card_tooltip: state.components.cart_actions.card_tooltip
        ? {
            ...state.components.cart_actions.card_tooltip,
            isFetching: true,
          }
        : null,
    },
  },
});

const addToCartUpdateComplete = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    cart_actions: {
      ...action.payload.components.cart_actions,
      card_tooltip: action.payload.components.cart_actions.card_tooltip
        ? {
            ...action.payload.components.cart_actions.card_tooltip,
            isFetching: false,
          }
        : null,
    },
    shipping_summary: {
      ...action.payload.components.shipping_summary,
    },
    second_shipping_summary: action.payload.components.second_shipping_summary
      ? {
          ...action.payload.components.second_shipping_summary,
        }
      : { state: 'HIDDEN' },
    pick_up_summary: {
      ...action.payload.components.pick_up_summary,
    },
    payment_summary: {
      styles: state.components.payment_summary.styles,
      ...action.payload.components.payment_summary,
    },
  },
  feedback: {
    ...action.payload.feedback,
    key: Math.random(),
  },
});

const addToCartUpdateError = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    cart_actions: {
      ...state.components.cart_actions,
      card_tooltip: state.components.cart_actions.card_tooltip
        ? {
            ...state.components.cart_actions.card_tooltip,
            isFetching: false,
          }
        : null,
    },
  },
  error: action.error,
});

const a2cMapper = {
  [ADD_TO_CART_UPDATE_START]: addToCartUpdateStart,
  [ADD_TO_CART_UPDATE_COMPLETE]: addToCartUpdateComplete,
  [ADD_TO_CART_UPDATE_ERROR]: addToCartUpdateError,
};

export { addToCartUpdateStart, addToCartUpdateComplete, addToCartUpdateError };
export default a2cMapper;
