import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import * as actions from '../../../../actions/vip';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';

const FEATURE_NAME = 'compatibilities';

const mapStateToProps = ({ components: { [FEATURE_NAME]: compatibilities } }) => compatibilities;

const mapDispatchToProps = dispatch => ({
  fetchCompatibleValues: ({ domainId, labelId, values }) =>
    dispatch(actions.fetchCompatibleValues({ domainId, labelId, values })),
  setSelectedValue: ({ labelId, value }) => dispatch(actions.setSelectedValue({ labelId, value })),
  fetchCompatibilities: ({ domainId, values }) => dispatch(actions.fetchCompatibilities({ domainId, values })),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const component = Component => enhance(Component);

export default component;
