import React from 'react';
import { string, shape } from 'prop-types';
import classnames from 'classnames';
import { setCookie } from '../../lib/cookies';
import BaseModal from '../modals/base-modal/index';
import ExitPopupTitleComponent from './exit-popup-title';
import RecommendationsCarouselSnapped from '../../pages/vip/features/recommendations/carousel-snapped';
import { trackEvent } from '../../lib/tracking';

const ExitPopupComponent = ({ url, state, track, carousel_config }) => {
  const { title, subtitle = {} } = carousel_config?.recommendation_from_middle_end;
  const isTrackHidden = state === 'TRACK_HIDDEN';
  const isHidden = state === 'HIDDEN';
  const EMPTY_TITLE = ' '; /* Necesario para el modal de Andes, no dibuje el titulo pero mantenga el header. */
  const namespace = 'ui-pdp-exit-popup';
  const [openModal, setOpenModal] = React.useState(false);
  const [sendTrack, setSendTrack] = React.useState(false);

  const cookie_attributes = React.useMemo(
    () => ({
      path: '/',
      domain: `.${new URL(url).hostname
        .split('.')
        .slice(-3)
        .join('.')}`,
    }),
    [url],
  );

  const send_track = trackParam => {
    setSendTrack(true);
    trackEvent(trackParam);
  };

  const getDateNow = () => {
    const dateNow = new Date();
    return dateNow.toISOString();
  };

  const onMouseOut = React.useCallback(e => {
    const cookieName = 'ml_gads_exit-popup-offer';
    const LIMIT_ON_MOUSE_OUT = 20;
    if (e.clientY < LIMIT_ON_MOUSE_OUT && !sendTrack) {
      if (!isTrackHidden) {
        setOpenModal(true);
      }
      setCookie(cookieName, getDateNow(), cookie_attributes);
      send_track(track);
    }
  }, [sendTrack, isTrackHidden, track, cookie_attributes]);

  React.useEffect(() => {
    document.onmouseout = onMouseOut;

    return () => {
      document.removeEventListener('mouseout', onMouseOut);
    };
  }, [sendTrack, onMouseOut]);

  if (isTrackHidden || isHidden) {
    return null;
  }

  return (
    <BaseModal
      key={`${namespace}__modal-${Math.random()}`}
      id={`${namespace}__modal`}
      isOpen={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
      closable
      closeModalLabel=""
      title={EMPTY_TITLE}
      type="large"
      autoHeight={false}
      maxWidth={1300}
      maxHeight={580}
      className={classnames(`${namespace}__bg-modal-exit-popup`)}
    >
      <div className={classnames(`${namespace}__div-modal-container`)}>
        <div className={classnames(`${namespace}__div-modal-title`)}>
          <ExitPopupTitleComponent
            namespace={namespace}
            title={title}
            url={subtitle.url}
            max_discount={subtitle.max_discount}
            button_title={subtitle.text}
          />
        </div>
        <div className={classnames(`${namespace}__div-modal-carousel`)}>
          <RecommendationsCarouselSnapped
            featureName="carousel_exit_pop_up"
            slidesPerView={3}
            title={EMPTY_TITLE}
            subtitle={{ text: '', url: '' }}
          />
        </div>
      </div>
    </BaseModal>
  );
};

ExitPopupComponent.propTypes = {
  state: string.isRequired,
  url: string.isRequired,
  track: shape({ melidata_event: shape({}) }),
  carousel_config: shape({ recommendation_from_middle_end: shape({ title: string, subtitle: shape({}) }) }),
};

ExitPopupComponent.defaultProps = {
  isOpen: true,
  carousel_config: { recommendation_from_middle_end: { title: '', subtitle: {} } },
};

export default ExitPopupComponent;
