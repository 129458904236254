import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import jsonToProps from '../generic-summary/shipping-mapper';
import * as actions from '../../../../actions/vip';

const FEATURE_NAME = 'upselling_meliplus_summary';

const mapStateToProps = ({ components: { [FEATURE_NAME]: upsellingMeliplusSummary }, isFetching }) =>
  jsonToProps({ ...upsellingMeliplusSummary, isFetching, noTitle: true });

const mapDispatchToProps = dispatch => ({
  onCloseModal: () => {
    dispatch(actions.fetchItemOnMeliplusUpdate('close_modal'));
  },
  meliplusUpsellingOnSuscription: () => {
    window.location.reload(true);
  },
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const upsellingMeliplusSummary = UpsellingMeliplusSummary => enhance(UpsellingMeliplusSummary);

export default upsellingMeliplusSummary;
