import { loadable } from 'nordic/lazy';
import { PROVIDER } from './constants';
import ImageProvider from './figure.image';

const FIGURE = 'FigureMemo';
const ICON = 'Icon';
const PREVIEW = 'Preview';

const getLoadableConfig = type => ({ resolveComponent: m => m[type], fallback: ImageProvider[type] });

const ClipsProvider = {
  Figure: loadable(() => import('./figure.clip'), getLoadableConfig(FIGURE)),
  Icon: loadable(() => import('./figure.clip'), getLoadableConfig(ICON)),
  Preview: loadable(() => import('./figure.clip'), getLoadableConfig(PREVIEW)),
};

const YoutubeProvider = {
  Figure: loadable(() => import('./figure.youtube'), getLoadableConfig(FIGURE)),
  Icon: loadable(() => import('./figure.youtube'), getLoadableConfig(ICON)),
  Preview: loadable(() => import('./figure.youtube'), getLoadableConfig(PREVIEW)),
};

const MatterportProvider = {
  Figure: loadable(() => import('./figure.matterport'), getLoadableConfig(FIGURE)),
  Icon: loadable(() => import('./figure.matterport'), getLoadableConfig(ICON)),
  Preview: loadable(() => import('./figure.matterport'), getLoadableConfig(PREVIEW)),
};

const SpincarProvider = {
  Figure: loadable(() => import('./figure.spincar'), getLoadableConfig(FIGURE)),
  Icon: loadable(() => import('./figure.spincar'), getLoadableConfig(ICON)),
  Preview: loadable(() => import('./figure.spincar'), getLoadableConfig(PREVIEW)),
};

const Figure = (provider = PROVIDER.IMAGE) => {
  switch (provider.toLowerCase()) {
    case PROVIDER.YOUTUBE:
      return YoutubeProvider;
    case PROVIDER.CLIP:
      return ClipsProvider;
    case PROVIDER.MATTERPORT:
      return MatterportProvider;
    case PROVIDER.SPINCAR:
      return SpincarProvider;
    default:
      return ImageProvider;
  }
};

const figureProviderFactory = provider => Figure(provider);

export default figureProviderFactory;
