/* eslint-disable security/detect-non-literal-regexp */
import TYPES from './types';

import * as REGEX from './regex';

/**
 * getUrlInstance
 * ________________
 * Retorna una instancia de URL y hace un manejo de errores.
 */

const getUrlInstance = urlText => {
  try {
    return new URL(urlText);
  } catch {
    const protocol = 'https://';

    if (!urlText.startsWith(protocol)) {
      return getUrlInstance(`${protocol}${urlText}`);
    }
    return null;
  }
};

/**
 * getUrlsDataFromText
 * ________________
 * Dada una url + config, arma un objeto acorde a un -value- de tipo -action- (en i18n.jsx)
 */

const getI18nActionValue = (urlData, originalUrl, config) => {
  const { accessibility_text = null, html_target = null, track = null, fragment, getLabelUrl } = config;

  const labelText = getLabelUrl(originalUrl, urlData, config);

  if (fragment) {
    urlData.hash = fragment;
  }

  return {
    type: TYPES.ACTION,
    color: 'BLUE',
    target: urlData.href,
    label: {
      text: labelText,
    },
    accessibility_text,
    html_target,
    track,
    stopPropagation: true,
  };
};

/**
 * getRegex
 * ________________
 * Verifica si el regex obtenido del backend es válido. En caso de serlo se retorna la instancia,
 * si no se retorna la regex literal por default.
 */

const getRegex = ({ pattern, flag } = {}) => {
  try {
    const isValidRegex = pattern?.length > 10;
    return isValidRegex ? new RegExp(pattern, flag) : REGEX.urls;
  } catch {
    return REGEX.urls;
  }
};

/**
 * getUrlsDataFromText
 * ________________
 * Aplica regex para extrer urls del texto. A su vez las instancia para validarlas y dotarla de valor.
 * Las formatea acorde a i18n.
 */

const getUrlsDataFromText = (text, config) => {
  const matches = text.match(getRegex(config.regex));

  return matches?.reduce((acc, originalUrl, i, list) => {
    const urlData = getUrlInstance(originalUrl);
    const lastUrlIndex = acc.length;

    if (!urlData) {
      return acc;
    }
    const id = list?.length === 1 ? 'url' : `url${lastUrlIndex + 1}`;

    return [
      ...acc,
      {
        id,
        originalUrl,
        ...getI18nActionValue(urlData, originalUrl, config),
      },
    ];
  }, []);
};

/**
 * counterChars
 * ________________
 * Recorre el arreglo de objetos y dependiendo el tipo va sumando la cantidad de caracteres.
 */

const counterChars = items =>
  items.reduce((acum, { str, type, label }) => {
    const isURL = type === TYPES.ACTION;
    const currentCount = isURL ? label.text.length : str.length;
    return acum + currentCount;
  }, 0);

/**
 * toArrayModel
 * ________________
 * Identifica y extrae las urls. Se arma un array con estas con el formato de -value- requerido por i18n.
 *
 * Separa el texto plano de las urls y genera un array de objetos.
 *
 * Retorna ej. : [{type: 'text', str: 'El link es: '}, { type: 'action', str: 'https://ww..', ...restUrlProperties'}]
 */

const toArrayModel = (txt, config) => {
  const urls = getUrlsDataFromText(txt, config);
  const defaultArrayModel = { list: [{ type: TYPES.TEXT, str: txt }], charCounter: txt.length }; // sin urls, solo texto;

  const { list, charCounter } = !urls
    ? defaultArrayModel
    : urls.reduce(
        (acc, { id, originalUrl, ...restDataUrl }, index, urlList) => {
          const { restText, list: accList, charCounter: accCharCounter } = acc;

          const positionUrlInText = restText.indexOf(originalUrl);
          const isLastUrl = index === urlList?.length - 1;
          const urlPosition = {
            start_at: positionUrlInText,
            end_at: positionUrlInText + originalUrl.length - 1,
          };

          const urlProperties = { type: TYPES.ACTION, id, originalUrl, ...restDataUrl };

          /* "|----text(a)----| |----url(b)----| |----text(c)----|" */

          const a = { type: TYPES.TEXT, str: restText.slice(0, urlPosition.start_at) };
          const b = { ...urlProperties, str: `{${id}}` };
          const c = isLastUrl && { type: TYPES.TEXT, str: restText.slice(urlPosition.end_at + 1, restText.length) };
          const items = [a, b, c].filter(e => e?.str);

          return {
            restText: restText.slice(urlPosition.end_at + 1, restText.length),
            charCounter: accCharCounter + counterChars(items),
            list: [...accList, ...items],
          };
        },
        { restText: txt, charCounter: 0, list: [] },
      );

  return { arrayModel: list, charsAmount: charCounter };
};

/**
 * toI18n
 * ________________
 * Recorre un arrayModel y lo transforma en un objeto con texto y valores.
 *
 * (Requerido por i18n.jsx)
 */

const toI18n = arrayModel =>
  arrayModel.reduce(
    (acc, current) => {
      const { type, str, id, ...props } = current;
      return {
        text: acc.text + str,
        values: {
          ...acc.values,
          ...(type === TYPES.ACTION ? { [id]: { type, ...props } } : {}),
          ...(type === TYPES.HIGHLIGHTED ? { [id]: { type, ...props } } : {}),
        },
      };
    },
    { text: '', values: {} },
  );

export { toArrayModel, toI18n };
