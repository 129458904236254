import get from 'lodash/get';

const canUseDOM = !!(typeof window !== 'undefined');

const trackNewrelic = error => {
  if (canUseDOM && typeof window.newrelic !== 'undefined') {
    const errorText = get(error, 'error', error);
    const infoText = get(error, 'info');
    let errorWithInfo = errorText;
    if (typeof errorText === 'string') {
      errorWithInfo = `[CUSTOM] ${errorText} ${infoText}`;
    }
    window.newrelic.noticeError(errorWithInfo);
  }

  return null;
};

export default trackNewrelic;
