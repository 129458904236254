import React from 'react';
import { string, shape } from 'prop-types';
import classnames from 'classnames';

const namespace = 'ui-seller-info__status-info';

const StatusInfo = ({ className, iconClassName, titleClassName, subtitleClassName, icon, title, subtitle }) => (
  <div className={classnames(namespace, className)}>
    {icon && (
      <figure aria-hidden="true" className={classnames(`${namespace}__icon`, iconClassName)}>
        {icon}
      </figure>
    )}
    <div>
      <p className={classnames(`${namespace}__title`, titleClassName)}>{title}</p>
      <p className={classnames(`${namespace}__subtitle`, subtitleClassName)}>{subtitle}</p>
    </div>
  </div>
);

StatusInfo.propTypes = {
  className: string,
  iconClassName: string,
  titleClassName: string,
  subtitleClassName: string,
  icon: shape({ id: string }),
  title: string,
  subtitle: string,
};

StatusInfo.defaultProps = {
  className: null,
  iconClassName: null,
  titleClassName: null,
  subtitleClassName: null,
  icon: null,
  title: null,
  subtitle: null,
};

export default StatusInfo;
