import React, { useContext } from 'react';
import ChevronRight20 from '@andes/icons/ChevronRight20';
import StaticPropsContext from '../context/static-props';
import classNames from 'classnames';
import { shape, func, string, bool } from 'prop-types';
import Share from '../share/share.mobile';
import Bookmark from '../bookmark/bookmark';
import jsonToProps from './jsonToProps';
import { UserConsumer } from '../context/user';
import useTrackView from '../../hooks/use-track-view';

const GroupedShareBookmark = ({
  share,
  bookmark,
  onClickBookmark,
  wishlist,
  openWishlistModalBS,
  redirectToLogin,
  showOnlyForWishlistVariant,
  publishMyVehicleButton,
}) => {
  const addToListNamespace = `ui-pdp-add-to-list`;
  const { deviceType } = useContext(StaticPropsContext);
  const userContext = useContext(UserConsumer);
  const namespace = userContext?.nickname ? 'ui-vpp-grouped-share-bookmark' : 'ui-vpp-grouped-share-bookmark-no-logged';

  const buttonClassname = classNames(`${addToListNamespace}__link`, { 'gift-registry': wishlist?.gift_registry?.id });

  const buttonOnClick = () => {
    if (userContext?.nickname) {
      openWishlistModalBS();
    } else {
      const regex = /item_id=([^&]+)/;
      const match = wishlist?.target?.match(regex);
      const itemId = match ? match[1] : null;
      redirectToLogin(itemId);
    }
  };

  const isDesktop = deviceType === 'desktop';
  const isMobile = deviceType === 'mobile';
  const shouldHideComponent =
    (!wishlist?.wishlistVariantVisible && showOnlyForWishlistVariant && isDesktop) ||
    (!showOnlyForWishlistVariant && (!wishlist || !wishlist.wishlistVariantVisible) && isDesktop);

  if (shouldHideComponent) {
    return null;
  }

  if (wishlist?.label && (wishlist?.wishlistVariantVisible || showOnlyForWishlistVariant)) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const viewRef = useTrackView(wishlist?.track || {}, { threshold: 0.1 });
    return (
      <div className={classNames(`${namespace}-wishlist`, deviceType)} id="gift-registry" ref={viewRef}>
        <button
          type="button"
          className={buttonClassname}
          onClick={() => buttonOnClick()}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              buttonOnClick();
            }
          }}
        >
          <span>
            <span className={`${addToListNamespace}__link--label`}>{wishlist?.label}</span>
          </span>
          <span className={`${addToListNamespace}__link--chevron`}>
            <ChevronRight20 color="#3483FA" />
          </span>
        </button>
      </div>
    );
  }

  if (publishMyVehicleButton?.publishMyVehicleButtonVariantVisible && isMobile) {
    return null;
  }
  return (
    <div className={namespace}>
      {bookmark && bookmark.label && <Bookmark {...bookmark} onClick={onClickBookmark} />}
      {share && share.action ? <Share {...share} /> : null}
    </div>
  );
};

GroupedShareBookmark.propTypes = {
  id: string,
  app: string,
  addElementToList: func,
  removeElementFromList: func,
  share: shape({
    action: shape(),
  }),
  bookmark: shape({}),
  onClickBookmark: func,
  showSnackbar: func,
  openWishlistModalBS: func,
  wishlist: shape({
    label: string,
    target: string,
  }),
  redirectToLogin: func,
  showOnlyForWishlistVariant: bool,
  publishMyVehicleButton: shape({
    publishMyVehicleButtonVariantVisible: bool,
  }),
};

GroupedShareBookmark.defaultProps = {
  share: null,
  bookmark: null,
  onClickBookmark: null,
  showSnackbar: null,
  wishlist: null,
  redirectToLogin: null,
  showOnlyForWishlistVariant: null,
  publishMyVehicleButton: null,
};

export default GroupedShareBookmark;
export const MappedGroupedShareBookmark = props => <GroupedShareBookmark {...jsonToProps(props)} />;
