import React, { Fragment, useEffect } from 'react';
import { bool, string, shape, func } from 'prop-types';
import classNames from 'classnames';
import Message from '../message';
import componentEnhance from '../../lib/component-enhance';
import useTrackView from '../../hooks/use-track-view';
import { generateKeyToComponent } from '../../utils/validators';

const namespace = 'ui-pdp-description';

const nl2br = text =>
  text.split('\n').map(item => (
    <Fragment key={generateKeyToComponent(item)}>
      {item}
      <br />
    </Fragment>
  ));

const Description = ({
  title,
  content,
  custom_message,
  className,
  showTitle,
  show_track,
  focus,
  onScrollComponent,
}) => {
  const refView = useTrackView(show_track, { threshold: 0.1 });

  useEffect(() => {
    let timeoutId;

    if (focus) {
      window.scrollTo({
        left: 0,
        top: refView.current.getBoundingClientRect().top + window.scrollY,
        behavior: 'smooth',
      });
      timeoutId = setTimeout(() => {
        onScrollComponent({ focus: false });
      }, 1000);
    }

    return () => clearTimeout(timeoutId);
  }, [refView, focus, onScrollComponent]);

  return (
    <div ref={refView} className={classNames(namespace, className)}>
      {showTitle && (
        <h2 data-testid="title" className={`${namespace}__title`}>
          {title}
        </h2>
      )}
      {custom_message && (
        <Message
          className={`${namespace}__message`}
          {...{
            closeable: custom_message.closeable,
            hierarchy: custom_message.hierarchy,
            type: custom_message.message_type,
            title: custom_message.title?.text,
          }}
        >
          <div className={`${namespace}__message__text`}>
            {componentEnhance.jsx(custom_message.body.text, custom_message.body.values)}
          </div>
        </Message>
      )}
      <p data-testid="content" className={`${namespace}__content`}>
        {nl2br(content)}
      </p>
    </div>
  );
};

Description.propTypes = {
  className: string,
  content: string.isRequired,
  custom_message: shape({
    state: string,
    body: shape({ test: string }),
    closeable: bool,
    hierarchy: string,
    message_type: string,
    title: shape({ text: string }),
  }),
  showTitle: bool,
  title: string.isRequired,
  show_track: shape({}),
  focus: bool,
  onScrollComponent: func,
};

Description.defaultProps = {
  className: null,
  custom_message: null,
  showTitle: true,
  show_track: null,
  focus: false,
  onScrollComponent: null,
};

export default React.memo(Description);
