const OWNERSHIP_CORE = 'ownership_core';

const ownershipMap = {
  // We use this in order to set the ownership of certains components
  // fit_as_expected_component_key: 'ownership_apparel', // Example
  // price: 'ownership_price_team', // Example
  default: OWNERSHIP_CORE,
};

export default ownershipMap;
