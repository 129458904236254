import React from 'react';
import { string, arrayOf, shape, func, oneOf, number, bool } from 'prop-types';
import { DropdownItem, Dropdown } from '@andes/dropdown';
import classnames from 'classnames';
import getPictureConfig from './lib/get-picture-config';
import Image from '../image';
import colornames from '../../lib/colornames';
import { DECORATION_BLOCKED } from './utils/types';

const namespace = 'ui-pdp-dropdown-selector';

const labelComponent = (product, prop) => (
  <span className={classnames(`${namespace}__item--${prop}`, colornames(product[prop].color))}>
    {product[prop].text}
  </span>
);

const itemComponent = (product, thumbnail, text) => (
  <>
    <span
      className={classnames(`${namespace}__item`, `${namespace}__item-first-column`, {
        [`${namespace}__item-blocked`]: product.decoration_type === 'BLOCKED',
        [`${namespace}__item-with-thumbnail`]: !!thumbnail,
      })}
    >
      {thumbnail && <Image alt="" className={`${namespace}__thumbnail`} lazyload={false} {...thumbnail} />}
      <span
        className={classnames(
          thumbnail && text ? `${namespace}__item--label` : `${namespace}__item--label-small`,
          colornames(product.label.color),
        )}
      >
        {product.label.text}
      </span>
      {product.subtitle && labelComponent(product, 'subtitle')}
    </span>
    {text && <span className={`${namespace}__item--secondary_text`}>{text}</span>}
  </>
);

const VariationsDropdown = ({
  selectedAttributes,
  selectedAttributeId,
  picker,
  handleOnSelectVariation,
  onSelectVariation,
  selectedQuantity,
  hasDeferredErrors,
  platform,
}) => {
  const { id, error_message, selected_option, products } = picker;
  const selected = products.find(product => product.selected);
  const show_error = picker.error_message && !picker.error_message.is_deferred;
  const selectedOption = {
    label: {
      text: show_error ? error_message.selected_option.text : selected_option.text,
      color: show_error ? error_message.selected_option.color : selected_option.color,
    },
    thumbnail: selected ? getPictureConfig(selected, { alt: '' }) : null,
    selected: false,
    ...(show_error ?? { subtitle: selected?.subtitle }),
  };

  const show_warning =
    selectedAttributes && !selectedAttributes[picker.id] && picker.outer_message && picker.border_color && !show_error;

  return (
    <Dropdown
      className={classnames(
        namespace,
        { [`${namespace}--error`]: show_error },
        { [`${namespace}--warning`]: show_warning },
      )}
      type="form"
      primary={itemComponent(selectedOption, selectedOption.thumbnail)}
      menuAlignment="bottom"
      key={selectedAttributeId}
      srLabel={selected_option.text}
      value={selectedAttributeId}
      menuMaxHeight={288}
      modifier={show_error && 'error'}
      onChange={(e, unitId) => {
        e.preventDefault();
        if (unitId !== selectedAttributeId) {
          const product = products.find(value => value.attribute_id === unitId);
          handleOnSelectVariation(
            product,
            selectedQuantity,
            selectedAttributes,
            id,
            hasDeferredErrors,
            onSelectVariation,
            platform,
          );
        }
      }}
    >
      {products.map(product => (
        <DropdownItem
          key={product.attribute_id}
          aria-label={`variation-dropdown-${product.attribute_id}`}
          srLabel={product.label.text}
          className={classnames(`${namespace}__item`)}
          title={itemComponent(product, getPictureConfig(product, { alt: '' }), product.disabled_text)}
          value={product.attribute_id}
          selected={product.attribute_id === selectedAttributeId}
          disabled={product.decoration_type === DECORATION_BLOCKED}
        />
      ))}
    </Dropdown>
  );
};

VariationsDropdown.propTypes = {
  selectedAttributes: shape({}),
  selectedAttributeId: string,
  picker: shape({
    id: string,
    label: shape({
      color: string,
      text: string,
    }),
    display_info: shape({
      show_limit: number,
      is_collapsed: bool,
      label_dto: shape({
        text: string,
        color: string,
      }),
    }),
    products: arrayOf(
      shape({
        permalink: string,
        id: string,
        decoration_type: oneOf(['NONE', 'BLOCKED', 'DISABLED', 'SELECTED']),
        label: shape({
          color: string,
          text: string,
        }),
        picture: shape({
          id: string,
          src: string,
        }),
      }),
    ),
  }).isRequired,
  handleOnSelectVariation: func.isRequired,
  onSelectVariation: func.isRequired,
  selectedQuantity: number,
  hasDeferredErrors: bool,
  platform: string,
};

VariationsDropdown.defaultProps = {
  selectedAttributes: null,
  selectedAttributeId: null,
  selectedQuantity: 1,
  hasDeferredErrors: null,
  platform: null,
};

export default VariationsDropdown;
