import { loadable } from 'nordic/lazy';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';

const Promotions = loadable(() => import('../../../../components/promotions'));

const FEATURE_NAME = 'highlights';

const mapStateToProps = (
  {
    components: {
      [FEATURE_NAME]: { tag_action, tooltip, description_action, labels, type },
    },
  },
  ownProps,
) => ({
  tag_action,
  tooltip,
  description_action,
  labels,
  type,
  ...ownProps,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(Promotions);
