import { useEffect } from 'react';
import { shape } from 'prop-types';
import { trackPage } from '../../lib/tracking';

const TrackPage = ({ melidata_event, analytics_event }) => {
  useEffect(() => {
    trackPage({ melidata_event, analytics_event });
  }, [melidata_event, analytics_event]);
  return null;
};

TrackPage.propTypes = {
  analytics_event: shape({}),
  melidata_event: shape({}),
};

TrackPage.defaultProps = {
  analytics_event: null,
  melidata_event: null,
};

export default TrackPage;
