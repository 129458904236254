import { CREATE_BUDGET_START, CREATE_BUDGET_ERROR } from '../../../../actions/vip/vis/types';

const createBudgetStart = state => ({
  ...state,
  components: {
    ...state.components,
    budget_demand: {
      ...state.components.budget_demand,
      messages: null,
      disabled: true,
      isFetching: true,
    },
  },
});

const createBudgetError = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    budget_demand: {
      ...state.components.budget_demand,
      messages: {
        ...action.payload.display_message,
        type: 'error',
      },
      disabled: false,
      isFetching: false,
    },
  },
});

const budgetMapper = {
  [CREATE_BUDGET_START]: createBudgetStart,
  [CREATE_BUDGET_ERROR]: createBudgetError,
};

export { createBudgetStart, createBudgetError };
export default budgetMapper;
