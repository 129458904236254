import React from 'react';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import StyledLabel from '../../styled-label';

const compare = (a, b) => {
  if (a.start_at > b.start_at) {
    return 1;
  }
  if (a.start_at < b.start_at) {
    return -1;
  }
  return 0;
};

const getHighlight = (text, className) => <StyledLabel text={text} as="span" className={className} />;

const sanatizeHighlights = highlights => {
  try {
    const cleanHighlights = uniqWith(highlights, isEqual);
    if (cleanHighlights && cleanHighlights.length > 0) {
      return cleanHighlights.sort(compare);
    }
    return [];
  } catch {
    return [];
  }
};

const getHighlightedText = (originalText, highlightsRaw) => {
  const highlightClassName = 'ui-pdp-qadb--highlighted';
  const arrayOfWords = [];

  // 1- ordeno el array de start_at, characters_amount que envia backend
  const highlights = sanatizeHighlights(highlightsRaw);

  try {
    if (highlights && highlights.length > 0) {
      // 2- de ese array obtengo las palabras
      for (let i = 0; i < highlights.length; i += 1) {
        arrayOfWords.push({
          start_at: highlights[i].start_at,
          characters_amount: highlights[i].characters_amount,
          text: originalText.substr(highlights[i].start_at, highlights[i].characters_amount),
        });
      }
      let wordsHighlight = [];
      let text = originalText;
      // 3- a partir de esas palabras reemplazo de fin a inicio las palabras por un span con el classname de destaque
      for (let i = arrayOfWords.length - 1; i >= 0; i -= 1) {
        const start = arrayOfWords[i].start_at;
        const end = arrayOfWords[i].start_at + arrayOfWords[i].characters_amount;

        wordsHighlight.push(text.slice(0, start));
        wordsHighlight.push(getHighlight(text.slice(start, end), highlightClassName));

        text = text.substr(arrayOfWords[i].start_at + arrayOfWords[i].characters_amount);
      }
      wordsHighlight.push(text);
      wordsHighlight = wordsHighlight.filter(e => !!e);
      return wordsHighlight;
    }
    return originalText;
  } catch (e) {
    return originalText;
  }
};

export { getHighlightedText, sanatizeHighlights };
