import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import BannerDesktop from '../../../../components/buybox/banner';
import * as actions from '../../../../actions/vip';

const FEATURE_NAME = 'generic_banner_shipping';

const GENERIC_BANNER_PROPS = {
  id: FEATURE_NAME,
};

const mapStateToProps = ({ components: { [FEATURE_NAME]: genericBannerShipping } }, ownProps) => ({
  ...genericBannerShipping,
  ...ownProps,
  ...GENERIC_BANNER_PROPS,
});

const mapDispatchToProps = dispatch => ({
  onCloseModal: () => {
    dispatch(actions.fetchItemOnMeliplusUpdate('close_modal'));
  },
  meliplusUpsellingOnSuscription: () => {
    window.location.reload(true);
  },
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(BannerDesktop);
