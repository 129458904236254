import React from 'react';
import { string, number } from 'prop-types';

const ProgressBar = ({ className, percentage }) => (
  <div className={className}>
    <span className={`${className}__background`} />
    <span className={`${className}__fill-background`} style={{ width: `${percentage}%` }} />
  </div>
);

ProgressBar.propTypes = {
  className: string.isRequired,
  percentage: number,
};

ProgressBar.defaultProps = {
  percentage: 0,
};

export default React.memo(ProgressBar);
