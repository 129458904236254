import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import * as actions from '../../../../actions/vip/vis';

const FEATURE_NAME = 'description';

const mapStateToProps = ({ components: { [FEATURE_NAME]: description }, [FEATURE_NAME]: rootDescription }) => ({
  ...description,
  maxPages: description.max_pages,
  minPages: description.min_pages,
  focus: rootDescription && rootDescription.focus,
});

const mapDispatchToProps = dispatch => ({
  onScrollComponent: ({ focus }) => dispatch(actions.onScrollComponent({ component: FEATURE_NAME, focus })),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const component = Component => enhance(Component);

export default component;
