import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';

const FEATURE_NAME = 'share';

const mapStateToProps = ({ components: { [FEATURE_NAME]: share } }, ownProps) => ({
  action: share.action,
  permalink: share.permalink,
  title: share.share_title,
  shareActions: share.share_actions,
  ...ownProps,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME, { loader: false }),
  connect(mapStateToProps),
);

const component = Component => enhance(Component);

export default component;
