import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import jsonToProps from '../../../../components/message/jsonToProps';

const FEATURE_NAME = 'returns_notification_bottom';

const mapStateToProps = ({ components: { [FEATURE_NAME]: returnsNotificationBottom } }) => ({
  ...jsonToProps(returnsNotificationBottom),
  className: FEATURE_NAME,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

const returnsNotificationBottom = ReturnsNotificationBottom => enhance(ReturnsNotificationBottom);

export default returnsNotificationBottom;
export { mapStateToProps };
