import React from 'react';
import { string } from 'prop-types';

const DislikeFull = ({ className }) => (
  <svg
    aria-hidden
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M5.53083 10.0225L4.28565 9.58388L4.18818 1.63911L5.4278 1.6239L8.96556 0.930417C10.9525 0.540925 12.9345 1.62451 13.6792 3.5074L14.7491 6.20899C15.3387 7.69769 14.2539 9.31541 12.6528 9.33505L9.29453 9.37625L9.96036 11.2589C10.5153 12.828 9.6294 14.5395 8.02793 14.9925L5.53083 10.0225Z"
      fill="white"
    />
    <path d="M2.97355 0.454055L3.09867 10.6533L0.149535 10.6895L0.0244141 0.490234L2.97355 0.454055Z" fill="white" />
  </svg>
);

DislikeFull.propTypes = {
  className: string,
};

DislikeFull.defaultProps = {
  className: null,
};

export default React.memo(DislikeFull);
