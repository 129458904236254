import React from 'react';
import { string, bool, node, oneOf } from 'prop-types';
import { Message as AndesMessage } from '@andes/message';
import classnames from 'classnames';
import { validateSnackbarColor } from '../../utils/validators';

const namespace = 'ui-pdp-message';
const namecolorspace = 'ui-pdp-background-color';

const Message = ({
  type,
  title,
  hierarchy,
  closeable,
  className,
  children,
  text,
  actionsPosition,
  actions,
  bgColor,
}) => (
  <AndesMessage
    className={classnames(namespace, className, {
      [`${namecolorspace}--${bgColor}`]: bgColor,
      [`${namespace}--closeable`]: closeable,
    })}
    color={validateSnackbarColor(type)}
    hierarchy={hierarchy}
    closable={closeable}
    title={title}
    actionsPosition={actionsPosition}
    actions={actions}
  >
    {text || children}
  </AndesMessage>
);

Message.propTypes = {
  className: string,
  text: string,
  type: oneOf(['neutral', 'success', 'warning', 'error']),
  title: string,
  hierarchy: string,
  closeable: bool,
  children: node,
  actionsPosition: string,
  actions: node,
  bgColor: string,
};

Message.defaultProps = {
  className: null,
  title: null,
  text: null,
  type: 'neutral',
  hierarchy: 'quiet',
  closeable: false,
  actions: null,
  actionsPosition: 'bottom',
  bgColor: null,
};

export default Message;
