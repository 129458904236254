/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react';
import { arrayOf, shape, string, func } from 'prop-types';
import classNames from 'classnames';
import { Button } from '@andes/button';
import { trackEvent } from '../../lib/tracking';
import { isSupported } from '../../lib/local-storage';
import { stringIncludes } from '../../lib/includes';
import { isShippingCalculator, getShippingCalculatorProps } from './questions-shipping-calculator-props';
import Iframe from '../iframe';
import ActionModal from '../action-modal';

const namespace = 'ui-pdp-questions';

const QuestionsQuickAccess = ({ actions, title, onIframeMessage }) => {
  useEffect(() => {
    isSupported().then(() => {
      const scrollRestore = localStorage.getItem('scrollRestore');
      if (scrollRestore) {
        window.document.body.style = 'overflow:hidden';

        setTimeout(() => {
          window.scrollTo(0, scrollRestore);
          history.scrollRestoration = 'auto';
          localStorage.removeItem('scrollRestore');
          window.document.body.removeAttribute('style');
        }, 1200);
      }
    });
  });

  const getTarget = target => (isShippingCalculator(target) ? `${target}&quick_access=true` : target);

  const handleClick = () => {
    localStorage.setItem('scrollRestore', window.pageYOffset);
    history.scrollRestoration = 'manual';
  };

  return (
    <>
      <h3 className={`${namespace}__subtitle`}>{title.text}</h3>
      <div className={`${namespace}__list`}>
        {actions.map((action, i) => {
          const isNewVersionModal = stringIncludes(action.target, 'new_version=true');
          if (isNewVersionModal) {
            return (
              <ActionModal
                deviceType="mobile"
                key={action.label}
                messageId="quick-access-modal"
                label={action.label}
                url={getTarget(action.target)}
                linkClassName={`andes-button andes-button--quiet andes-button--medium ${namespace}__link`}
                modalClassName={classNames('ui-pdp-iframe-modal', `ui-pdp-questions-${i}`, {
                  'ui-pdp-generic-summary-modal ui-pdp-shipping-modal': isShippingCalculator(action.target),
                })}
                modalUrl={title ? `#questions-${i}` : null}
                modalTitle={action.modal_title}
                track={action.track}
                closeTrack={action.close_track}
                {...getShippingCalculatorProps(action.target)}
                closeModalLabel={action.close_modal_label}
                controlModalHeader
                showCloseButton
              >
                <Iframe
                  id="quick-access-iframe"
                  title={action.label.text}
                  src={getTarget(action.target)}
                  onMessage={onIframeMessage}
                  scrolling="no"
                  width="100%"
                />
              </ActionModal>
            );
          }
          return (
            <Button
              key={action.label}
              data-testid={`quick-access-${i}`}
              href={action.target}
              className={`${namespace}__link`}
              size="medium"
              hierarchy="quiet"
              onClick={() => {
                handleClick();
                trackEvent(action.track);
              }}
            >
              {action.label.text}
            </Button>
          );
        })}
      </div>
    </>
  );
};

QuestionsQuickAccess.propTypes = {
  actions: arrayOf(
    shape({
      label: shape({
        text: string.isRequired,
      }),
      target: string.isRequired,
    }),
  ).isRequired,
  title: shape({ text: string.isRequired }).isRequired,
  onIframeMessage: func,
};

QuestionsQuickAccess.defaultProps = {
  onIframeMessage: null,
};

export default QuestionsQuickAccess;
