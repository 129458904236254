import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import * as actions from '../../../../actions/vip';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';

const FEATURE_NAME = 'on_demand_iframe';

const mapStateToProps = ({ components: { [FEATURE_NAME]: mainActions } }) => mainActions;

const mapDispatchToProps = dispatch => ({
  onDemandIframeUpdate: params => {
    dispatch(actions.onDemandIframeUpdate(params));
  },
  onDemandIframeClose: () => dispatch(actions.onDemandIframeClose()),
  onCreateQuestionFromAi: ({ text, snackbar_message, suggestionTrack, source }) => {
    dispatch(actions.onCreateQuestionFromAi({ text, snackbar_message, suggestionTrack, source }));
  },
  showSnackbar: snackbar => dispatch(actions.showSnackbar(snackbar)),
  updateComponentsBottomSheet: () => dispatch(actions.updateComponentsBottomSheet()),
  showVariationsError: (deviceType, actionKey) => dispatch(actions.showVariationsError(deviceType, actionKey)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const onDemandIframe = OnDemandIframe => enhance(OnDemandIframe);

export default onDemandIframe;
export { mapDispatchToProps };
