import React from 'react';
import { node, string } from 'prop-types';
import Stars from '../stars';

const namespace = 'ui-pdp-reviews__rating';

const Rating = ({ average, children, label, stars }) => (
  <div className={namespace}>
    <div className={`${namespace}__summary`}>
      {average && <p className={`${namespace}__summary__average`}>{average}</p>}
      <div className={`${namespace}__summary__description`}>
        <div aria-hidden="true">
          <Stars className={`${namespace}__summary__rating`} value={stars} />
        </div>
        {label && <p className={`${namespace}__summary__label`}>{label}</p>}
      </div>
    </div>
    <div className={`${namespace}__extended`}>{children}</div>
  </div>
);

Rating.propTypes = {
  average: string.isRequired,
  children: node,
  label: string,
  stars: string.isRequired,
};

Rating.defaultProps = {
  label: null,
  children: null,
};

export default React.memo(Rating);
