import compose from '../../lib/compose';
import connect from '../../store/connect';
import withFeatureFlag from '../feature-flag/with-feature-flag';
import withRow from '../grid/row/with-row';

const FEATURE_NAME = 'cbt_summary';

const CBT_PROPS = {
  id: FEATURE_NAME,
};

const mapStateToProps = ({ components: { [FEATURE_NAME]: cbtSummary } }, ownProps) => ({
  ...cbtSummary,
  ...ownProps,
  ...CBT_PROPS,
});

const serializeOwnProps = (state, ownProps) => ({
  ...ownProps,
  ...CBT_PROPS,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

const noFeatureFlagConnect = connect(serializeOwnProps);

const getCbtSummary = component => enhance(component);
const noFeatureFlag = component => noFeatureFlagConnect(component);

export { noFeatureFlag };
export default getCbtSummary;
