import map from 'lodash/map';
import { idToUrl } from '../../lib/id-to-url';

const jsonToProps = ({ picture_config, elements }) => {
  const {
    template,
    template_thumbnail: templateThumbnail,
    template_thumbnail_2x: templateThumbnail2x,
  } = picture_config;

  return map(elements, element => ({
    ...element,
    picture: {
      ...element.picture,
      width: element.picture?.width || 500,
      height: element.picture?.height || 500,
      src: idToUrl(element.picture?.id, template),
      thumbnail: {
        src: idToUrl(element.picture?.id, templateThumbnail),
        src2x: templateThumbnail2x ? idToUrl(element.picture?.id, templateThumbnail2x) : null,
      },
    },
  }));
};

export default jsonToProps;
