import {
  FETCH_ON_CHANGE_INSTALLATION_COMPLETE,
  FETCH_ON_CHANGE_INSTALLATION_START,
  FETCH_ON_CHANGE_INSTALLATION_END,
} from '../../../../actions/vip/types';

const fetchOnChangeInstallationStart = state => ({
  ...state,
  components: {
    ...state.components,
    installations: {
      ...state.components.installations,
    },
    main_actions: {
      ...state.components.main_actions,
    },
    shipping_summary: {
      ...state.components.shipping_summary,
    },
    pick_up_summary: {
      ...state.components.pick_up_summary,
    },
    returns_summary: {
      ...state.components.returns_summary,
    },
    second_shipping_summary: {
      ...state.components.second_shipping_summary,
    },
    meliplus_summary: {
      ...(state.components.meliplus_summary || { state: 'hidden' }),
    },
    upselling_meliplus_summary: {
      ...(state.components.upselling_meliplus_summary || { state: 'hidden' }),
    },
  },
  isFetching: true,
});

const fetchOnChangeInstallationComplete = (state, action) => {
  const components = action.payload.components.short_description ?? action.payload.components;
  const installations = components.find(component => component.id === 'installations') || {};
  const main_actions = components.find(component => component.id === 'main_actions') || {};
  const shippingSummary = components.find(component => component.id === 'shipping_summary') || { state: 'HIDDEN' };
  const pickUpSummary = components.find(component => component.id === 'pick_up_summary') || { state: 'HIDDEN' };
  const returnsSummary = components.find(component => component.id === 'returns_summary') || { state: 'HIDDEN' };
  const secondShippingSummary = components.find(component => component.id === 'second_shipping_summary') || {
    state: 'HIDDEN',
  };
  const meliplusSummary = components.find(component => component.id === 'meliplus_summary') || {
    state: 'HIDDEN',
  };
  const upsellingMeliplusSummary = components.find(component => component.id === 'upselling_meliplus_summary') || {
    state: 'HIDDEN',
  };
  return {
    ...state,
    components: {
      ...state.components,
      installations: {
        ...installations,
      },
      main_actions: {
        ...main_actions,
      },
      shipping_summary: {
        ...shippingSummary,
      },
      pick_up_summary: {
        ...pickUpSummary,
      },
      returns_summary: {
        ...returnsSummary,
      },
      second_shipping_summary: {
        ...secondShippingSummary,
      },
      meliplus_summary: {
        ...meliplusSummary,
      },
      upselling_meliplus_summary: {
        ...upsellingMeliplusSummary,
      },
    },
    isFetching: false,
  };
};

const fetchOnChangeInstallationEnd = state => ({
  ...state,
  components: {
    ...state.components,
    installations: {
      ...state.components.installations,
    },
    main_actions: {
      ...state.components.main_actions,
    },
    shipping_summary: {
      ...state.components.shipping_summary,
    },
    pick_up_summary: {
      ...state.components.pick_up_summary,
    },
    returns_summary: {
      ...state.components.returns_summary,
    },
    second_shipping_summary: {
      ...state.components.second_shipping_summary,
    },
    meliplus_summary: {
      ...(state.components.meliplus_summary || { state: 'hidden' }),
    },
    upselling_meliplus_summary: {
      ...(state.components.upselling_meliplus_summary || { state: 'hidden' }),
    },
  },
  isFetching: false,
});

const installationsMapper = {
  [FETCH_ON_CHANGE_INSTALLATION_START]: fetchOnChangeInstallationStart,
  [FETCH_ON_CHANGE_INSTALLATION_COMPLETE]: fetchOnChangeInstallationComplete,
  [FETCH_ON_CHANGE_INSTALLATION_END]: fetchOnChangeInstallationEnd,
};

export { fetchOnChangeInstallationStart, fetchOnChangeInstallationComplete, fetchOnChangeInstallationEnd };
export default installationsMapper;
