import React from 'react';
import classNames from 'classnames';
import ChevronIcon from '../../../../icons/chevron';
import { namespace, quantityChevronPropTypes } from './model';

const QuantityChevron = ({ active = false, showError = false, disabled = false }) => (
  <span className={`${namespace}__chevron`}>
    <ChevronIcon
      className={classNames('ui-pdp-icon--chevron-down', {
        'ui-pdp-icon--chevron-down--active': active,
        'ui-pdp-icon--chevron-down--error': showError,
        'ui-pdp-icon--disabled': disabled,
      })}
    />
  </span>
);

QuantityChevron.propTypes = quantityChevronPropTypes;

export default QuantityChevron;
