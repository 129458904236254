import React from 'react';
import { LazyHydrate as Hydrator } from 'nordic/lazy';
import { string, node } from 'prop-types';

const Hydrate = ({ componentType, children }) => {
  switch (componentType) {
    case 'seller_recos':
      return children;
    default:
      return <Hydrator whenIdle>{children}</Hydrator>;
  }
};

Hydrate.propTypes = {
  componentType: string.isRequired,
  children: node.isRequired,
};

export default Hydrate;
