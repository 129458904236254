export const QUICK_ACTIONS = 'QUICK_ACTIONS';
export const SEARCH_BAR = 'SEARCH_BAR';
export const MAKE_QUESTION_MODAL = 'MAKE_QUESTION_MODAL';
export const MAKE_QUESTION = 'MAKE_QUESTION';
export const MAKE_QUESTION_SA = 'MAKE_QUESTION_SA';
export const ADDITIONAL_PARAMETERS = 'ADDITIONAL_PARAMETERS';
export const SMART_ANSWER_MODAL = 'SMART_ANSWER_MODAL';
export const NO_LAST_QUESTIONS = 'NO_LAST_QUESTIONS';
export const RESULTS = 'RESULTS';
export const CARD_ANSWER = 'CARD_ANSWER';
export const FEEDBACK = 'FEEDBACK';
export const OWNER_QUESTIONS = 'OWNER_QUESTIONS';
export const QUESTIONS = 'QUESTIONS';
export const SMART_ANSWER = 'SMART_ANSWER';
export const CALL_TO_ACTION = 'CALL_TO_ACTION';
export const PUSH_NOTIFICATION_QUESTION_MODAL = 'PUSH_NOTIFICATION_QUESTION_MODAL';
