import React from 'react';
import { arrayOf, shape, string, number, bool } from 'prop-types';
import classnames from 'classnames';
import applyClasses from '../../lib/apply-classes';
import ProgressBar from './components/progress-bar';
import RatingLevelsTitle from './components/rating-levels-title';

const namespace = 'ui-vpp-rating';

const RatingLevels = React.forwardRef(({ title, levels, rightAlign, color }, ref) => (
  <div className={namespace}>
    {title && <RatingLevelsTitle className={`${namespace}__title`} title={title} />}
    <ul ref={ref}>
      {levels.map(({ label, percentage, value, value_label }) => (
        <li key={`${namespace}__level-${label.text}`} className={`${namespace}__level`}>
          <div className={`${namespace}__level__column ${namespace}__level__text-container`}>
            <span
              className={classnames(
                `${namespace}__level__text`,
                { [`${namespace}__level__text--right`]: rightAlign },
                { [`${namespace}__level__text--left`]: !rightAlign },
                applyClasses({ size: label.font_size, family: label.font_family, color: label.color || color }),
              )}
              data-testid="apparel-size-specs__label"
            >
              {label.text}
            </span>
          </div>
          <div aria-hidden="true" className={`${namespace}__level__column ${namespace}__level__progress-bar-container`}>
            <ProgressBar className={`${namespace}__level__progress-bar`} percentage={percentage} />
          </div>
          <div className={`${namespace}__level__column ${namespace}__level__value-container`}>
            <span
              className={applyClasses({
                size: label.font_size,
                family: label.font_family,
                color: label.color || color,
                className: `${namespace}__level__value`,
              })}
            >
              {value_label && value_label.text ? value_label.text : value}
            </span>
          </div>
        </li>
      ))}
    </ul>
  </div>
));

RatingLevels.propTypes = {
  levels: arrayOf(
    shape({
      label: shape({
        text: string.isRequired,
      }).isRequired,
      percentage: number.isRequired,
      value: number.isRequired,
      value_label: shape({
        text: string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  rightAlign: bool,
  color: string,
  title: shape({
    text: string.isRequired,
    color: string,
    font_size: string,
    font_family: string,
    values: arrayOf(
      shape({
        value: shape({
          text: string.isRequired,
          color: string,
          font_size: string,
          font_family: string,
        }).isRequired,
      }),
    ),
  }),
};

RatingLevels.defaultProps = {
  rightAlign: false,
  color: 'GRAY',
};

export default React.memo(RatingLevels);
