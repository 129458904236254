import { CLOSE_MESSAGE } from '@vpp-frontend-components/action-modal';
import { trackEvent } from '@vpp-frontend-components/common';

const getActionModalConfig = (props, _, context) => {
  const { dependencies } = context;
  const { HistoryService } = dependencies;
  return {
    onCloseModal: event => {
      const { modalUrl } = props;

      if (modalUrl && event?.data !== CLOSE_MESSAGE) {
        HistoryService.back();
      }
    },
    onOpenModal: () => {
      const { track } = props;
      trackEvent(track);
    },
  };
};

export { getActionModalConfig };
