import { supportsHistory } from '../lib/dom-utils';
import factory from '../lib/history';

let history;

// global window
class Service {
  static getHistory() {
    if (!history) {
      history = factory();
    }
    return history;
  }

  static push(path, state) {
    const canUseHistory = supportsHistory();
    if (canUseHistory) {
      Service.getHistory().push(path, state);
    }
  }

  static pushParam(key, value, replace = false) {
    const canUseHistory = supportsHistory();

    if (canUseHistory) {
      const currentUrl = new URL(window.location.href);
      if (currentUrl && currentUrl.searchParams) {
        currentUrl.searchParams.set(key, value);
      }
      const { search } = currentUrl;
      Service.getHistory()[replace ? 'replace' : 'push']({ search });
      return currentUrl.href;
    }
    return null;
  }

  static replaceProductAndParam(path, state, key, value) {
    const canUseHistory = supportsHistory();
    if (canUseHistory && path) {
      const url = window.location.origin + path;
      const currentUrl = new URL(url);
      if (currentUrl && currentUrl.searchParams) {
        currentUrl.searchParams.set(key, value);
      }
      // This is the correct way to manipulate exhausting url params
      // Pathname es lo que viene despues deorigin hasta los parametros
      // search, son los parametros (query string)
      // state es ilustrativo
      Service.getHistory().replace({
        pathname: currentUrl.pathname,
        search: currentUrl.search,
        state,
      });
    }
  }

  static back() {
    const canUseHistory = supportsHistory();
    if (canUseHistory) {
      Service.getHistory().goBack();
    }
  }

  static replacePathWithHash(hashValue) {
    const canUseHistory = supportsHistory();
    if (canUseHistory) {
      const currentUrl = new URL(window.location);
      let newUrl = { pathname: `${currentUrl.pathname}#${hashValue}` };
      if (currentUrl.search) {
        newUrl = { pathname: currentUrl.pathname, search: `${currentUrl.search}#${hashValue}` };
      }
      Service.getHistory().replace(newUrl);
    }
  }

  static deleteHash() {
    const canUseHistory = supportsHistory();
    if (canUseHistory) {
      const currentUrl = new URL(window.location);
      Service.getHistory().replace({
        pathname: currentUrl.pathname,
        search: currentUrl.search,
      });
    }
  }
}

export default Service;
