const headerMapper = ({ title } = {}) => {
  if (!title) {
    return null;
  }
  return {
    title,
  };
};

export default headerMapper;
