import { trackEvent } from '@vpp-frontend-components/common';

const SHIPPING_HUB_EVENT_CLOSE = 'nav:cp:close';

const getActionModalConfig = (props, ref) => ({
  onOpenModal: () => {
    const { track } = props;
    trackEvent(track);
  },
  customEventMessageHandler: event => {
    const { data } = event;
    const { type } = data;
    const methods = ref.current || {};
    const { setShowLoading, setIsOpenModal } = methods;

    if (type) {
      switch (type) {
        case SHIPPING_HUB_EVENT_CLOSE:
          setIsOpenModal(false);
          setShowLoading(false);
          break;
        default:
          break;
      }
    }
  },
});

export { getActionModalConfig };
