const jsonToProps = ({ title, related_searches: relatedSearches }) => ({
  label: title.text,
  items: relatedSearches.map(related => ({
    href: related.target,
    text: related.label.text,
    id: related.label.text,
  })),
});

export default jsonToProps;
