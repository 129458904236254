import React from 'react';
import { func, string } from 'prop-types';
import Message, { MessageActions } from '@andes/message';
import { MessageType } from './types';
import { validateSnackbarColor } from '../../utils/validators';

const namespace = 'ui-pdp-compatibilities';

const CompatibilitiesMessageComponent = ({ message, onChange, userSelection }) => (
  <>
    {message && (
      <Message
        actions={
          message.action && (
            <MessageActions
              primary={{
                onClick: onChange,
                text: message.action.label.text,
              }}
            />
          )
        }
        actionsPosition={message.action ? 'right' : null}
        className={`${namespace}__message`}
        color={validateSnackbarColor(message.type.toLowerCase())}
        hierarchy="quiet"
      >
        <div data-testid="feedback">
          {message.label ? message.label.text : ''}{' '}
          {userSelection && !message.action && <strong>{userSelection}.</strong>}
          {message.label2 && message.label2.text}
        </div>
      </Message>
    )}
  </>
);

CompatibilitiesMessageComponent.namespace = namespace;

CompatibilitiesMessageComponent.propTypes = {
  onChange: func,
  message: MessageType,
  userSelection: string,
};

CompatibilitiesMessageComponent.defaultProps = {
  message: null,
  onChange: () => null,
  userSelection: null,
};

export default CompatibilitiesMessageComponent;
