import React from 'react';
import { string, shape, func } from 'prop-types';
import { Button } from '@andes/button';
import { Tooltip } from '@andes/tooltip';
import QuestionsAiDenounceMenuContent from './questions-ai-denounce-menu-content';
import useDenounceMenu from '../../../../pages/questions-ai/hooks/use-denounce-menu';

const namespace = `ui-vpp-questions-ai-denounce`;
const DOTS_AMOUNT = 3;

const QuestionsAiDenounceMenu = ({ text, track, snackbar_message, showSnackbar, saveFrontendStatsd, answer }) => {
  const { isVisibleMenu, handleOnDenounce, handleMenu, handleClickOutside } = useDenounceMenu({
    track,
    snackbar_message,
    showSnackbar,
    saveFrontendStatsd,
    source: 'vpp',
  });

  return (
    <Tooltip
      className={`${namespace}__tooltip`}
      actions={
        <QuestionsAiDenounceMenuContent
          text={text}
          handleOnDenounce={handleOnDenounce}
          handleClickOutside={() => handleClickOutside}
          isVisibleMenu={isVisibleMenu}
          answer={answer}
        />
      }
      onClose={handleClickOutside}
      side="bottomRight"
      trigger="no-trigger"
      open={isVisibleMenu}
    >
      <Button
        srLabel={text}
        className={`${namespace}__tooltip--button`}
        data-testid="denounce-button"
        onClick={handleMenu}
        hierarchy="transparent"
      >
        {DOTS_AMOUNT &&
          Array.from({ length: DOTS_AMOUNT }, (_, index) => <span key={index} className="denounce-dropdown__circle" />)}
      </Button>
    </Tooltip>
  );
};

QuestionsAiDenounceMenu.propTypes = {
  text: string.isRequired,
  track: shape({}).isRequired,
  snackbar_message: shape({}),
  showSnackbar: func.isRequired,
  saveFrontendStatsd: func.isRequired,
  answer: string.isRequired,
};

QuestionsAiDenounceMenu.defaultProps = {
  snackbar_message: null,
};

export default QuestionsAiDenounceMenu;
