import React from 'react';
import StyledLabel from '../../../../styled-label';
import classnames from 'classnames';
import { generateKeyToComponent } from '../../../../../utils/validators';
import { namespace, quantityInputLabelsPropTypes } from './model';

const QuantityInputLabels = ({ subtitles, bulkSale = false, hasCustomOptions = false }) => (
  <>
    {subtitles &&
      subtitles.map(subtitle => (
        <StyledLabel
          key={generateKeyToComponent(subtitle)}
          className={classnames(`${namespace}__stock`, {
            [`${namespace}__stock__yield`]: bulkSale,
            [`${namespace}__stock__custom-options`]: hasCustomOptions,
          })}
          as="p"
          {...subtitle}
        />
      ))}
  </>
);

QuantityInputLabels.propTypes = quantityInputLabelsPropTypes;

export default QuantityInputLabels;
