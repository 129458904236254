import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import jsonToProps from '../../../../components/message/jsonToProps';
import * as actions from '../../../../actions/vip';

const FEATURE_NAME = 'compats_feedback';

const mapStateToProps = ({ components: { [FEATURE_NAME]: itemStatus, header_store, header_top_brand_vip } }) => {
  const messageProps = jsonToProps(itemStatus);
  const isTopBrand = !!header_top_brand_vip && !!header_top_brand_vip.state === 'VISIBLE';
  return {
    ...messageProps,
    isOfficialStore: !!header_store || isTopBrand,
  };
};

const mapDispatchToProps = dispatch => ({
  onClose: updateState =>
    new Promise(resolve => {
      resolve(dispatch(actions.cleanCompatsFeedback({ updateState })));
    }),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const component = Component => enhance(Component);

export default component;
