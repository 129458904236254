const mapJsonToProps = (
  {
    carousel: { title },
    carousel_config: {
      item_id,
      product_id,
      category_id,
      picture_id,
      variation_id,
      state_id,
      encoded_filters,
      zone_models,
      recommendation_from_middle_end,
      client,
      d2id,
      host_rewrite,
      limit,
      ignore_min_reco_size,
      page,
      positions,
      recommended,
      site_id,
      vid,
      q,
      min_recomms,
      web_device,
      pads_vip_case,
      platform,
      site_platform,
      zip_code,
      bbw_ads,
      include_only_flex,
      lazy,
    },
    is_carousel_polycard,
  },
  recommendations_context,
  globalContext,
) => {
  const ADD_TO_CART_ROUTE = 'p/api/combos/shipping';
  const result = {
    productId: product_id || recommendations_context?.product_id,
    itemId: item_id || recommendations_context?.item_id,
    variationId: variation_id || recommendations_context?.variation_id || globalContext.variation_id,
    categoryId: category_id || recommendations_context?.category_id || globalContext.category_id,
    picture_id: picture_id || recommendations_context?.picture_id || globalContext.picture_id,
    stateId: state_id || recommendations_context?.state_id,
    encodedFilters: encoded_filters || recommendations_context?.encoded_filters,
    zoneModels: zone_models || recommendations_context?.zone_models,
    apiresponse: recommendation_from_middle_end,
    client,
    d2Id: d2id,
    limit,
    host_rewrite,
    ignore_min_reco_size,
    page,
    positions,
    siteId: site_id || globalContext.siteId,
    thousandSeparator: globalContext.thousandSeparator,
    decimalSeparator: globalContext.decimalSeparator,
    vid,
    q,
    min_recomms,
    web_device: web_device || globalContext.webDevice,
    pads_vip_case,
    platform,
    site_platform,
    title,
    csrfToken: globalContext.csrfToken,
    addToCartPublicEndpoint: ADD_TO_CART_ROUTE,
    bbw_ads,
    include_only_flex,
    zipCode: zip_code,
    isPolycard: is_carousel_polycard,
    lazy: lazy ?? true,
  };
  if (recommended && recommended.force_categories) {
    result['RECOMMENDED.force_categories'] = recommended.force_categories;
  }
  if (recommended && recommended.cnt) {
    result['RECOMMENDED.cnt'] = recommended.cnt;
  }
  if (globalContext.isWebView) {
    result.webView = 'true';
  }
  return result;
};

export default mapJsonToProps;
