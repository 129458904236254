import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withRow from '../../../../components/grid/row/with-row';

const FEATURE_NAME = 'highlighted_specs_attrs';

const mapStateToProps = ({ components: { [FEATURE_NAME]: highlightedSpecs }, id }, { className }) => ({
  ...highlightedSpecs,
  productId: id,
  className,
  feature_name: FEATURE_NAME,
});

const mapDispatchToProps = dispatch => ({
  getDispatch: () => dispatch,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const highlightedSpecsAttrs = HighlightedSpecsAttrs => enhance(HighlightedSpecsAttrs);

export default highlightedSpecsAttrs;
