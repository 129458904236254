import { useState, useRef, useCallback, useEffect, useContext, startTransition } from 'react';
import { trackQuantityEvent, trackEvent } from '../../../../lib/tracking';
import HistoryService from '../../../../services/history';
import useOnClickOutside from '../../../../hooks/use-onclick-outside';
import StaticPropsContext from '../../../context/static-props';
import customOptions from '../../../../utils/quantity-custom-options';
import { runSchedulerTask } from '../../../../utils/validators';

const useQuantitySelectorDesktop = ({ picker, quantitySelector, setQuantity, buttonFocus }) => {
  const { layout } = useContext(StaticPropsContext);
  const yieldValue = quantitySelector?.selector?.template?.yield;
  const bulkSale = !!yieldValue;
  const hasCustomOptions = customOptions.has(quantitySelector?.selector?.template?.custom_options);
  const customOptionsItems = customOptions.getItemsFormatted(quantitySelector?.selector?.template?.custom_options);
  const ref = useRef();
  const triggerRef = useRef();
  const listPickerRef = useRef();
  const inputRef = useRef();
  const [visible, setVisible] = useState(false);
  const [isClose, setIsClose] = useState(false);
  const initialQuantity = bulkSale ? picker.selected * yieldValue : picker.selected;
  const [localQuantity, setLocalQuantity] = useState(initialQuantity);

  useEffect(() => {
    if (isClose || buttonFocus) {
      document.querySelector('#quantity-selector').focus();
    }
  }, [isClose, buttonFocus]);

  useEffect(() => {
    let observer = null;
    if (triggerRef.current && visible) {
      observer = new IntersectionObserver(
        ([entry]) => {
          if (!entry.isIntersecting) {
            setVisible(false);
          }
        },
        {
          threshold: 0.1,
        },
      );

      observer.observe(triggerRef.current);
    }
    return () => observer && observer.disconnect();
  }, [visible]);

  useEffect(() => {
    if (visible) {
      trackEvent(picker.track);
    }
  }, [visible, picker.track]);

  useEffect(() => {
    if (hasCustomOptions) {
      setLocalQuantity(initialQuantity);
    }
  }, [initialQuantity, hasCustomOptions]);

  useOnClickOutside(ref, e => {
    if (!triggerRef.current.contains(e.target)) {
      setVisible(false);
    }
  });

  const onTriggerClick = useCallback(e => {
    e.preventDefault();
    setVisible(!visible);
    setIsClose(false);
    if (hasCustomOptions) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 50);
    }
  }, [visible, hasCustomOptions]);

  const onClickListItem = ({ event, quantity, selected, typeInput }) => {
    if (event) {
      event.preventDefault();
    }

    let totalQuantity = quantity;
    if (bulkSale) {
      totalQuantity = Math.ceil(parseFloat((quantity / yieldValue).toFixed(4)));
    }

    if (totalQuantity !== selected || typeInput) {
      setLocalQuantity(bulkSale ? totalQuantity * yieldValue : totalQuantity);
      const { track } = quantitySelector;
      if (hasCustomOptions) {
        // Request from the Product Team to track whether the quantity was changed by input or by clicking on the list when priced by quantity
        const selected_from = typeInput ? 'input' : 'list';
        track.melidata_event = {
          ...track.melidata_event,
          event_data: {
            ...track.melidata_event.event_data,
            selected_from,
          },
        };
      }
      startTransition(() => {
        setQuantity(totalQuantity);
        trackQuantityEvent(track, totalQuantity);
        runSchedulerTask(() => {
          HistoryService.pushParam('quantity', totalQuantity, true);
        }, 'background');
      });
    }
    setVisible(false);
  };

  const closeTooltip = e => {
    if (e.keyCode === 27) {
      setVisible(false);
      setIsClose(true);
    }
  };

  return {
    layout,
    yieldValue,
    bulkSale,
    hasCustomOptions,
    customOptionsItems,
    ref,
    triggerRef,
    listPickerRef,
    inputRef,
    visible,
    setVisible,
    isClose,
    setIsClose,
    localQuantity,
    setLocalQuantity,
    onTriggerClick,
    onClickListItem,
    closeTooltip,
  };
};

export default useQuantitySelectorDesktop;
