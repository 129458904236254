import React, { useState, useEffect } from 'react';
import { func, bool, string } from 'prop-types';
import classnames from 'classnames';
import IconFactory from '../../../icons/factory-builder';

const namespace = `ui-vpp-questions-ai-denounce`;

const QuestionsAiDenounceMenuContent = ({ text, handleOnDenounce, handleClickOutside, isVisibleMenu, answer }) => {
  const [isDenounced, setIsDenounced] = useState(false);

  useEffect(() => {
    setIsDenounced(false);
  }, [answer]);

  const handleOnClick = () => {
    handleOnDenounce();
    setIsDenounced(true);

    if (isVisibleMenu) {
      handleClickOutside();
    }
  };

  return (
    <ul className={`${namespace}-content`}>
      <li className={`${namespace}-content__li`}>
        <button
          type="button"
          aria-label={text}
          className={classnames(`${namespace}-content__item-menu`, {
            [`${namespace}-content__item-menu--denounced`]: isDenounced,
          })}
          data-testid="denounce-button"
          onClick={handleOnClick}
        >
          {IconFactory('flag')}
          <span>{text}</span>
        </button>
      </li>
    </ul>
  );
};

QuestionsAiDenounceMenuContent.propTypes = {
  handleOnDenounce: func.isRequired,
  handleClickOutside: func.isRequired,
  isVisibleMenu: bool.isRequired,
  text: string.isRequired,
  answer: string.isRequired,
};

export default QuestionsAiDenounceMenuContent;
