import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';

const ICON_ID = 'response';
const namespace = 'ui-pdp-icon';

const IconResponse = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconResponse.propTypes = {
  className: string,
};

IconResponse.defaultProps = {
  className: null,
};

IconResponse.ICON_ID = ICON_ID;

export default React.memo(IconResponse);
