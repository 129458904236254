import React from 'react';
import RecommendationsIconFactory from './icon-factory';
import { RecommendationsCombosDesktop } from '@recommendations-fe/combos';
import getCombos from './combos';

const Component = props => <RecommendationsCombosDesktop {...props} IconFactory={RecommendationsIconFactory} />;

const combos = getCombos(Component);

export default combos;
