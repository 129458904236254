import { FETCH_ON_MELIPLUS_UPDATE_START, FETCH_ON_MELIPLUS_UPDATE_COMPLETE } from '../../../../actions/vip/types';
import updateArrayComponents from '../../../helpers/update-array-components';

const fetchOnMeliplusUpdateStart = state => ({ ...state, isFetching: true, error: null });

const fetchOnMeliplusUpdateComplete = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    ...action.payload.components,
    fixed: {
      ...state.components.fixed,
      ...action.payload.components.fixed,
    },
    ...updateArrayComponents(state.components, action.payload.components),
  },
  preload: action.payload.preload,
  isFetching: false,
});

const meliplusMapper = {
  [FETCH_ON_MELIPLUS_UPDATE_START]: fetchOnMeliplusUpdateStart,
  [FETCH_ON_MELIPLUS_UPDATE_COMPLETE]: fetchOnMeliplusUpdateComplete,
};

export { fetchOnMeliplusUpdateStart, fetchOnMeliplusUpdateComplete };
export default meliplusMapper;
