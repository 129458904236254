import React from 'react';
import { shape, node } from 'prop-types';
import { VppProvider } from './vpp-context';

const StaticPropsContext = React.createContext();
const { Provider, Consumer: StaticPropsConsumer } = StaticPropsContext;

const StaticPropsProvider = ({ children, value, icons }) => (
  <VppProvider icons={icons} staticProps={value}>
    <Provider value={value}>{children}</Provider>
  </VppProvider>
);

StaticPropsProvider.propTypes = {
  children: node.isRequired,
  value: shape({}),
  icons: shape({}),
};

export default StaticPropsContext;
export { StaticPropsProvider, StaticPropsConsumer };
