import React, { useContext } from 'react';
import { shape, bool, string, func } from 'prop-types';
import StyledLabel from '../styled-label';
import componentEnhance from '../../lib/component-enhance';
import Message from '.';
import StaticPropsContext from '../context/static-props';
import { constants } from '../../utils/constants';

const namespace = 'ui-pdp-question-warning';
const { devices } = constants;

const QuestionWarningMessage = ({ showVariationsError, customMessage }) => {
  const { deviceType } = useContext(StaticPropsContext);
  const { hierarchy, message_type, closeable, primary_action, body } = customMessage;

  const handleMessageClick = () => {
    if (deviceType === devices.DESKTOP) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      showVariationsError(deviceType, 'QADB');
    }

    if (deviceType === devices.MOBILE) {
      showVariationsError(deviceType, 'QADB');
    }
  };

  return (
    <Message className="mt-20" hierarchy={hierarchy} type={message_type} closeable={closeable}>
      <StyledLabel className={`${namespace}__text`} as="p" text={body.text} />
      <button
        type="button"
        className={`${namespace}__action`}
        data-testid="warning-action"
        onClick={handleMessageClick}
      >
        {componentEnhance.jsx(primary_action.label.text)}
      </button>
    </Message>
  );
};

QuestionWarningMessage.propTypes = {
  showVariationsError: func,
  customMessage: shape({
    body: shape({
      text: string,
    }),
    hierarchy: string,
    closeable: bool,
    message_type: string,
    primary_action: shape({
      label: shape({
        text: string,
        color: string,
        size: string,
      }),
    }).isRequired,
  }).isRequired,
};

QuestionWarningMessage.defaultProps = {
  showVariationsError: () => {},
  customMessage: null,
};

export default QuestionWarningMessage;
