import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import GalleryWrapper from '../../../../components/gallery';
import jsonToProps from '../../../../components/gallery/json-to-props';
import * as actions from '../../../../actions/vip';

const FEATURE_NAME = 'gallery';

const mapStateToProps = ({
  components: {
    fixed: { [FEATURE_NAME]: gallery },
    header: { title },
  },
}) => jsonToProps({ ...gallery, titleForAlt: title });
/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  showSnackbar: props => dispatch(actions.showSnackbar({ ...props })),
});

const enhance = compose(
  withFeatureFlag(`fixed.${FEATURE_NAME}`),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(GalleryWrapper);
