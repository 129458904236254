import React from 'react';
import { string, bool } from 'prop-types';
import classnames from 'classnames';
import colornames from '../../../lib/colornames';
import { namespace, showError } from '../utils';
import ErrorMessage from './error-message';
import IconFactory from '../../icons/factory-builder';
import customPropTypes from '../utils/proptypes';

const PickerTitle = ({ picker, pickerLabelId, pickerSelectedSubtitleId, hovered }) => (
  <div className={`${namespace}__title`}>
    {picker.label && (
      <p
        className={classnames(`${namespace}__label`, colornames(picker.label), {
          [colornames(picker.error_message && picker.error_message.label.color)]: showError(picker) && !hovered,
        })}
      >
        {picker.label.text}
        <span className={classnames(`${namespace}__selected-label`, colornames(picker.selected_option))}>
          {picker.selected_option && picker.selected_option.text_icon && (
            <span id={`icon-${pickerLabelId}`}>
              {IconFactory(picker.selected_option.text_icon.id, colornames(picker.selected_option.text_icon.color))}
            </span>
          )}
          <span id={pickerLabelId} className={`${namespace}__selected-text`}>
            {picker.selected_option.text}
          </span>
          {picker.error_message && !picker.error_message.is_deferred && !hovered && (
            <ErrorMessage pickerId={pickerLabelId} error={picker.error_message.selected_option} />
          )}
        </span>
      </p>
    )}
    {picker.selected_subtitle && (
      <span
        id={pickerSelectedSubtitleId}
        className={classnames(`${namespace}__subtitle`, colornames(picker.selected_subtitle))}
      >
        {picker.selected_subtitle.text}
      </span>
    )}
  </div>
);

PickerTitle.propTypes = {
  picker: customPropTypes.picker,
  pickerLabelId: string,
  pickerSelectedSubtitleId: string,
  hovered: bool,
};

export default PickerTitle;
