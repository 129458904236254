import {
  FETCH_MODAL_QUESTIONS_START,
  FETCH_MODAL_QUESTIONS_COMPLETE,
  FETCH_MODAL_QUESTIONS_ERROR,
} from '../../../../actions/vip/vis/types';
import { messagesErrorScreen } from '../../../../lib/get-message-error';

const fetchModalQuestionsStart = state => ({
  ...state,
  questions_modal: {},
  message_modal: {},
});

const fetchModalQuestionsComplete = (state, action) => ({
  ...state,
  questions_modal: action.payload.questions_modal,
  message_modal: {},
});

const fetchModalQuestionsError = state => {
  const { title, description } = messagesErrorScreen(state.siteId);
  const errorText = `${title} - ${description}`;
  return {
    ...state,
    questions_modal: {},
    message_modal: {
      text: errorText,
      type: 'error',
    },
  };
};

const questionsModalMapper = {
  [FETCH_MODAL_QUESTIONS_START]: fetchModalQuestionsStart,
  [FETCH_MODAL_QUESTIONS_COMPLETE]: fetchModalQuestionsComplete,
  [FETCH_MODAL_QUESTIONS_ERROR]: fetchModalQuestionsError,
};

export { fetchModalQuestionsStart };
export { fetchModalQuestionsComplete };
export { fetchModalQuestionsError };
export default questionsModalMapper;
