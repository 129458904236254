/**
 * Module dependencies
 */
import React from 'react';

import { StoreConsumer } from './context';
import ErrorBoundary from './error';
import getDisplayName from '../lib/get-display-name';

const noop = () => {};

/**
 * base-connect
 */

// internal state component, without history or global actions to dispatch
const baseConnect = (mapStateToProps = noop) => WrappedComponent => {
  const ConnectedComponent = ownProps => (
    <StoreConsumer>
      {({ state }) => (
        <ErrorBoundary>
          <WrappedComponent {...mapStateToProps(state, ownProps)} />
        </ErrorBoundary>
      )}
    </StoreConsumer>
  );
  ConnectedComponent.displayName = `Connect(${getDisplayName(WrappedComponent)})`;

  return ConnectedComponent;
};

/**
 * Expose connect
 */

export default baseConnect;
