import React from 'react';
import { shape, bool, string } from 'prop-types';
import classNames from 'classnames';
import StyledLabel from '../styled-label';

const { useState, useCallback } = React;

const namespace = 'ui-pdp-questions__questions-list';

const Question = ({ content, date, denounce, denounceTemplate, deviceType, hasAnswer, shouldScrollToQuestion }) => {
  const [denounceVisibility, setDenounceVisibility] = useState(false);
  const [scrolledOnce, setScrolledOnce] = useState(false);
  const ref = React.createRef();
  React.useEffect(() => {
    if (shouldScrollToQuestion && !scrolledOnce) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setScrolledOnce(true);
    }
  }, [ref, shouldScrollToQuestion, scrolledOnce]);
  const submitAction = useCallback(() => {
    window.open(denounce.target, '_blank');
  }, [denounce]);
  return (
    <div
      ref={ref}
      className={`${namespace}__question-wrap`}
      onMouseEnter={() => {
        setDenounceVisibility(true);
      }}
      onMouseLeave={() => {
        setDenounceVisibility(false);
      }}
    >
      <StyledLabel
        className={`${namespace}__question`}
        as="span"
        font_size="SMALL"
        font_family="REGULAR"
        color="BLACK"
        {...content}
      />
      {date && (
        <StyledLabel
          className={`${namespace}__question-item__separate`}
          font_size="XSMALL"
          font_family="REGULAR"
          as="span"
          {...date}
        />
      )}
      {(deviceType !== 'mobile' || !hasAnswer) && denounce && (
        <StyledLabel
          className={classNames(
            `andes-button andes-button--medium andes-button--transparent`,
            `${namespace}__question-item__denounce ${namespace}__question-item__denounce__overriding-default-button`,
            {
              [`${namespace}__item__denounce--VISIBLE`]: denounceVisibility,
            },
          )}
          onClick={submitAction}
          as="button"
          font_size="XSMALL"
          font_family="REGULAR"
          accessibilityText={denounceTemplate.accessibility_text}
          data-testid="question-button-denounce"
          {...denounceTemplate}
        />
      )}
    </div>
  );
};

Question.propTypes = {
  content: shape({
    text: string,
    color: string,
  }).isRequired,
  hasAnswer: bool.isRequired,
  date: shape({
    text: string,
    color: string,
  }),
  denounce: shape({
    target: string,
  }),
  denounceTemplate: shape({
    text: string,
    color: string,
  }).isRequired,
  deviceType: string.isRequired,
  shouldScrollToQuestion: bool,
};

Question.defaultProps = {
  denounce: null,
  date: null,
  shouldScrollToQuestion: false,
};

export default Question;
