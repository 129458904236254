const getSanitizedTitle = pictures => {
  const picturesArray = typeof pictures === 'object' && !Array.isArray(pictures) ? Object.values(pictures) : pictures;

  if (!Array.isArray(picturesArray)) {
    return null;
  }
  return pictures[0]?.sanitized_title;
};

export default getSanitizedTitle;
