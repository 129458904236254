import React from 'react';
import { string } from 'prop-types';

const ICON_ID = 'like';

const Like = ({ className }) => (
  <svg data-testid="like-svg" className={className} width="20" height="20" viewBox="0 0 20 20">
    <use href={`#${ICON_ID}`} />
  </svg>
);

Like.propTypes = {
  className: string,
};

Like.defaultProps = {
  className: null,
};

export default React.memo(Like);
export { ICON_ID };
