import React from 'react';
import { shape, string, func, bool } from 'prop-types';
import classnames from 'classnames';
import IconFactory from '../icons/factory-builder';

const namespace = 'ui-pdp-collapsable';

const ActionCollapsableExpand = ({ action, collapsed, onClick }) => {
  const buttonContent = (
    <>
      {action.label.text}
      {IconFactory(
        { id: 'chevron' },
        classnames(`${namespace}__chevron`, {
          'ui-pdp-icon--chevron-down': collapsed,
          'ui-pdp-icon--chevron-down--active': !collapsed,
        }),
      )}
    </>
  );

  const button =
    action &&
    (action.target ? (
      <a
        data-testid="action-collapsable-target"
        className={`${namespace}__action`}
        title={action.label.text}
        onClick={onClick}
        href={action.target}
      >
        {buttonContent}
      </a>
    ) : (
      <button
        className={`${namespace}__action`}
        type="button"
        onClick={onClick}
        data-testid="action-collapsable-target"
      >
        {buttonContent}
      </button>
    ));

  return button;
};

ActionCollapsableExpand.propTypes = {
  action: shape({
    label: shape({
      text: string.isRequired,
      color: string,
    }).isRequired,
    target: string,
  }).isRequired,
  collapsed: bool.isRequired,
  onClick: func,
};

ActionCollapsableExpand.defaultProps = {
  onClick: null,
};

export default ActionCollapsableExpand;
