import React from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import { getLocationForHash } from '../../lib/dom-utils';
import { ActionType, InputType, ModalType, MessageType } from './types';
import CompatibilitiesWidgetComponent from './compatibilities-widget-component';
import CompatibilitiesModalComponent from './compatibilities-modal-component';

const namespace = 'ui-pdp-compatibilities';

const CompatibilitiesComponent = ({
  className,
  id,
  title,
  description,
  feature,
  action,
  modal,
  inputs,
  message,
  success,
  onModalOpen,
  onFormChange,
  onFormSubmit,
  isFetching,
}) => {
  const MessageActions = (
    <CompatibilitiesModalComponent
      buttonLabel={action.label}
      modalUrl={title ? getLocationForHash(`compatibilities-modal`) : null}
      modalTitle={modal.title}
      description={modal.description}
      helpText={modal.help_text}
      action={modal.action}
      userSelection={modal.user_selection}
      inputs={inputs}
      message={message || modal.message}
      isFetching={isFetching}
      success={success}
      onOpen={onModalOpen}
      onChange={onFormChange}
      onSubmit={onFormSubmit}
      table={modal.table}
    />
  );

  return (
    <CompatibilitiesWidgetComponent
      className={className}
      id={id}
      actions={MessageActions}
      title={title}
      description={description}
      feature={feature}
      userSelection={modal.user_selection}
      message={modal.message}
    />
  );
};

CompatibilitiesComponent.namespace = namespace;

CompatibilitiesComponent.propTypes = {
  className: string,
  id: string,
  title: string.isRequired,
  description: string.isRequired,
  feature: string.isRequired,
  action: ActionType.isRequired,
  modal: ModalType.isRequired,
  inputs: arrayOf(InputType),
  success: bool,
  onModalOpen: func.isRequired,
  onFormChange: func.isRequired,
  onFormSubmit: func.isRequired,
  message: MessageType,
  isFetching: bool,
};

CompatibilitiesComponent.defaultProps = {
  id: null,
  className: null,
  success: null,
  inputs: [],
  message: null,
  isFetching: false,
};

export default CompatibilitiesComponent;
