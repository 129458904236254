import {
  FETCH_QUOTATION_START,
  FETCH_QUOTATION_COMPLETE,
  FETCH_QUOTATION_ERROR,
  FETCH_QUOTATION_PRICE,
} from '../../../../actions/vip/vis/types';
import { messagesErrorScreen } from '../../../../lib/get-message-error';

const fetchQuotationStart = state => ({
  ...state,
  quotation_modal: {},
});

const fetchQuotationComplete = (state, action) => {
  if (state.quotation && state.quotation.form) {
    return {
      ...state,
      quotation: {
        ...state.quotation,
        ...action.payload,
        form: state.quotation.form,
      },
    };
  }

  return {
    ...state,
    quotation: {
      ...state.quotation,
      ...action.payload,
    },
  };
};

const fetchQuotationPrice = (state, action) => ({
  ...state,
  quotation: {
    ...action.payload,
  },
});

const fetchQuotationError = state => {
  const { title, description } = messagesErrorScreen(state.siteId);
  const errorText = `${title} - ${description}`;
  return {
    ...state,
    quotation: {},
    quotation_modal: {
      message: {
        text: errorText,
        type: 'error',
      },
    },
  };
};

const fetchReQuotationMapper = {
  [FETCH_QUOTATION_START]: fetchQuotationStart,
  [FETCH_QUOTATION_ERROR]: fetchQuotationError,
  [FETCH_QUOTATION_PRICE]: fetchQuotationPrice,
  [FETCH_QUOTATION_COMPLETE]: fetchQuotationComplete,
};

export { fetchQuotationStart, fetchQuotationError };
export { fetchQuotationComplete, fetchQuotationPrice };
export default fetchReQuotationMapper;
