import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import classNames from 'classnames';

const RelatedDesktop = ({ label, items, namespace }) => {
  const listItems = items.map(item => (
    <li className={`${namespace}__item`} key={item.id}>
      <a className={`${namespace}__link`} href={item.href}>
        {item.text}
      </a>
    </li>
  ));

  return (
    <ul className={`${namespace}__list`}>
      <li className={classNames(`${namespace}__item`, `${namespace}__item-title`)}>{label}</li>
      {listItems}
    </ul>
  );
};

RelatedDesktop.propTypes = {
  namespace: string,
  items: arrayOf(
    shape({
      href: string.isRequired,
      id: string.isRequired,
      text: string.isRequired,
    }),
  ).isRequired,
  label: string.isRequired,
};

RelatedDesktop.defaultProps = {
  namespace: null,
  className: null,
};

export default RelatedDesktop;
