import 'regenerator-runtime/runtime';

/* eslint-env browser */

import React from 'react';

import { hydrate } from 'nordic/hydrate';
import { loadableReady } from 'nordic/lazy';
import I18n from 'nordic/i18n';
import withProviders from '../components/with-providers';

export default function bootstrap(Component) {
  const preloadedState = window.__PRELOADED_STATE__;

  const i18n = new I18n({
    translations: preloadedState.translations,
  });

  const WithProvidersComponent = withProviders(props => <Component {...props} />, preloadedState.imagePrefix, i18n);

  loadableReady(() => {
    hydrate(<WithProvidersComponent {...preloadedState} />, document.getElementById('root-app'));
  });

  window.newrelic?.setErrorHandler(err => {
    const ignoreStatusMessages = ['Script error.'];
    let response = false;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < ignoreStatusMessages.length; i++) {
      const element = ignoreStatusMessages[i];
      if (err.message === element) {
        response = true;
      }
    }

    return response;
  });
}
