import React, { useState } from 'react';
import { loadable } from 'nordic/lazy';
import { arrayOf, bool, shape, string, number, func } from 'prop-types';
import { Table, TableBody, TableRow, TableData, TableHeader } from '@andes/table';
import { Tabs, Tab, TabContent } from '@andes/tabs';
import classNames from 'classnames';
import { trackEvent } from '../../lib/tracking';
import SpecOther from './spec-other';
import SpecOtherCollapsable from './spec-other-collapsable';
import SpecGroupsCollapsable from './spec-groups-collapsable';
import useTrackView from '../../hooks/use-track-view';
import StyledLabel from '../styled-label';
import componentEnhance from '../../lib/component-enhance';

const CompatsWidget = loadable(({ show }) => (show ? import('../compats-widget/block') : null));

const namespace = 'ui-pdp-specs';
const STRIPED_TYPE = 'STRIPED';
const TAB_TYPE = 'TAB_LIST';
const MAX_ROWS_SPLIT = 2;
const MAX_ROWS = 6;

const SpecRowGroups = ({ action, spec, isGroupCollapsed, restTechGroups, onClick }) => {
  if (!spec) {
    return null;
  }

  if (action) {
    return (
      <SpecGroupsCollapsable
        className={namespace}
        title={spec.title}
        attributes={spec.attributes}
        action={action}
        isCollapsed={isGroupCollapsed}
        restGroups={restTechGroups}
        onClick={onClick}
      />
    );
  }

  return (
    <div className={`${namespace}__rest`}>
      <SpecOther className={namespace} title={spec.title} attributes={spec.attributes} key={spec.title} />
      {restTechGroups.map(otherSpec => (
        <SpecOther
          className={`${namespace}-groups`}
          title={otherSpec.title}
          attributes={otherSpec.attributes}
          key={otherSpec.title}
        />
      ))}
    </div>
  );
};

const SpecRowsGroupsByDefault = ({ action, specs, showSingleTechSpec, otherTechSpecs }) => {
  if (!specs) {
    return null;
  }

  if (showSingleTechSpec || !otherTechSpecs || otherTechSpecs.length <= 0) {
    return null;
  }

  return otherTechSpecs.map(spec => {
    const actualRows = spec && spec.attributes ? spec.attributes.length / MAX_ROWS_SPLIT : 1;
    const shouldShowTitle = specs.length > 1;

    if (MAX_ROWS < actualRows && action) {
      return (
        <SpecOtherCollapsable
          className={namespace}
          title={spec.title}
          attributes={spec.attributes}
          action={action}
          key={spec.title}
          showTitle={shouldShowTitle}
        />
      );
    }

    return (
      <SpecOther
        className={namespace}
        title={spec.title}
        attributes={spec.attributes}
        key={spec.title}
        showTitle={shouldShowTitle}
      />
    );
  });
};

SpecRowsGroupsByDefault.propTypes = {
  showSingleTechSpec: bool,
  action: shape({
    label: shape({
      text: string.isRequired,
      color: string,
    }).isRequired,
    target: string,
  }).isRequired,
  otherTechSpecs: arrayOf(
    shape({
      title: string.isRequired,
      attributes: arrayOf(
        shape({
          id: string,
          text: string.isRequired,
        }),
      ),
    }),
  ),
  specs: arrayOf(
    shape({
      title: string.isRequired,
      attributes: arrayOf(
        shape({
          id: string,
          text: string.isRequired,
        }),
      ),
    }),
  ),
};

const Specs = ({
  action,
  viewport_track,
  components,
  className,
  specs,
  showSingleSpec,
  showFirstTitle,
  max_groups,
  onSubmitForm,
  onRemove,
}) => {
  const mainSpecs = specs && specs.find(spec => spec.type === STRIPED_TYPE);
  const otherSpecs = specs && specs.filter(spec => spec.type !== STRIPED_TYPE && spec.type !== TAB_TYPE);
  const tabsSpecs = specs && specs.filter(spec => spec.type === TAB_TYPE);
  let mainGroupSpec = null;
  let restSpecs = null;
  if (specs) {
    [mainGroupSpec, ...restSpecs] = otherSpecs;
  }
  const restGroups = restSpecs;
  const [isCollapsed, setIsCollapsed] = useState(true);
  const hasCompats = components && components.find(component => component.type === 'compats_widget_block');

  const viewRef = useTrackView(viewport_track, { threshold: 0.45 });

  const onClick = e => {
    e.preventDefault();
    if (action && action.track) {
      trackEvent(action.track);
    }
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div ref={viewRef} className={classNames(namespace, className)}>
      {showFirstTitle && specs && specs.length > 0 && <h2 className={`${namespace}__title`}>{specs[0].title}</h2>}
      {hasCompats && (
        <CompatsWidget
          show={hasCompats}
          components={components}
          onSubmitForm={onSubmitForm}
          onRemove={onRemove}
          namespace={namespace}
          modifier="dark"
        />
      )}
      {mainSpecs && (
        <div className={`${namespace}__table`}>
          <Table>
            <TableBody>
              {mainSpecs.attributes.map(spec => (
                <TableRow selected={false} key={spec.id}>
                  <TableHeader
                    className={classNames(`${namespace}__table__column`, `${namespace}__table__column-title`)}
                    scope="row"
                  >
                    {spec.id}
                  </TableHeader>
                  <TableData className={`${namespace}__table__column`}>{spec.text}</TableData>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
      {max_groups ? (
        <SpecRowGroups
          action={action}
          spec={mainGroupSpec}
          isGroupCollapsed={isCollapsed}
          restTechGroups={restGroups}
          onClick={onClick}
        />
      ) : (
        <SpecRowsGroupsByDefault
          action={action}
          specs={specs}
          showSingleTechSpec={showSingleSpec}
          otherTechSpecs={otherSpecs}
        />
      )}
      {tabsSpecs?.length > 0 ? (
        <div className={`${namespace}__tabs`}>
          <Tabs active={1}>
            {tabsSpecs.map(spec => (
              <Tab key={spec.title} title={spec.title}>
                <TabContent id={`tab-content-id-${spec.title.toLowerCase().replace(/\s/g, '-')}`}>
                  {spec.attributes &&
                    spec.attributes.map((attr, index) => (
                      <div
                        key={
                          attr.values && attr.values.value_text
                            ? `${attr.values.value_text.text}-${index}`
                            : `${attr.text}-${index}`
                        }
                        className={`${namespace}__tab-spec`}
                      >
                        <StyledLabel
                          {...attr}
                          className={`${namespace}__specs-list`}
                          text={componentEnhance.jsx(attr.text, attr.values)}
                        />
                      </div>
                    ))}
                </TabContent>
              </Tab>
            ))}
          </Tabs>
        </div>
      ) : null}
    </div>
  );
};

Specs.propTypes = {
  className: string,
  showFirstTitle: bool,
  showSingleSpec: bool,
  max_groups: number,
  components: arrayOf(shape({})),
  action: shape({ target: string, track: shape({}) }),
  specs: arrayOf(
    shape({
      title: string.isRequired,
      attributes: arrayOf(
        shape({
          id: string,
          text: string.isRequired,
          values: shape({
            value_text: shape({
              text: string,
            }),
          }),
        }),
      ),
    }),
  ).isRequired,
  onSubmitForm: func,
  onRemove: func,
  viewport_track: shape({}),
};

Specs.defaultProps = {
  className: '',
  components: null,
  showFirstTitle: true,
  showSingleSpec: false,
  action: null,
  max_groups: 0,
  onSubmitForm: null,
  onRemove: null,
  viewport_track: null,
};

SpecRowGroups.propTypes = {
  spec: shape({
    title: string.isRequired,
    attributes: arrayOf(
      shape({
        id: string,
        text: string.isRequired,
      }),
    ),
  }),
  action: shape({ target: string, track: shape({}) }),
  isGroupCollapsed: bool.isRequired,
  restTechGroups: arrayOf(shape({})),
  onClick: func.isRequired,
};

SpecRowGroups.defaultProps = {
  spec: null,
  restTechGroups: null,
  action: null,
};

export default Specs;
