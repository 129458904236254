import {
  UPDATE_QUOTATION_FORM,
  UPDATE_QUOTATION_CURRENT_STATE,
  QUOTATION_ERROR_EXCEEDS_LIMIT,
} from '../../../../actions/vip/vis/types';

const updateQuotationForm = (state, action) => ({
  ...state,
  quotation: {
    ...state.quotation,
    form: {
      ...state.quotation.form,
      form: {
        ...state.quotation.form.form,
        ...action.payload,
      },
    },
  },
});

const updateQuotationCurrentState = (state, action) => ({
  ...state,
  quotation: {
    ...state.quotation,
    currentState: action.payload,
  },
});

const quotationErrorExceedsLimit = (state, action) => ({
  ...state,
  quotation: {
    ...state.quotation,
    isPostingQuotation: false,
    exceedQuotationLimit: true,
  },
  quotation_modal: {
    message: {
      text: action.payload.message,
      type: 'error',
    },
  },
});

const reQuotationMapper = {
  [UPDATE_QUOTATION_FORM]: updateQuotationForm,
  [UPDATE_QUOTATION_CURRENT_STATE]: updateQuotationCurrentState,
  [QUOTATION_ERROR_EXCEEDS_LIMIT]: quotationErrorExceedsLimit,
};

export { updateQuotationForm, updateQuotationCurrentState, quotationErrorExceedsLimit };
export default reQuotationMapper;
