import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import Denounce from '../../../../components/denounce';

const FEATURE_DENOUNCE = 'denounce';
const FEATURE_CODE_INTERNAL = 'code_internal';

const mapStateToProps = ({ components: { [FEATURE_DENOUNCE]: denounce, [FEATURE_CODE_INTERNAL]: code_internal } }) => ({
  ...denounce,
  ...code_internal,
});

const enhance = compose(
  withFeatureFlag(FEATURE_DENOUNCE),
  withRow(FEATURE_DENOUNCE),
  connect(mapStateToProps),
);

export default enhance(Denounce);
