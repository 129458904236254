import { SCROLL_TO_COMPONENT, FETCH_SELLER_INFO_PHONES } from '../../../../actions/vip/vis/types';

const scrollToComponent = (state, action) => {
  const { component, focus } = action.payload;
  return { ...state, [component]: { ...state[component], focus } };
};

const fetchBudgetComponent = (state, action) => {
  const id = 'service_flash_message';
  const type = 'service_flash_message';
  return {
    ...state,
    components: {
      ...state.components,
      new_budget_message: { ...action.payload.components.new_budget_message },
      budget_sent_message: { ...action.payload.components.budget_sent_message },
      service_flash_message: {
        id,
        type,
        ...action.payload.service_flash_message,
      },
      budget_demand: { ...action.payload.components.budget_demand },
    },
    grouped_main_actions: { ...action.payload.components.main_actions },
    service_flash_message: {
      id,
      type,
      ...action.payload.service_flash_message,
    },
  };
};

const quoteModal = {
  [SCROLL_TO_COMPONENT]: scrollToComponent,
  [FETCH_SELLER_INFO_PHONES]: fetchBudgetComponent,
};

export { scrollToComponent, fetchBudgetComponent };
export default quoteModal;
