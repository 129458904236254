import transformActionTarget from '../../../../utils/transformActionTarget';

const jsonToProps = ({ ...props }) => ({
  action: transformActionTarget(props.action, { controlled: 'true' }),
  shouldShowSpinner: true,
  blackPriceClassName: 'ui-pdp-generic-summary--black-price',
  ...props,
});

export default jsonToProps;
