import React from 'react';
import CartActions from './cart-actions';

const namespace = 'ui-pdp-cart-actions-container';

const CartActionsContainer = props => (
  <div className={namespace}>
    <CartActions {...props} />
  </div>
);

export default CartActionsContainer;
