import { getCookie } from '../../../lib/cookies';

const getPrimaryDomain = domain =>
  domain.split('.').reduce((prev, curr) => {
    if (prev && prev !== '') {
      return `${prev}.${curr}`;
    }

    if (curr?.includes('mercadoli')) {
      return curr;
    }

    return '';
  }, '') || domain;

const cleanCookie = name => {
  if (getCookie(name)) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${getPrimaryDomain(
      window.location.hostname,
    )}; path=/; Secure; SameSite=None`;
  }
};

export { getPrimaryDomain, cleanCookie };
