import DEFAULT_CONFIG from './default-config';
import headerConfig from '../features/header-configuration';
import priceConfig from '../features/price-configuration';
import galleryConfig from '../features/gallery-configuration';

const boundaryConfigMap = {
  header: headerConfig,
  price: priceConfig,
  gallery: galleryConfig,
  [`fixed.gallery`]: galleryConfig,
  default: DEFAULT_CONFIG,
};

export default boundaryConfigMap;
