import isEmpty from 'lodash/isEmpty';
import { isSupportedByDefault } from '../../lib/local-storage';

const localStorageKey = 'unregistered-contact-form';

const formFieldsMap = {
  name: 'contactUserFirstName',
  last_name: 'contactUserLastName',
  phone: 'contactUserPhoneNumber',
  email: 'contactUserEmail',
  question: 'questionTextInput',
};

const loadFormData = () => {
  const formDataString = localStorage.getItem(localStorageKey);
  if (isEmpty(formDataString)) {
    return {};
  }
  let formDataObject;
  try {
    formDataObject = JSON.parse(formDataString);
  } catch (e) {
    formDataObject = {};
  }
  return formDataObject;
};

const saveFormByProp = (key, value) => {
  const storageKey = formFieldsMap[`${key}`];
  if (storageKey) {
    const formDataObject = loadFormData();
    formDataObject[`${storageKey}`] = value;
    localStorage.setItem(localStorageKey, JSON.stringify(formDataObject));
  }
};

const getFormByProp = key => {
  const formDataObject = loadFormData();
  if (isEmpty(formDataObject)) {
    return null;
  }
  return formDataObject[`${formFieldsMap[`${key}`]}`];
};

const saveForm = ({ input }) => {
  const { name, last_name, phone, email, question } = input;
  const isLocalStorageSupported = isSupportedByDefault();
  if (isLocalStorageSupported) {
    saveFormByProp('name', name ? name.value || '' : '');
    saveFormByProp('last_name', last_name ? last_name.value || '' : '');
    saveFormByProp('email', email ? email.value || '' : '');
    saveFormByProp('phone', phone ? phone.value || '' : '');
    saveFormByProp('question', question ? question.value || '' : '');
  }
};

const getForm = (input, value) => {
  const isLocalStorageSupported = isSupportedByDefault();
  if (isLocalStorageSupported) {
    const resultLocalStorage = getFormByProp(input);
    return isEmpty(resultLocalStorage) || resultLocalStorage === 'null' ? value : resultLocalStorage;
  }
  return value;
};

const hasDataStore = input => {
  const isLocalStorageSupported = isSupportedByDefault();
  if (isLocalStorageSupported) {
    const nameLocalStored = getFormByProp(input.nameField);
    const lastNameLocalStored = getForm(input.lastNameField);
    const emailLocalStored = getForm(input.emailField);
    return !!(nameLocalStored && lastNameLocalStored && emailLocalStored);
  }
  return false;
};

export { saveForm, getForm, hasDataStore };
