import React from 'react';
import { number } from 'prop-types';

const Thermometer = ({ rank }) => (
  <ul aria-hidden="true" className="ui-thermometer" value={rank}>
    <li className="ui-thermometer__level ui-thermometer__level--1" />
    <li className="ui-thermometer__level ui-thermometer__level--2" />
    <li className="ui-thermometer__level ui-thermometer__level--3" />
    <li className="ui-thermometer__level ui-thermometer__level--4" />
    <li className="ui-thermometer__level ui-thermometer__level--5" />
  </ul>
);

Thermometer.propTypes = {
  rank: number.isRequired,
};

export default Thermometer;
