import { HIDE_SNACKBAR, SHOW_SNACKBAR } from '../../../../actions/vip/types';

const showSnackbar = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    snackbar: {
      state: 'VISIBLE',
      message: action.params.message,
      type: action.params.type,
      delay: action.params.delay,
      called_from: action.params.called_from,
      show: true,
      action: action.params.action,
      className: action.params.className,
    },
  },
});

const hideSnackbar = state => ({
  ...state,
  components: {
    ...state.components,
    snackbar: {
      called_from: state.components?.snackbar?.called_from,
      state: 'HIDDEN',
    },
  },
});

const snackbarMapper = {
  [HIDE_SNACKBAR]: hideSnackbar,
  [SHOW_SNACKBAR]: showSnackbar,
};

export { showSnackbar, hideSnackbar };
export default snackbarMapper;
