import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import StickyWrapper from '../../../../components/sticky-wrapper';

const mapStateToProps = ({ dynamic_scroll: dynamicScroll }, ownProps) => ({
  dynamicScroll,
  ...ownProps,
});

const enhance = compose(connect(mapStateToProps));

export default enhance(StickyWrapper);
