import React from 'react';
import { MoneyAmount } from '@andes/money-amount';
import { string, number } from 'prop-types';

const getFormattedIntFraction = price => {
  const rawFraction = Math.floor(price).toString();
  return rawFraction.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, n => `${n}${'.'}`);
};

const PriceFallback = ({ value, currencySymbol }) => (
  <MoneyAmount
    className="ui-pdp-price ui-pdp-price--fallback-boundary mt-16"
    value={{
      fraction: getFormattedIntFraction(value),
    }}
    symbol={currencySymbol}
    compact
  />
);

PriceFallback.propTypes = {
  value: number.isRequired,
  currencySymbol: string.isRequired,
};

export default PriceFallback;
