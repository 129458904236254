import { stringIncludes } from '../../lib/includes';

const isShippingCalculator = target =>
  stringIncludes(target, 'new_version=true') && stringIncludes(target, 'gz/shipping-calculator');

const getShippingCalculatorProps = target => {
  if (!isShippingCalculator(target)) {
    return null;
  }
  return {
    controlModalHeader: true,
    showCloseButton: true,
  };
};

export { isShippingCalculator, getShippingCalculatorProps };
