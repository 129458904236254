import React from 'react';
import classnames from 'classnames';
import { func, number, shape, string } from 'prop-types';
import Image from '../../../image';
import { PROVIDER } from '../../../figure/constants';

const Picture = ({ item, namespace, index, galleryType, maxHeight, minHeight, onClick, id }) => {
  const { width, height, thumbnail, zoom, src, a11yLabel, ...restImageProps } = item;
  const imageClassnames = classnames({
    [`${namespace}--horizontal`]: width > height,
    [`${namespace}--vertical`]: height > width,
  });
  const preload = index === 0;
  return (
    <a
      data-testid={`gallery-picture-${index}`}
      key={id}
      href={src}
      onClick={onClick}
      className={`${PROVIDER.IMAGE} ${namespace}--${galleryType}`}
      style={{ maxHeight, minHeight }}
      aria-label={a11yLabel}
    >
      <Image
        alt=""
        id={id}
        src={src}
        lazyload={!preload}
        preload={preload}
        width={500}
        height={500}
        className={imageClassnames}
        fetchPriority={preload ? 'high' : null}
        {...restImageProps}
      />
    </a>
  );
};

Picture.propTypes = {
  item: shape({}).isRequired,
  namespace: string.isRequired,
  index: number.isRequired,
  galleryType: string.isRequired,
  maxHeight: string,
  minHeight: string,
  onClick: func.isRequired,
  id: string.isRequired,
};

export default Picture;
