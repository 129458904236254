import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import jsonToProps from '../../../../components/seller-info-label/json-to-props';
import * as actions from '../../../../actions/vip';

const FEATURE_NAME = 'seller';

const mapStateToProps = ({ components: { [FEATURE_NAME]: seller } }) => jsonToProps(seller);
const mapDispatchToProps = dispatch => ({
  onSubmitForm: (selectedInput, updateState, deviceType) =>
    new Promise(resolve => {
      resolve(
        dispatch(actions.getCompatsProduct({ selectedInput, updateState, feature_name: FEATURE_NAME, deviceType })),
      );
    }),
  onRemove: (deviceType, updateState) =>
    new Promise(resolve => {
      resolve(dispatch(actions.cleanFormCompats({ feature_name: FEATURE_NAME, deviceType, updateState })));
    }),
});
const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const seller = Seller => enhance(Seller);

export default seller;
