import { trackEvent } from '../../../lib/tracking';
import { GALLERY_TABS } from '../constants';
import { MULTIMEDIA_PROVIDERS } from '../../figure/constants';

const useGalleryHandlers = ({
  clipsRef,
  deviceType,
  clipsConfig,
  track,
  setIsFullscreen,
  setModalVisibility,
  setHeaderUnclickeable,
  setActiveModalTab,
  clipHandlerAsync,
}) => {
  const handleBeforeOpen = () => {
    setIsFullscreen(true);
  };

  const handleLightboxOnClose = () => {
    if (deviceType === 'mobile') {
      setTimeout(() => setHeaderUnclickeable(false), 200);
    }

    setIsFullscreen(false);
    setModalVisibility(false);
  };

  const handleFullScreen = () => {
    setModalVisibility(true);
  };

  const handleClip = () => {
    if (clipsRef.current) {
      clipsRef.current.clipHandler();
    }
  };

  const handleTap = (type = 'picture', event = null) => {
    setActiveModalTab(type);
    setModalVisibility(true);
    if (event) {
      trackEvent(event);
    }
  };

  const handleLightboxOnTouch = (...params) => clipsRef.current && clipsRef.current?.handleTouch(...params);

  const handleTapFigure = async (figure, e) => {
    e.preventDefault();
    const { provider, button } = figure;

    if (clipsConfig?.hasClips) {
      await clipHandlerAsync(provider);
    }
    if (MULTIMEDIA_PROVIDERS.includes(provider)) {
      handleTap(GALLERY_TABS.MULTIMEDIA, button?.track);
    } else {
      setHeaderUnclickeable(true);
      handleTap(GALLERY_TABS.PICTURE, track);
    }
  };

  return {
    handleBeforeOpen,
    handleLightboxOnClose,
    handleFullScreen,
    handleClip,
    handleLightboxOnTouch,
    handleTapFigure,
  };
};

export default useGalleryHandlers;
