import DEFAULT_CONFIG from '../config/default-config';
import headerMapper from '../mappers/header-mapper';

const headerConfig = {
  ...DEFAULT_CONFIG,
  isCritical: false,
  mapComponentInfoToProps: headerMapper,
};

export default headerConfig;
