import React from 'react';
import { bool, string } from 'prop-types';
import { Snackbar } from '@andes/snackbar';

const SnackBarError = ({ key, message, show }) => (
  <Snackbar show={show} message={message} delay={20000} color="red" key={key} />
);

SnackBarError.propTypes = {
  key: string,
  message: string.isRequired,
  show: bool,
};

SnackBarError.defaultProps = {
  key: null,
  show: false,
};

export default React.memo(SnackBarError);
