/* eslint-disable react/destructuring-assignment */
/* eslint-disable consistent-return */
import React, { useState } from 'react';
import { string, shape, bool } from 'prop-types';
import Snackbar from '@andes/snackbar';
import usePostClose from '../reviews/hooks/use-post-close';
import { forEach } from '../../lib/dom-utils';
import APIService from '../../services/api';
import useTrackView from '../../hooks/use-track-view';
import IFrameReviews from '../iframe-reviews';
import melidata from '../../lib/melidata';

const { trackEvent } = melidata;

const ReviesCapability = (props, WrappedComponent, mobile) => {
  const {
    track,
    title,
    see_more: { url: urlSeeMore },
  } = props;

  const refView = useTrackView({ melidata_event: track }, { threshold: 0.01 });
  const [iFrameVisible, setIFrameVisible] = usePostClose(false);

  const [snackbarShow, setSnackbarShow] = useState({
    visible: false,
    message: null,
    textAction: null,
  });

  const [iframeData, setIframeData] = useState({ url: urlSeeMore, title: title.text });

  const SNACKBAR_DELAY = 3000;

  const { visible } = snackbarShow;

  const openSheetReviews = (iframeUrl, titleText) => {
    if (mobile) {
      window.location.href = iframeUrl;
    } else {
      setIframeData({ url: iframeUrl, title: titleText });
      setIFrameVisible(true);

      const main = document.querySelectorAll('body > header, body > main') || [];

      forEach(main, element => {
        element.classList.add('with-scroll-bars-hidden');
      });
    }
  };

  const likeDislikeHandler = serviceCall => async (id, tracks, selected) => {
    if (!props.islogged) {
      const { text, action } = props.snackbar;
      setSnackbarShow({
        visible: true,
        message: text,
        textAction: action.text,
      });
    } else {
      const response = await serviceCall(id);
      const { snackbar, likes, dislikes } = response.data;
      const succeeded = !!likes || !!dislikes;
      if (selected && succeeded) {
        trackEvent(tracks);
      }
      if (snackbar) {
        setSnackbarShow({
          visible: true,
          message: snackbar.text,
          textAction: null,
        });
      }
      return response.data;
    }
  };

  const onCloseHandler = e => {
    if (e) {
      e.preventDefault();
    }

    setIFrameVisible(false);

    props.filterOptionsVpp = {
      orderCriteria: null,
      rating: null,
    };

    // Se calculan en base al react-remove-scroll-bar y cumplen la función de suplantar el espacio
    // dejado por la scrollbar en los casos que se utilice mouse
    const main = document.querySelectorAll('body > header, body > main') || [];

    forEach(main, element => {
      element.classList.remove('with-scroll-bars-hidden');
    });
  };

  const onSeeMoreClick = (url, tracks) => {
    trackEvent(tracks);
    openSheetReviews(url, title.text);
  };

  const onDenounceClick = (url, tracks) => {
    window.location.href = url;
    trackEvent(tracks);
  };

  const handleLogin = async () => {
    const { id } = props;
    await APIService.redirectToLogin(id, 'reviews', 'reviews');
  };

  const handleFilter = (filters, tracks) => {
    if (filters?.orderCriteria || (filters?.rating || filters?.rating === null)) {
      const { orderCriteria, rating } = filters;
      const urltoFilter = `${urlSeeMore}&orderCriteria=${orderCriteria}&rating=${rating}`;
      trackEvent(tracks);
      openSheetReviews(urltoFilter, title.text);
    }
  };
  const onClickCarousel = (path, trackCarousel) => {
    trackEvent(trackCarousel);
    if (props.modal_photos) {
      const { title: ModalTitle } = props?.modal_photos;
      const urlPhotoNavigation = `${path}&return=false`;
      openSheetReviews(urlPhotoNavigation, ModalTitle);
    }
  };

  const handleChangeSnackBar = () => {
    setSnackbarShow({
      visible: false,
      message: null,
      textAction: null,
    });
  };

  return (
    <div className="ui-review-capability-vpp" ref={refView}>
      {visible && (
        <Snackbar
          message={snackbarShow.message}
          show={visible}
          delay={SNACKBAR_DELAY}
          {...(snackbarShow.textAction ? { onActionClick: handleLogin } : {})}
          {...(snackbarShow.textAction ? { actionMessage: snackbarShow.textAction } : {})}
          onChange={handleChangeSnackBar}
        />
      )}
      <WrappedComponent
        embedded
        vpp
        {...props}
        onLikeComment={likeDislikeHandler(APIService.likeReviewsCapability)}
        onDislikeComment={likeDislikeHandler(APIService.dislikeReviewsCapability)}
        onLikeSummary={likeDislikeHandler(APIService.likeReviewsSummaryCapability)}
        onDislikeSummary={likeDislikeHandler(APIService.dislikeReviewsSummaryCapability)}
        onSeeMoreClick={onSeeMoreClick}
        onDenounceClick={onDenounceClick}
        onLoginComment={handleLogin}
        onFilter={handleFilter}
        onClickCarouselImage={onClickCarousel}
      />
      <IFrameReviews
        visible={iFrameVisible}
        action={{
          target: iframeData.url,
        }}
        title={iframeData.title}
        hashId="reviews"
        onClose={onCloseHandler}
        reviewsIframeId={props.id}
      />
    </div>
  );
};

ReviesCapability.propTypes = {
  id: string.isRequired,
  is_item: bool.isRequired,
  islogged: bool,
  title: shape().isRequired,
  see_more: shape().isRequired,
  track: shape().isRequired,
  actions: shape().isRequired,
  snackbar: shape().isRequired,
  modal_photos: shape(),
};

ReviesCapability.defaultProps = {
  modal_photos: null,
};

export default ReviesCapability;
