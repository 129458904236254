import { idToUrl } from '../../../lib/id-to-url';
import { arrayIncludes } from '../../../lib/includes';
import { PROVIDER } from '../../figure/constants';

const galleryTypes = ['square', 'horizontal', 'vertical'];
const MULTIMEDIA_CONFIG = {
  alt: 'Multimedia',
  title: 'Multimedia',
  allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
  frameBorder: 0,
  showSpinner: false,
};

const isEmpty = obj => !obj || Object.keys(obj)?.length === 0;

const getFormattedPictures = ({ pictures, titleForAlt, pictureConfig }) =>
  pictures &&
  pictures.map(picture => {
    if (picture.url) {
      return {
        src: picture.url.src,
        src2x: picture.url.src2x,
        width: picture.width || 500,
        height: picture.height || 500,
        provider: PROVIDER.IMAGE,
        alt: titleForAlt,
        a11yLabel: picture.alt,
      };
    }

    const {
      template,
      template_2x: template2x,
      template_zoom: templateZoom,
      template_zoom_2x: templateZoom2x,
      template_thumbnail: templateThumbnail,
      template_thumbnail_2x: templateThumbnail2x,
    } = pictureConfig || {};

    return {
      ...picture,
      src: idToUrl(picture.id, template, picture.sanitized_title),
      src2x: template2x ? idToUrl(picture.id, template2x, picture.sanitized_title) : null,
      thumbnail: {
        src: templateThumbnail ? idToUrl(picture.id, templateThumbnail, picture.sanitized_title) : null,
        src2x: templateThumbnail2x ? idToUrl(picture.id, templateThumbnail2x, picture.sanitized_title) : null,
      },
      zoom: {
        src: templateZoom ? idToUrl(picture.id, templateZoom, picture.sanitized_title) : null,
        src2x: templateZoom2x ? idToUrl(picture.id, templateZoom2x, picture.sanitized_title) : null,
      },
      width: picture.width || 500,
      height: picture.height || 500,
      provider: PROVIDER.IMAGE,
      alt: titleForAlt,
      a11yLabel: picture.alt,
    };
  });
const getFormattedBookmarks = ({ bookmark, id, csrfToken, wishlist }) =>
  bookmark && bookmark?.state !== 'HIDDEN'
    ? {
        isBookmarked: bookmark.is_bookmarked,
        href: `${id}/bookmark?method=${bookmark.isBookmarked ? 'remove' : 'add'}&item_id=${id}`,
        remove: bookmark.delete,
        add: bookmark.add,
        isFetching: bookmark.isFetching,
        themeMode: 'circle',
        itemId: bookmark.item_id,
        csrfToken,
        wishlist: wishlist && {
          label: wishlist?.action?.label?.text,
          title: wishlist?.title,
          target: wishlist?.targetDynamic || wishlist?.action?.target,
          loading_text: wishlist?.action?.loading_text,
          show: wishlist?.show,
          wishlistVariantVisible: wishlist?.state === 'VISIBLE',
          gift_registry: wishlist?.gift_registry || null,
          track: wishlist?.track,
        },
      }
    : null;

const getFormattedShare = ({ share, show_share }) =>
  share && share.state !== 'HIDDEN' && share.action && show_share
    ? {
        action: share.action,
        permalink: share.permalink,
        title: share.share_title,
        shareActions: share.share_actions,
      }
    : null;

const getGalleryType = ({ gallery_type, pictures }) => {
  const firstGalleryPicture = pictures?.[0] || null;
  if (gallery_type && arrayIncludes(galleryTypes, gallery_type)) {
    return gallery_type;
  }
  if (firstGalleryPicture) {
    if (firstGalleryPicture.width === firstGalleryPicture.height) {
      return 'square';
    }
    if (firstGalleryPicture.width > firstGalleryPicture.height) {
      return 'horizontal';
    }
  }
  return null;
};
const getMultimedia = highlighted_multimedia =>
  highlighted_multimedia
    ? [
        {
          ...highlighted_multimedia,
          thumbnail: highlighted_multimedia.thumbnail.url.src,
          src: highlighted_multimedia.thumbnail.url.src,
          alt: 'Tour Virtual',
          icon: highlighted_multimedia.button.content.icon,
          showTopInGallery: highlighted_multimedia.show_top_in_gallery ?? true,
          provider: highlighted_multimedia.provider?.toLowerCase?.(),
        },
      ]
    : [];
const getClipsConfig = clips => {
  if (isEmpty(clips)) {
    return {
      hasClips: false,
    };
  }
  const { autoplay, shorts, snackbar_own_error, snackbar_connection_error } = clips || {};
  const snackbars = [snackbar_own_error, snackbar_connection_error].map(snackbar => {
    const { type, message, delay } = snackbar || {};
    return { id: type, message, delay };
  });

  return {
    autoplay,
    hasClips: !!shorts.length,
    size: shorts.length,
    snackbars,
  };
};
const getFormattedClips = clips =>
  clips?.shorts &&
  clips.shorts.map(short => {
    const { thumbnail } = short;

    return {
      ...short,
      provider: PROVIDER.CLIP,
      thumbnail: thumbnail?.url?.src
        ? {
            src: thumbnail?.url?.src,
            src2x: thumbnail?.url?.src,
            alt: 'Gallery clip thumbnail',
          }
        : thumbnail,
    };
  });
const getFormattedVideos = videos => (videos || []).map(video => ({ ...video, provider: PROVIDER.YOUTUBE }));

const getFigures = ({ config, multimedia, pictures, clips, videos }) => {
  const { show_top_in_gallery = true } = config.multimedia || {};
  const figures = Array.from(pictures);

  // step1: inject clips figures
  if (clips?.length > 0) {
    clips.forEach(clip => {
      const { position } = clip;

      if (typeof clip.position === 'number') {
        figures.splice(position, 0, clip);
      } else {
        figures.push(clip);
      }
    });
  }

  // step2: inject videos figures
  if (videos.length > 0) {
    figures.push(...videos);
  }

  // step3: inject multimedia figures
  if (multimedia?.length > 0) {
    if (show_top_in_gallery) {
      figures.unshift(...multimedia);
    } else {
      figures.push(...multimedia);
    }
  }

  return figures;
};

export {
  getFormattedPictures,
  getFormattedBookmarks,
  getFormattedShare,
  getGalleryType,
  getMultimedia,
  getClipsConfig,
  getFormattedClips,
  getFormattedVideos,
  getFigures,
  MULTIMEDIA_CONFIG,
};
