import { Validation } from 'frontend-validation';
import { runSchedulerTask } from '@vpp-frontend-components/common';

const escapeProxy = typeof window !== 'undefined' && encodeURI ? encodeURI : escape;

const objectDecorator = result => ({
  isValid: result === null,
  ...result,
});

const snackbarColor = new Map();
// values to validate the las props with the new props
snackbarColor.set('error', 'red');
snackbarColor.set('warning', 'orange');
snackbarColor.set('success', 'green');
snackbarColor.set('neutral', 'accent');
snackbarColor.set('generic', 'gray');
// if the back return the color this values is to add the color to the map
snackbarColor.set('red', 'red');
snackbarColor.set('orange', 'orange');
snackbarColor.set('green', 'green');
snackbarColor.set('accent', 'accent');
snackbarColor.set('gray', 'gray');

/**
 * @description: this function validate the kind of messages and return the color
 * @param {string} type: type to validate
 * @returns the color to the messages
 */
const validateSnackbarColor = type => snackbarColor.get(type) || type;

/**
 * @description: this function generate the key to the component
 * @param {*} data: data to find the key
 * @returns the key to the component
 */
const generateKeyToComponent = data => {
  const title = data?.title?.values?.key?.value || data?.title?.values?.key?.text || data?.title?.text || data?.title;
  const returnValue =
    data?.key ||
    title ||
    data?.id ||
    data?.label?.text ||
    data?.text ||
    data?.subtitle ||
    data?.upper_label ||
    data?.description;
  return returnValue;
};

function validateItemOrProductId(value) {
  return objectDecorator(
    Validation.run(escapeProxy(value), {
      errorMessages: {
        ITEM_ID_REQUIRED: 'ITEM_ID_REQUIRED',
        ITEM_ID_BAD_FORMAT: 'ITEM_ID_BAD_FORMAT',
      },
      validators: [
        {
          name: 'required',
          value: true,
          errorMessage: 'ITEM_ID_REQUIRED',
        },
        {
          name: 'pattern',
          value: '([m|M][a-zA-Z]{2}[u|U]?)([-]?)([0-9]+)',
          errorMessage: 'ITEM_ID_BAD_FORMAT',
        },
      ],
    }),
  );
}

function validateNumber(value) {
  return objectDecorator(
    Validation.run(escapeProxy(value), {
      errorMessages: {
        NUMBER_BAD_FORMAT: 'NUMBER_BAD_FORMAT',
      },
      validators: [
        {
          name: 'pattern',
          value: '[0-9]+',
          errorMessage: 'NUMBER_BAD_FORMAT',
        },
      ],
    }),
  );
}

function validateString(value) {
  return objectDecorator(
    Validation.run(escapeProxy(value), {
      errorMessages: {
        STRING_BAD_FORMAT: 'STRING_BAD_FORMAT',
      },
      validators: [
        {
          name: 'pattern',
          value: '\\w+|\\|',
          errorMessage: 'STRING_BAD_FORMAT',
        },
      ],
    }),
  );
}

function validateBoolean(value) {
  return objectDecorator(
    Validation.run(escapeProxy(value), {
      errorMessages: {
        BAD_FORMAT: 'BOOLEAN_BAD_FORMAT',
      },
      validators: [
        {
          name: 'pattern',
          value: 'true|false',
          errorMessage: 'BOOLEAN_BAD_FORMAT',
        },
      ],
    }),
  );
}

export {
  validateItemOrProductId,
  validateNumber,
  validateString,
  validateBoolean,
  validateSnackbarColor,
  runSchedulerTask,
  generateKeyToComponent,
};
