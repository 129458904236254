import { useEffect, useState } from 'react';
import { shape, number, string } from 'prop-types';
import { trackPage } from '../../lib/tracking';

const TrackPageVariation = ({ melidata_event, analytics_event, variation_id, id }) => {
  const setId = variation_id || id;
  const [currentId, setCurrentId] = useState(() => setId);

  useEffect(() => {
    if (setId !== currentId) {
      setCurrentId(setId);
      trackPage({ melidata_event, analytics_event });
    }
  }, [melidata_event, analytics_event, currentId, setId]);
  return null;
};

TrackPageVariation.propTypes = {
  analytics_event: shape({}),
  melidata_event: shape({}),
  variation_id: number,
  id: string,
};

TrackPageVariation.defaultProps = {
  analytics_event: null,
  melidata_event: null,
};

export default TrackPageVariation;
