import React from 'react';
import { shape, string } from 'prop-types';
import classNames from 'classnames';
import QuestionsList from './questions-list';
import Iframe from '../iframe/iframe';
import onIframeModalResize from '../../lib/on-iframe-modal-resize';
import ActionModal from '../action-modal/action-modal';

const namespace = 'ui-pdp-questions__questions-others-question-modal';

const OthersQuestionsDesktop = ({ questionsList, title, show_more, closeModalLabel }) => (
  <div className={classNames(namespace, 'mt-24')}>
    <QuestionsList questionsList={questionsList} showTitle />
    {show_more && (
      <ActionModal
        modalClassName={classNames(`${namespace}__content`)}
        modalUrl="#questions-modal"
        url={show_more.target}
        label={show_more.label}
        modalTitle={title.text}
        closeModalLabel={closeModalLabel}
        controlModalHeader
      >
        <Iframe
          src={`${show_more.target}&modal=true`}
          title={null}
          width="100%"
          height="100%"
          scrolling="no"
          onMessage={onIframeModalResize('vip:modal-content:loaded', 'height', '.ui-pdp-iframe', null)}
        />
      </ActionModal>
    )}
  </div>
);

OthersQuestionsDesktop.propTypes = {
  title: shape({ text: string }).isRequired,
  questionsList: shape({}).isRequired,
  show_more: shape({
    label: shape({
      text: string,
      color: string,
    }),
    target: string,
  }),
  closeModalLabel: string.isRequired,
};

OthersQuestionsDesktop.defaultProps = {
  show_more: null,
};

export default OthersQuestionsDesktop;
