const idToUrl = (id, template, sanitizedTitle) => {
  if (template && typeof template === 'string') {
    return template.replace('{id}', id).replace('{sanitizedTitle}', sanitizedTitle ?? '');
  }
  return null;
};

const urlWithSanitizedTitle = (string, sanitizedTitle) => string.replace('{sanitizedTitle}', sanitizedTitle || '');

export { idToUrl, urlWithSanitizedTitle };
