import { bool, func, string } from 'prop-types';

export const namespace = 'ui-pdp-buybox__quantity';

export const quantityTriggerPropTypes = {
  active: bool.isRequired,
  description: string,
  showError: bool,
  bulkSale: bool,
  message: string,
  selectedLabel: string,
  title: string.isRequired,
  onClick: func,
  disabled: bool,
  hasCustomOptions: bool,
};

// QuantityChevron
export const quantityChevronPropTypes = {
  active: bool,
  showError: bool,
  disabled: bool,
};

// QuantitySelectedLabel
export const quantitySelectedLabelPropTypes = {
  selectedLabel: string.isRequired,
  description: string,
  showError: bool,
};

// QuantityTitle
export const quantityTitlePropTypes = {
  title: string.isRequired,
  showError: bool,
};
