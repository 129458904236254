import { canUseDOM } from '../lib/dom-utils';
import { LoggerFactory } from 'nordic/logger';
import {
  VPP_BOUNDARY_DATADOG_KEY_PREFIX,
  VPP_BOUNDARY_DATADOG_KEY_CASES,
} from '../services/frontend-statsd/config/allowed-keys';
import { feStatsdHelper } from '../utils/frontendStatsHelper';

const log = LoggerFactory('vpp_boundary_fe');

const CATCH_ACTION_FALLBACK = 'catch_action_fallback';
const CATCH_ACTION_CRASH = 'catch_action_crash';
const CATCH_ACTION_SKIP = 'catch_action_skip';

const catchErrorBoundary = ({ error, catchErrorBoundaryConfig = {} } = {}) => {
  const {
    app,
    deviceType,
    componentId,
    isCritical,
    fallbackProps,
    FallbackComponent,
    ownership,
  } = catchErrorBoundaryConfig;

  const hasFallback = fallbackProps && FallbackComponent;

  const getCatchAction = () => {
    if (hasFallback) {
      return CATCH_ACTION_FALLBACK;
    }
    if (isCritical) {
      return CATCH_ACTION_CRASH;
    }
    return CATCH_ACTION_SKIP;
  };

  const metricData = {
    referer_app: app,
    device_type: deviceType,
    catch_context: canUseDOM ? 'client_side' : 'server_side',
    component_id: componentId,
    is_critical: isCritical,
    catch_action: getCatchAction(),
    ownership,
  };

  if (canUseDOM) {
    feStatsdHelper({
      key: VPP_BOUNDARY_DATADOG_KEY_CASES.ERROR_BOUNDARY_TRY_CATCH,
      statsdConfig: {
        keyPrefix: VPP_BOUNDARY_DATADOG_KEY_PREFIX,
      },
      additionalTags: metricData,
      shouldLogMessage: true,
      additionalInfo: { ...metricData, error: error.stack },
      messageTitle: 'Vpp-ErrorBoundary - TryCatch',
    });
  } else {
    log.error(`Vpp-ErrorBoundary - TryCatch`, {
      ...metricData,
      error: error.stack,
    });
  }

  // Scenario #1 = Have the props + Have a fallback component
  // Action to do: Render a fallback
  if (hasFallback) {
    return <FallbackComponent {...fallbackProps} />;
  }

  // Scenario #2 = NOT have a fallback + Is a NON Critical component
  // Action to do: Render a empty fragment
  if (!isCritical) {
    return <></>;
  }

  // Scenario #3 = NOT have a fallback + Is a Critical component
  // Action to do: Throw an exception in order to...
  // SSR: Get catched by Error Middleware >> Crash and show an ErrorPage (and send metrics/logs)
  // CSR: Get catched by React Error Boundary >> Do nothing (only send metrics/logs)
  throw new Error(
    `Critical component crashes (without a fallback). Error on (${componentId}) component. Info: ${error}`,
  );
};

export default catchErrorBoundary;
