import getTools from './tools';
import { toI18n, toArrayModel } from './utils';

const getLabelUrl = originalUrl =>
  // TODO: En esta función se podria proporcionar un título acorde al link, ej. título del item.
  originalUrl;
/**
 * getRawData
 * ________________
 * Recibe un texto plano que luego transforma en un array de objetos, que facilita la operatoria de recortes, interpretación de links y highlights.
 * Cada objeto tiene el formato {type: 'text' | 'action' | 'highlighted', str: "string a visualizar (post-procesamiento)" y otros parámetros acordes al tipo.}
 *
 * Tambien retorna la cantidad de carácteres del texto a visualizar (post-procesamiento)
 */

const getRawData = ({ plainText, fragment, pattern, flag, actionConfig = {} }) => {
  const config = { ...actionConfig, fragment, regex: { pattern, flag }, getLabelUrl };
  const { arrayModel, charsAmount } = toArrayModel(plainText, config);

  return { arrayModel, charsAmount };
};

/**
 * getI18nValues
 * ________________
 * Procesa el texto (pasado previamente por getRawData) y le aplica las transformaciones necesarias (recorte y/o highlights).
 *
 * Retorna texto y valores requeridos por i18n.jsx para renderizar elementos.
 */

const getI18nValues = ({ maxChars, highlights, arrayModel }) => {
  const tools = getTools({ maxChars, highlights }).filter(({ validate }) => validate());
  const processedData = tools.reduce((acc, { transform }) => transform(acc), arrayModel);

  return toI18n(processedData);
};

export { getRawData, getI18nValues };
