import {
  SHOW_HI_QUANTITY_ERROR,
  HIDE_HI_QUANTITY_ERROR,
  FETCH_ON_SET_HI_QUANTITY_COMPLETE,
} from '../../../../actions/vip/types';

const showHiQuantityError = state => {
  if (!state.components.bulk_sale_quantity || state.components.bulk_sale_quantity.state === 'HIDDEN') {
    return { ...state };
  }
  const showNotSelectedError = !state.components.bulk_sale_quantity.selected;
  return {
    ...state,
    components: {
      ...state.components,
      bulk_sale_quantity: {
        ...state.components.bulk_sale_quantity,
        showNotSelectedError,
      },
    },
  };
};

const hideHiQuantityError = state => ({
  ...state,
  components: {
    ...state.components,
    bulk_sale_quantity: {
      ...state.components.bulk_sale_quantity,
      showNotSelectedError: false,
    },
  },
});

const fetchOnSetHiQuantityComplete = (state, action) => {
  const bulkSaleQuantity = action.payload.calculator?.id === 'bulk_sale_calculator' ? action.payload.calculator : {};
  return {
    ...state,
    isFetching: false,
    components: {
      ...state.components,
      bulk_sale_quantity: {
        ...bulkSaleQuantity,
        selected: action.payload.calculator?.selected,
      },
      main_actions: {
        ...action.payload.components.main_actions,
      },
    },
  };
};

const hiQuantityMapper = {
  [SHOW_HI_QUANTITY_ERROR]: showHiQuantityError,
  [HIDE_HI_QUANTITY_ERROR]: hideHiQuantityError,
  [FETCH_ON_SET_HI_QUANTITY_COMPLETE]: fetchOnSetHiQuantityComplete,
};

export { showHiQuantityError, hideHiQuantityError };
export { fetchOnSetHiQuantityComplete };
export default hiQuantityMapper;
