import React from 'react';
import { shape, string } from 'prop-types';
import classNames from 'classnames';
import StyledLabel from '../styled-label';
import QuestionTextCollapse from './questions-text-collapse';

const { useState, useCallback } = React;

const namespace = 'ui-pdp-questions__questions-list';

const Answer = ({ className, answer, date, denounce, denounceTemplate, tapeableLinks }) => {
  const [denounceVisibility, setDenounceVisibility] = useState(false);
  const submitAction = useCallback(() => {
    window.open(denounce.target, '_blank');
  }, [denounce]);
  return (
    <div
      className={className}
      onMouseEnter={() => {
        setDenounceVisibility(true);
      }}
      onMouseLeave={() => {
        setDenounceVisibility(false);
      }}
    >
      <QuestionTextCollapse
        className="separate"
        component="questions"
        text={answer.text}
        color={answer.text.color}
        length={answer.text.length}
        tapeableLinks={tapeableLinks}
      />
      {date && (
        <StyledLabel
          className={`${namespace}__answer-item__separate`}
          font_size="XSMALL"
          font_family="REGULAR"
          as="span"
          {...date}
        />
      )}
      {denounce && (
        <StyledLabel
          className={classNames(
            `andes-button andes-button--medium andes-button--transparent`,
            `${namespace}__answer-item__denounce ${namespace}__answer-item__denounce__overriding-default-button`,
            {
              [`${namespace}__item__denounce--VISIBLE`]: denounceVisibility,
            },
          )}
          as="button"
          font_size="XSMALL"
          font_family="REGULAR"
          accessibilityText={denounceTemplate.accessibility_text}
          data-testid="answer-button-denounce"
          onClick={submitAction}
          {...denounceTemplate}
        />
      )}
    </div>
  );
};

Answer.propTypes = {
  className: string,
  answer: shape({ text: string }).isRequired,
  date: shape({
    text: string,
    color: string,
  }),
  denounce: shape({
    target: string,
  }),
  denounceTemplate: shape({
    text: string,
    color: string,
  }).isRequired,
  tapeableLinks: shape(),
};

Answer.defaultProps = {
  denounce: null,
  className: null,
  date: null,
};

export default Answer;
