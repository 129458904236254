import React, { useRef, useState } from 'react';
import { node, shape, string, func, bool } from 'prop-types';
import classnames from 'classnames';
import { trackEvent } from '@vpp-frontend-components/common';
import Button from './components/button';

const namespace = 'ui-pdp-collapsable-mobile';

const ActionCollapsableCssMobile = ({ action, children, className, onSelect, scrollToTop }) => {
  const ref = useRef();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { track } = action;

  const onClick = e => {
    e.preventDefault();
    trackEvent(track);
    if (onSelect) {
      onSelect();
    }
    setIsCollapsed(!isCollapsed);
    if (scrollToTop) {
      ref.current.scrollIntoView();
    }
  };

  return (
    <div className={classnames(namespace, className, { [`${namespace}--is-collapsed`]: isCollapsed })}>
      <div
        className={classnames(`${namespace}__container`, { [`${namespace}__container-items-hidden`]: isCollapsed })}
        ref={ref}
      >
        {children}
      </div>
      {isCollapsed && <Button action={action} namespace={namespace} onClick={onClick} />}
    </div>
  );
};

ActionCollapsableCssMobile.propTypes = {
  action: shape({
    label: shape({
      text: string.isRequired,
      color: string,
    }).isRequired,
    target: string,
  }).isRequired,
  children: node,
  onSelect: func,
  className: string,
  scrollToTop: bool,
};

ActionCollapsableCssMobile.defaultProps = {
  children: null,
  className: null,
  onSelect: null,
  scrollToTop: true,
};

export default ActionCollapsableCssMobile;
