import React, { useRef } from 'react';
import { shape, string, bool } from 'prop-types';
import classnames from 'classnames';

import StyledLabel from '../styled-label';
import ActionModal from '../action-modal';
import Iframe from '../iframe';
import onIframeModalResize from '../../lib/on-iframe-modal-resize';
import IconFactory from '../icons/factory-builder';

const namespace = 'ui-vpp-apparel-size';

const ApparelSizeSpecs = ({ state, size_chart, inPicker }) => {
  const apparelSizeRef = useRef();

  if (state === 'HIDDEN' && !size_chart) {
    return null;
  }

  return (
    <div className={classnames(namespace, { [`${namespace}-picker`]: inPicker })} ref={apparelSizeRef}>
      {size_chart && (
        <ActionModal
          className={`${namespace}__action-modal`}
          componentAsLabel={<StyledLabel as="span" className={`${namespace}__label`} {...size_chart.label} />}
          icon={IconFactory({ id: 'ruler' }, `${namespace}__action-icon`)}
          url={size_chart.target}
          modalUrl="#apparel-size"
          modalClassName="ui-pdp-iframe-modal ui-pdp-apparel-size-modal"
          modalTitle={size_chart.label.text}
          closeModalLabel={size_chart?.close_modal_label}
        >
          <Iframe
            scrolling="no"
            src={size_chart.target}
            title={size_chart.label.text}
            data-testid="apparel-size-specs__iframe"
            onMessage={onIframeModalResize(
              'vip:modal-content:loaded',
              'height',
              '.ui-pdp-iframe-modal',
              '.andes-modal__header',
            )}
          />
        </ActionModal>
      )}
    </div>
  );
};

ApparelSizeSpecs.propTypes = {
  state: string.isRequired,
  size_chart: shape({}),
  inPicker: bool,
};

ApparelSizeSpecs.defaultProps = {
  size_chart: null,
  inPicker: false,
};

export default ApparelSizeSpecs;
