import {
  QUESTIONS_UNREGISTERED_EDIT_DATASTORED,
  QUESTIONS_UNREGISTERED_ON_CHANGE_VALUE,
  QUESTIONS_UNREGISTERED_ERROR_FORM,
} from '../../../../actions/vip/vis/types';

const questionsUnregisteredEditDatastored = (state, action) => {
  const { canEdit, isEditing, component } = action;
  return {
    ...state,
    [component]: {
      ...state[component],
      can_edit: canEdit,
      isEditing,
    },
  };
};

const questionsUnregisteredOnChangeValue = (state, action) => {
  const { input, value } = action.payload;
  Object.keys(state).forEach(key => {
    if (state[key] && state[key].form && state[key].form[input]) {
      state[key].form[input].value = value;
    }
  });

  return {
    ...state,
  };
};

const questionsUnregisteredErrorForm = (state, action) => {
  const { form, component } = action.payload;

  return {
    ...state,
    [component]: {
      ...state[component],
      form,
    },
  };
};

const questionsUnregisteredMapper = {
  [QUESTIONS_UNREGISTERED_EDIT_DATASTORED]: questionsUnregisteredEditDatastored,
  [QUESTIONS_UNREGISTERED_ON_CHANGE_VALUE]: questionsUnregisteredOnChangeValue,
  [QUESTIONS_UNREGISTERED_ERROR_FORM]: questionsUnregisteredErrorForm,
};

export { questionsUnregisteredEditDatastored };
export { questionsUnregisteredOnChangeValue };
export { questionsUnregisteredErrorForm };
export default questionsUnregisteredMapper;
