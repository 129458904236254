import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';

const FEATURE_NAME = 'cpg_summary';

const mapStateToProps = ({ components: { [FEATURE_NAME]: cpgSummaryInfo }, isFetching }) => ({
  ...cpgSummaryInfo,
  isFetching,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME, 'mb-20'),
  connect(mapStateToProps),
);

const cpgSummary = CpgSummary => enhance(CpgSummary);

export default cpgSummary;
