import React, { useEffect } from 'react';
import { bool, func, string, shape } from 'prop-types';
import Modal from '@andes/modal';
import object from '../../lib/global';
import APIService from '../../services/api';
import { stringIncludes } from '../../lib/includes';

const namespace = 'ui-pdp-iframe-reviews';

/**
 * @TODO llevar a actionmodal
 */

const IFrameReviews = ({ action, title, hashId, visible, onClose, reviewsIframeId }) => {
  const symbol = action?.target && stringIncludes(action.target, '?') ? '&' : '?';
  const iframeTarget = `${action?.target}${symbol}parent_origin=${object?.location?.origin}`;

  useEffect(() => {
    const handleRedirectToLogin = async () => {
      await APIService.redirectToLogin(reviewsIframeId, 'reviews', 'reviews');
    };
    const handleMessage = e => {
      if (visible && e?.data?.message) {
        switch (e.data.message) {
          case 'nav:cp:redirecttologin':
            handleRedirectToLogin();
            break;
          default:
            break;
        }
      }
    };
    if (visible) {
      object.addEventListener('message', handleMessage);
    } else {
      object.removeEventListener('message', handleMessage);
    }

    return () => {
      object.removeEventListener('message', handleMessage);
    };
  }, [visible, reviewsIframeId]);

  return (
    <Modal
      url={`#${hashId}`}
      closeUrl=""
      closeButtonSrLabel={action.close_modal_label}
      className={namespace}
      open={visible}
      onClose={e => onClose(e)}
      srLabel={title}
    >
      <iframe
        id={namespace}
        data-testid={namespace}
        title={title}
        className={`${namespace}__content`}
        src={iframeTarget}
        width="100%"
        height="100%"
        scrolling="auto"
        frameBorder="0"
      />
    </Modal>
  );
};

IFrameReviews.propTypes = {
  action: shape({
    target: string.isRequired,
  }).isRequired,
  hashId: string.isRequired,
  title: string,
  visible: bool,
  onClose: func,
  reviewsIframeId: string,
};

IFrameReviews.defaultProps = {
  visible: false,
  onClose: null,
  title: null,
  reviewsIframeId: null,
};

export default IFrameReviews;
