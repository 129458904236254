import React from 'react';
import { shape, bool, string, arrayOf, number } from 'prop-types';
import classNames from 'classnames';
import map from 'lodash/map';
import StyledLabel from '../styled-label';
import IconResponse from '../icons/response';
import StaticPropsContext from '../context/static-props';
import QuestionsDenounceModal from './questions-denounce-modal';
import Question from './questions-question';
import Answer from './questions-answer';
import BackdropModal from '../backdrop-modal';

const namespace = 'ui-pdp-questions__questions-list';

const QuestionsList = ({
  className,
  questionsList,
  isUsersQuestions,
  showTitle,
  isFetching,
  scrollToQuestion,
  closeModalLabel,
}) => {
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [denounceLinks, setDenounceLinks] = React.useState({ question: null, answer: null });

  const { deviceType } = React.useContext(StaticPropsContext);
  const { questions, touchable_links: tapeableLinks } = questionsList || {};

  const denounceOnClick = (e, question, answer) => {
    e.preventDefault();
    setIsOpenModal(!isFetching);
    setDenounceLinks({ question, answer });
  };

  return (
    <div className={classNames(namespace, className)}>
      {showTitle && questionsList.title ? <h3 className={`${namespace}__title`}>{questionsList.title.text}</h3> : null}
      <div className={classNames({ [`${namespace}__content`]: isUsersQuestions })}>
        {map(questions, (questionItem, i) => (
          <div
            key={questionItem.id}
            className={classNames(
              { [`${namespace}__item-questions--user-questions`]: isUsersQuestions },
              { [`${namespace}__item-questions--others-questions`]: !isUsersQuestions },
            )}
          >
            <Question
              shouldScrollToQuestion={i + 1 === scrollToQuestion}
              content={questionItem.question.content}
              target={questionItem.question.target}
              date={questionItem.question.date}
              hasAnswer={!!questionItem.answer}
              denounce={questionItem.question.denounce}
              denounceTemplate={questionsList.denounce_template.question}
              deviceType={deviceType}
            />
            {questionItem.answer && (
              <div className={`${namespace}__answer-container`}>
                <IconResponse className={`${namespace}__answer-container__icon`} />
                <Answer
                  className={`${namespace}__answer-container__answer`}
                  answer={questionItem.answer.content}
                  date={questionItem.answer.date}
                  denounce={deviceType !== 'mobile' ? questionItem.answer.denounce : null}
                  denounceTemplate={questionsList.denounce_template.answer}
                  tapeableLinks={tapeableLinks}
                />
              </div>
            )}
            {questionItem.answer &&
              questionItem.question &&
              questionItem.question.denounce &&
              questionItem.answer.denounce &&
              deviceType === 'mobile' && (
                <StyledLabel
                  onClick={e => {
                    denounceOnClick(e, questionItem.question.denounce.target, questionItem.answer.denounce.target);
                  }}
                  className={classNames(`${namespace}__question-item__separate`, {
                    [`${namespace}__answer-item__separate--denounce-mobile`]: deviceType === 'mobile',
                  })}
                  as="a"
                  text={questionsList.denounce_template.label.text}
                  font_size="XSMALL"
                  font_family="REGULAR"
                  href={questionItem.question.target}
                />
              )}
          </div>
        ))}
      </div>

      {deviceType === 'mobile' && (
        <BackdropModal
          onClose={() => {
            setIsOpenModal(false);
          }}
          visible={isOpenModal}
          url="#denounce"
          closeModalLabel={closeModalLabel}
        >
          <QuestionsDenounceModal
            denounceLinks={denounceLinks}
            questionText={questionsList.denounce_template.question.text}
            answerText={questionsList.denounce_template.answer.text}
          />
        </BackdropModal>
      )}
    </div>
  );
};

QuestionsList.propTypes = {
  className: string,
  closeModalLabel: string.isRequired,
  questionsList: shape({
    title: shape({
      text: string,
      color: string,
    }).isRequired,
    denounce_template: shape({
      question: shape({
        text: string,
        colot: string,
      }),
      answer: shape({
        text: string,
        color: string,
      }),
    }).isRequired,
    questions: arrayOf(
      shape({
        question_id: number,
        question: shape({
          content: shape({
            text: string,
            color: string,
          }),
          date: shape({
            text: string,
            color: string,
          }),
          denounce: shape({
            action: shape({
              target: string,
            }),
          }),
        }),
        answer: shape({
          content: shape({
            text: string,
            color: string,
          }).isRequired,
          date: shape({
            text: string,
            color: string,
          }).isRequired,
          denounce: shape({
            action: shape({
              target: string,
            }),
          }),
        }),
      }),
    ),
  }).isRequired,
  showTitle: bool,
  scrollToQuestion: number,
  isUsersQuestions: bool,
  isFetching: bool,
};

QuestionsList.defaultProps = {
  className: null,
  isUsersQuestions: false,
  showTitle: false,
  scrollToQuestion: null,
  isFetching: false,
};

export default QuestionsList;
