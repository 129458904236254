import mainMappers from './core';
import featureMappers from './features';
import visMappers from './vis';

const coreMapper = {
  ...mainMappers,
  ...featureMappers,
  ...visMappers,
};

export default coreMapper;
