import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import jsonToProps from './top-quantity-mapper';

const FEATURE_NAME = 'top_available_quantity_summary';

const mapStateToProps = ({ components: { [FEATURE_NAME]: topQuantitySummary }, isFetching }) =>
  jsonToProps({ ...topQuantitySummary, isFetching });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

const topQuantitySummary = TopQuantitySummary => enhance(TopQuantitySummary);

export default topQuantitySummary;
