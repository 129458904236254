import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import jsonToProps from '../generic-summary/shipping-mapper';
import * as actions from '../../../../actions/vip';

const FEATURE_NAME = 'second_shipping_summary';

const mapStateToProps = ({ components: { [FEATURE_NAME]: shippingSummary }, isFetching }) =>
  jsonToProps({ ...shippingSummary, isFetching, id: FEATURE_NAME });

const mapDispatchToProps = dispatch => ({
  updateShippingSummary: shippingOptionId => {
    dispatch(actions.fetchItemOnShippingUpdate(shippingOptionId));
  },
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const shippingSummary = ShippingSummary => enhance(ShippingSummary);

export default shippingSummary;
