import React from 'react';
import { element, string } from 'prop-types';
import classnames from 'classnames';
import { Pill } from '@andes/badge';
import Message from '@andes/message';
import { Col } from '@vpp-frontend-components/grid';
import { MessageType } from './types';
import { validateSnackbarColor } from '../../utils/validators';

const namespace = 'ui-pdp-compatibilities';

const CompatibilitiesWidgetComponent = ({
  className,
  id,
  title,
  description,
  feature,
  userSelection,
  message,
  actions,
}) => (
  <Col className={classnames(className, namespace)} id={id}>
    <h2 className={`${namespace}__title`}>{title}</h2>
    <Message
      className={`${namespace}__content`}
      hierarchy="quiet"
      actions={actions}
      color={validateSnackbarColor(userSelection ? message.type.toLowerCase() : undefined)}
    >
      <div className={`${namespace}__text`}>
        {!userSelection && (
          <>
            {description}
            <Pill
              className={`${namespace}__feature`}
              color="accent"
              size="small"
              hierarchy="loud"
              roundedCorners={['top-right', 'top-left', 'bottom-right', 'bottom-left']}
            >
              {feature}
            </Pill>
          </>
        )}
        {userSelection && (
          <>
            {message.label.text} <strong>{userSelection}.</strong> {message.label2 && message.label2.text}
          </>
        )}
      </div>
    </Message>
  </Col>
);

CompatibilitiesWidgetComponent.namespace = namespace;

CompatibilitiesWidgetComponent.propTypes = {
  className: string,
  id: string,
  userSelection: string,
  title: string.isRequired,
  description: string.isRequired,
  feature: string.isRequired,
  message: MessageType,
  actions: element,
};

CompatibilitiesWidgetComponent.defaultProps = {
  id: null,
  className: null,
  userSelection: null,
  message: null,
  actions: null,
};

export default CompatibilitiesWidgetComponent;
