import { getDefaultErrorMessage } from './getDefaultErrorMessage';
import { feStatsdHelper } from '../../utils/frontendStatsHelper';
import { trackEvent } from '../../lib/tracking';
import { SHOW_SNACKBAR } from '../actions';
import { VPP_SEQUENCER_DATADOG_KEY_CASES } from '../../services/frontend-statsd/config/allowed-keys';

const RENDER_MODES = {
  CONTAINER: 'container',
  FULLSCREEN: 'fullscreen',
};

const ACTIONS_SEQUENCER = {
  BOTTOM_SHEET_WITH_PRELOAD: 'BOTTOM_SHEET_WITH_PRELOAD',
  BOTTOM_SHEET: 'BOTTOM_SHEET',
  LANDING: 'LANDING',
  LANDING_WITH_PRELOAD: 'LANDING_WITH_PRELOAD',
  SNACKBAR: 'SNACKBAR',
  NAVIGATE: 'NAVIGATE',
};

const ACTIONS_SEQUENCER_TRACK = {
  FALLBACK: 'fallback',
  SNACKBAR: 'snackbar',
  RESPONSE: 'response',
  LOADING: 'loading',
  LOADED: 'loaded',
  NAVIGATE: 'navigate',
  PRELOAD_SHOWN: 'preload_shown',
  NAVIGATE_LOADING: 'navigate_loading',
  NAVIGATE_LOADED: 'navigate_loaded',
};

const newActionLoading = (actions, isLoading, actionKey) =>
  actions.map(actionItem => {
    if (actionKey === actionItem.label.text) {
      return {
        ...actionItem,
        isFetchingData: isLoading,
        isLoadingButton: isLoading,
      };
    }
    return actionItem;
  });

const newPayloadFetchSequencer = (actions, isLoading, actionKey = null) => ({
  actions: newActionLoading(actions, isLoading, actionKey),
  isFetching: isLoading,
});

const updateOnePayForAllTrack = (track, action) => {
  if (action) {
    return {
      ...track,
      melidata_event: {
        ...track.melidata_event,
        event_data: {
          ...track.melidata_event.event_data,
          action,
          type: track.melidata_event.type ? track.melidata_event.type.toLowerCase() : 'UNKNOWN',
        },
      },
    };
  }
  return track;
};

const redirectOrShowSnackbar = ({
  fallbackToRedirect,
  reason,
  additionalInfo,
  tags,
  shouldLogMessage = false,
  trackData,
  message,
  dispatch,
  siteId,
  statsdConfig,
}) => {
  if (fallbackToRedirect) {
    if (trackData) {
      trackEvent(updateOnePayForAllTrack(trackData, ACTIONS_SEQUENCER_TRACK.FALLBACK));
    }
    feStatsdHelper({
      key: VPP_SEQUENCER_DATADOG_KEY_CASES.REDIRECT_TO_FALLBACK,
      additionalTags: {
        reason,
        ...tags,
      },
      additionalInfo,
      shouldLogMessage,
      statsdConfig,
    });
    window.location.href = fallbackToRedirect;
  } else {
    if (trackData) {
      trackEvent(updateOnePayForAllTrack(trackData, ACTIONS_SEQUENCER_TRACK.SNACKBAR));
    }
    feStatsdHelper({
      key: VPP_SEQUENCER_DATADOG_KEY_CASES.SHOW_SNACKBAR,
      additionalTags: {
        reason,
        ...tags,
        snackbar_type: 'error',
      },
      additionalInfo,
      shouldLogMessage,
      statsdConfig,
    });
    dispatch({
      type: SHOW_SNACKBAR,
      params: {
        message: message || getDefaultErrorMessage(siteId),
        type: 'error',
        delay: 3000,
        called_from: 'pdp',
      },
    });
  }
};

const getLoadingTimeInterval = ({ nowTime = Date.now(), loadingStartTime }) => {
  let interval = null;
  if (!loadingStartTime) {
    return 'NO_START_TIME';
  }
  const elapsedTime = nowTime - loadingStartTime;
  switch (true) {
    case elapsedTime < 0:
      interval = 'LESS_THAN_0SECS';
      break;
    case elapsedTime < 3000:
      interval = 'LESS_THAN_3SECS';
      break;
    case elapsedTime < 7000:
      interval = 'BETWEEN_3_AND_7_SECS';
      break;
    case elapsedTime < 10000:
      interval = 'BETWEEN_7_AND_10_SECS';
      break;
    case elapsedTime < 15000:
      interval = 'BETWEEN_10_AND_15_SECS';
      break;
    case elapsedTime >= 15000:
      interval = 'MORE_THAN_15_SECS';
      break;
    default:
      break;
  }
  return interval;
};

export {
  newActionLoading,
  newPayloadFetchSequencer,
  updateOnePayForAllTrack,
  redirectOrShowSnackbar,
  getLoadingTimeInterval,
  ACTIONS_SEQUENCER,
  ACTIONS_SEQUENCER_TRACK,
  RENDER_MODES,
};
