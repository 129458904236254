import React from 'react';
import { string, shape } from 'prop-types';
import { Image } from 'nordic/image';

const namespace = 'ui-vpp-store';

const StoreHeader = ({ background_image, logo_image, action, logo_alt }) => {
  const logoHeader = (
    <div className={`${namespace}__logo-image-wrapper`}>
      <Image className={`${namespace}__logo-image`} src={logo_image} alt={logo_alt} />
    </div>
  );

  return (
    <div className={`${namespace}-header`}>
      <div className={`${namespace}__bg-image`}>
        <div className={`${namespace}__image-wrapper`}>
          <Image src={background_image} role="presentation" alt="" lazyload="off" preload />
        </div>
        {logo_image && (
          <div className={`${namespace}__logo-container`}>
            {action && action.target ? <a href={action.target}>{logoHeader}</a> : logoHeader}
          </div>
        )}
      </div>
    </div>
  );
};

StoreHeader.propTypes = {
  background_image: string.isRequired,
  logo_image: string,
  logo_alt: string,
  action: shape({
    target: string,
  }),
};

StoreHeader.defaultProps = {
  logo_image: null,
  action: null,
  logo_alt: null,
};

export default StoreHeader;
