import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';

const ICON_ID = 'poly_star_fill';
const namespace = 'ui-pdp-icon ui-pdp-icon--star-full';

const IconStarFull = ({ className }) => (
  <svg className={classnames(namespace, className)} width="10" height="10" viewBox="0 0 10 10" fill="none">
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconStarFull.propTypes = {
  className: string,
};

IconStarFull.defaultProps = {
  className: null,
};

IconStarFull.ICON_ID = ICON_ID;

export default React.memo(IconStarFull);
export { IconStarFull };
