import { MAKE_QUESTION, MAKE_QUESTION_ERROR, MAKE_QUESTION_SUCCESS } from '../../../../actions/vip/types';
import { MAKE_QUESTION_SA, RESULTS } from '../../../../components/qadb/qadb.constants';

const makeQuestions = (state, action) => {
  const makeQuestion = state.components.qadb.qadb_data.components.find(component => component.id === MAKE_QUESTION_SA);
  makeQuestion.initialValue = action.payload.question;
  return {
    ...state,
    components: {
      ...state.components,
      qadb: {
        ...state.components.qadb,
        makequestion: {
          ...state.components.qadb.makequestion,
          disabled: true,
        },
      },
    },
  };
};

const makeQuestionError = (state, action) => {
  const makeQuestion = state.components.qadb.qadb_data.components.find(component => component.id === MAKE_QUESTION_SA);
  makeQuestion.initialValue = '';
  return {
    ...state,
    components: {
      ...state.components,
      qadb: {
        ...state.components.qadb,
        snackMessage: { key: Math.random(), show: true, type: 'error', message: action.payload.message },
        makequestion: {
          ...state.components.qadb.makequestion,
          disabled: false,
        },
      },
    },
  };
};

const makeQuestionSuccess = (state, action) => {
  const components = Array.from(state.components.qadb.qadb_data.components);
  const makeQuestion = state.components.qadb.qadb_data.components.find(component => component.id === MAKE_QUESTION_SA);
  makeQuestion.initialValue = '';
  return {
    ...state,
    components: {
      ...state.components,
      qadb: {
        ...state.components.qadb,
        qadb_data: {
          ...state.components.qadb.qadb_data,
          components: [...components.filter(component => component.id !== RESULTS), action.payload.results],
          query: '',
        },
        smartAnswerModal: { isOpen: false, isAsking: false },
        makequestion: {
          ...state.components.qadb.makequestion,
          disabled: false,
        },
        snackMessage: {
          key: Math.random(),
          show: true,
          type: 'success',
          message: action.payload.message,
        },
      },
    },
  };
};

const makeQuestionsMapper = {
  [MAKE_QUESTION]: makeQuestions,
  [MAKE_QUESTION_ERROR]: makeQuestionError,
  [MAKE_QUESTION_SUCCESS]: makeQuestionSuccess,
};

export { makeQuestions, makeQuestionError, makeQuestionSuccess };
export default makeQuestionsMapper;
