/* eslint-disable complexity */
import React from 'react';
import { FocusScope } from '@react-aria/focus';
import formatBulkQuantity from '../../../../../utils/formatBulkQuantity';
import { Tooltip } from '@andes/tooltip';
import { List } from '@andes/list';
import classnames from 'classnames';
import componentEnhance from '../../../../../lib/component-enhance';
import { namespaceDesktop as namespace, quantitySelectorDesktopPropTypes } from './model';
import useQuantitySelectorDesktop from '../../hooks/use-quantity-selector-desktop';
import { getQuantityData } from '../../helpers/get-quantity-data';
import QuantityListItem from './desktop/quantity-list-item';
import { getLabelElementSelector } from '../../helpers/get-label-element-selector';
import QuantityInputItem from './desktop/quantity-input-item';
import QuantityTrigger from '../quantity-trigger/quantity-trigger';

const optionsClassname = 'ui-pdp-buybox__quantity__trigger--options';

const QuantitySelectorDesktop = ({
  message,
  picker,
  quantitySelector,
  trigger: Trigger = QuantityTrigger,
  setQuantity,
  showInput,
  onShowInput,
  buttonFocus = false,
}) => {
  const {
    layout,
    yieldValue,
    bulkSale,
    hasCustomOptions,
    customOptionsItems,
    ref,
    triggerRef,
    listPickerRef,
    inputRef,
    visible,
    localQuantity,
    onTriggerClick,
    onClickListItem,
    closeTooltip,
  } = useQuantitySelectorDesktop({ picker, quantitySelector, setQuantity, buttonFocus });

  const showErrorMessage = picker && picker.error_message && !picker.error_message.is_deferred;
  const hasOptions = !!(
    quantitySelector &&
    quantitySelector.selector.template.options &&
    quantitySelector.selector.template.options.length > 0
  );
  const hasVolumeDiscount = !!quantitySelector?.selector?.template?.volume_discount_options;
  const minimumQuantity = bulkSale ? yieldValue : quantitySelector?.minimum_quantity || 1;
  const lengthRows =
    quantitySelector && !hasOptions
      ? quantitySelector.selector.rows + minimumQuantity - 1
      : quantitySelector.selector.rows;
  const finalLength = hasCustomOptions ? customOptionsItems.length : lengthRows;
  const { description, selected_label } = picker;
  const selectedLabelText =
    (!description && selected_label.values) || (bulkSale && selected_label.values)
      ? componentEnhance.jsx(selected_label.text, selected_label.values, 'ui-pdp-buybox__quantity__selected__label')
      : selected_label.text;

  const items = [];
  if (quantitySelector) {
    const quantityIndex = hasOptions || hasCustomOptions ? 1 : minimumQuantity;
    const offset = minimumQuantity - 1;

    for (let index = quantityIndex; index <= finalLength; index += 1) {
      const { amount, quantity } = getQuantityData(
        index,
        hasCustomOptions,
        customOptionsItems,
        hasVolumeDiscount,
        quantitySelector,
      );
      const showTitle = hasVolumeDiscount || hasCustomOptions;
      let offsetIndex = index;
      if (hasOptions) {
        offsetIndex = bulkSale ? index * yieldValue : offset + index;
      }
      if (hasCustomOptions) {
        offsetIndex = quantity.value;
      }
      items.push(
        <QuantityListItem
          key={index}
          index={index}
          offsetIndex={offsetIndex}
          localQuantity={localQuantity}
          quantitySelector={quantitySelector}
          onClickListItem={onClickListItem}
          closeTooltip={closeTooltip}
          labelElement={i => getLabelElementSelector(i, quantitySelector, hasOptions)}
          showTitle={showTitle}
          amount={amount}
          quantity={quantity}
        />,
      );
    }

    const canShowLastItem =
      (quantitySelector.selector.template?.more &&
        quantitySelector.available_quantity > quantitySelector.selector.rows) ||
      (hasCustomOptions && quantitySelector.input);
    if (canShowLastItem) {
      items.push(
        <QuantityInputItem
          showInput={showInput}
          hasCustomOptions={hasCustomOptions}
          listPickerRef={listPickerRef}
          picker={picker}
          quantitySelector={quantitySelector}
          minimumQuantity={minimumQuantity}
          bulkSale={bulkSale}
          localQuantity={localQuantity}
          formatBulkQuantity={formatBulkQuantity}
          onClickListItem={onClickListItem}
          inputRef={inputRef}
          closeTooltip={closeTooltip}
          onShowInput={onShowInput}
          customOptionsItems={customOptionsItems}
          yieldValue={yieldValue}
        />,
      );
    }
  }

  const listClasses = classnames({
    [`${namespace}__list--${layout}`]: (hasVolumeDiscount || hasCustomOptions) && !bulkSale && !hasCustomOptions,
    [`${namespace}__list--default`]: !hasVolumeDiscount && !bulkSale && !hasCustomOptions,
    [`${namespace}__list--default-bulk`]: bulkSale && !hasCustomOptions,
    [`${namespace}__list-custom-options`]: hasCustomOptions && !bulkSale && !hasOptions,
  });

  const tooltipType = hasVolumeDiscount || hasCustomOptions ? 'default' : 'dropdown';
  const offsetX = hasCustomOptions ? '0' : 3;

  return (
    <Tooltip
      id="quantity-selector"
      className={classnames(namespace, {
        [`${namespace}--options`]: (hasOptions || hasCustomOptions) && !bulkSale,
        [`${namespace}--options-bulk`]: hasOptions && bulkSale && !hasCustomOptions,
        [`${namespace}__options-custom-options`]: hasCustomOptions && !bulkSale && !hasOptions,
      })}
      content={
        <FocusScope contain restoreFocus autoFocus>
          <div ref={ref} className={listClasses}>
            <List type={tooltipType} srLabel={picker.title} ref={listPickerRef}>
              {items}
            </List>
          </div>
        </FocusScope>
      }
      offsetX={offsetX}
      offsetY={2}
      side="bottomLeft"
      trigger="click"
      open={visible}
    >
      <Trigger
        ref={triggerRef}
        title={picker.title}
        selectedLabel={selectedLabelText}
        max={quantitySelector && quantitySelector.available_quantity}
        message={bulkSale ? undefined : message}
        active={visible}
        onClick={onTriggerClick}
        bulkSale={bulkSale}
        showError={showErrorMessage}
        className={classnames({ [optionsClassname]: hasOptions })}
        hasCustomOptions={hasCustomOptions}
      />
    </Tooltip>
  );
};

QuantitySelectorDesktop.propTypes = quantitySelectorDesktopPropTypes;

export default QuantitySelectorDesktop;
