const jsonToProps = (props = {}) => {
  const { title = '', menu = [], see_more = '', see_less = '' } = props;
  return {
    menu: menu.map(({ title: menuTitle = {}, children = [] }) => ({
      title: menuTitle?.label?.text,
      url: menuTitle?.target,
      children: children.map(({ target, label = {} }) => ({
        text: label?.text,
        url: target,
      })),
    })),
    title: title?.text,
    seeMore: see_more?.text,
    seeLess: see_less?.text,
  };
};

module.exports = jsonToProps;
