const DESKTOP = 'desktop';
const RESIZE_HEIGHT_METHOD = 'resize-height';
const ADD_MODAL_HEADER_STYLE_METHOD = 'add-modal-header-style';
const REMOVE_MODAL_HEADER_STYLE_METHOD = 'remove-modal-header-style';
const SHOW_SNACKBAR_METHOD = 'show-snackbar';
const CLOSE_METHOD = 'close';
const CALLBACK_METHOD = 'callback';
const CHANGE_TITLE_METHOD = 'change-title';
const SNACKBAR_LIST_LIMIT_TYPE = 'error';
const BOOKMARK_MODAL_IFRAME_CLOSE = 'bookmarks:modal-iframe:close';
const SHOW_GIFT_REGISTRY_EXPLANATION = 'showGiftRegistryExplanation';
const WISHLIST_LIST_SELECTOR_SET_CURRENT_STEP = 'wishlist:list-selector:set-current-step';

export {
  DESKTOP,
  RESIZE_HEIGHT_METHOD,
  ADD_MODAL_HEADER_STYLE_METHOD,
  REMOVE_MODAL_HEADER_STYLE_METHOD,
  SHOW_SNACKBAR_METHOD,
  CLOSE_METHOD,
  CALLBACK_METHOD,
  CHANGE_TITLE_METHOD,
  SNACKBAR_LIST_LIMIT_TYPE,
  BOOKMARK_MODAL_IFRAME_CLOSE,
  SHOW_GIFT_REGISTRY_EXPLANATION,
  WISHLIST_LIST_SELECTOR_SET_CURRENT_STEP,
};
