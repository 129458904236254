import React from 'react';
import { string, node, shape } from 'prop-types';
import backgroundColor from '../../lib/background-color';

const StyledContainer = ({ children, bgColor, className }) => (
  <div className={backgroundColor(bgColor, className)}>{children}</div>
);

StyledContainer.propTypes = {
  bgColor: shape({ text: string.isRequired }).isRequired,
  children: node.isRequired,
  className: string.isRequired,
};

export default StyledContainer;
