import React from 'react';

const ActionsForm = ({ form, isWebview, availableQuantity }) =>
  Object.keys(form).map(key => {
    // TODO: Esto lo deberia hacer el backend
    // dado a que estan asistiendo a nativo en otras cosas con mayor prioridad lo tomamos nosotros
    if (isWebview && key === 'item_id' && availableQuantity) {
      return (
        <>
          <input type="hidden" key="ids" name="ids" value={`${form.item_id}-${availableQuantity.picker.selected}`} />
          <input
            type="hidden"
            key="items"
            name="items"
            value={`${form.item_id}-${availableQuantity.picker.selected}`}
          />
        </>
      );
    }

    return <input type="hidden" key={key} name={key} value={form[key]} />;
  });

export default ActionsForm;
