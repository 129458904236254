import React from 'react';
import useEnhancedReducer from '../../../hooks/use-enhance-reducer';
import thunk from '../../../store/middleware/thunk';
import reducer from '../reducers/reviews';
import jsonToProps from '../jsonToProps';
import Reviews from '../reviews.desktop';
import { likeReview, dislikeReview } from '../actions/index';
import Grid from '../../grid';

const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line n/global-require
  const logger = require('../../../store/middleware/logger').default;
  middlewares.push(logger);
}

const mapDispatchToProps = dispatch => ({
  onDislikeComment: (e, reviewId) => {
    e.preventDefault();
    dispatch(dislikeReview(reviewId));
  },

  onLikeComment: (e, reviewId) => {
    e.preventDefault();
    dispatch(likeReview(reviewId));
  },
});

const ReviewsContainer = initialState => {
  const [internalState, dispatch] = useEnhancedReducer(reducer, initialState, middlewares);
  return <Reviews {...internalState} {...mapDispatchToProps(dispatch)} />;
};

const MappedReviews = props => (
  <Grid.Col size={3}>
    <ReviewsContainer {...props} />
  </Grid.Col>
);

export default ReviewsContainer;
export const MappedReviewsContainer = props => <MappedReviews {...jsonToProps(props)} />;
