import React from 'react';
import { shape, string, func } from 'prop-types';
import componentEnhance from '../../lib/component-enhance';
import StyledLabel from '../styled-label';
import IconFactory from '../icons/factory-builder';
import { trackEvent } from '../../lib/tracking';

const namespace = 'ui-pdp-seller-validated';
const location = 'location_and_points';

const onClickHandler = (e, onScrollComponent, target, track) => {
  if (track) {
    trackEvent(track);
  }
  return onScrollComponent?.({ component: target, focus: true });
};

const SellerValidated = ({ title, icon, onScrollComponent, target, track }) => (
  <div className={namespace}>
    <StyledLabel
      onClick={e => onClickHandler(e, onScrollComponent, target, track)}
      className={`${namespace}__title`}
      text={componentEnhance.jsx(title.text, title.values)}
      data-testid="seller-validated"
      font_size={title.font_size}
      font_family={title.font_family}
      color={title.color}
      {...target === location && { as: 'a' }}
    >
      {IconFactory(icon, `${namespace}__icon`)}
    </StyledLabel>
  </div>
);

SellerValidated.propTypes = {
  title: shape({
    text: string.isRequired,
    color: string,
    font_family: string,
    font_size: string,
  }).isRequired,
  icon: shape({
    id: string,
    size: string,
  }).isRequired,
  onScrollComponent: func,
  target: string.isRequired,
  track: shape({
    melidata_event: shape({
      event_data: shape,
      path: string,
    }),
  }).isRequired,
};

SellerValidated.defaultProps = {
  onScrollComponent: null,
};

export default SellerValidated;
