import React from 'react';
import { string, shape } from 'prop-types';
import { Image } from 'nordic/image';
import StyledLabel from '../styled-label';
import { trackEvent } from '../../lib/tracking';
import IconFactory from '../icons/factory-builder';

const namespace = 'ui-pdp__header-top-brand';

const HeaderTopBrand = ({ title, logo, url, track, official_store_icon }) => (
  <a href={url} onClick={() => trackEvent(track)}>
    <div className={namespace}>
      {logo && (
        <div className={`${namespace}__image-container`}>
          <Image alt="" className={`${namespace}__image-container__image`} src={logo.url.src} {...logo} />
        </div>
      )}
      <StyledLabel className={`${namespace}__text`} as="p" {...title} />
      {official_store_icon && IconFactory({ id: 'cockade' }, 'ui-pdp-cockade-icon')}
    </div>
  </a>
);

HeaderTopBrand.propTypes = {
  title: shape({
    text: string,
    color: string,
    font_size: string,
  }).isRequired,
  logo: shape({}),
  official_store_icon: string,
  url: string.isRequired,
  track: shape({}),
};

HeaderTopBrand.defaultProps = {
  logo: null,
  title: null,
  url: null,
  track: null,
};

export default HeaderTopBrand;
