import React from 'react';
import classNames from 'classnames';
import colornames from '../../../../../lib/colornames';
import { Button } from '@andes/button';
import IconFactory from '../../../../icons/factory-builder';
import { namespace, quantityTriggerPropTypes } from './model';
import QuantityTitle from './quantity-title';
import QuantitySelectedLabel from './quantity-select-label';
import QuantityChevron from './quantity-chevron';

const QuantityTrigger = React.forwardRef(
  (
    {
      title,
      description,
      showError = false,
      disabled = false,
      selectedLabel,
      bulkSale = false,
      message,
      onClick = null,
      active,
      hasCustomOptions = false,
    },
    ref,
  ) => (
    <div ref={ref}>
      <Button
        ref={ref}
        id="quantity-selector"
        data-testid="quantity-selector-trigger"
        aria-describedby="quantity-selector"
        className={classNames({
          [`${namespace}__trigger-bulksale`]: bulkSale,
          [`${namespace}__trigger-custom-options`]: hasCustomOptions,
          [`${namespace}__trigger`]: !bulkSale && !hasCustomOptions,
          [`${namespace}__trigger-custom-options--active`]: hasCustomOptions && active,
        })}
        hierarchy="transparent"
        onClick={onClick}
        disabled={disabled}
      >
        <QuantityTitle title={title} showError={showError} />
        {showError && IconFactory('MESSAGE_ALERT', colornames('RED'))}
        <QuantitySelectedLabel selectedLabel={selectedLabel} description={description} showError={showError} />
        {hasCustomOptions && message && <span className={`${namespace}__available`}>{message}</span>}
        {!disabled && <QuantityChevron active={active} showError={showError} disabled={disabled} />}
        {!hasCustomOptions && message && <span className={`${namespace}__available`}>{message}</span>}
      </Button>
    </div>
  ),
);

QuantityTrigger.propTypes = quantityTriggerPropTypes;

export default QuantityTrigger;
