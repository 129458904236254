import React from 'react';
import { string } from 'prop-types';

const HeaderFallback = ({ title }) => <h1 className="ui-pdp-title ui-pdp-title--fallback-boundary">{title}</h1>;

HeaderFallback.propTypes = {
  title: string.isRequired,
};

export default HeaderFallback;
