import {
  MODAL_REQUEST_START,
  MODAL_REQUEST_CLOSE,
  MODAL_REQUEST_RESIZING,
  FETCH_VEHICLE_HISTORY_MODAL,
} from '../../../../actions/vip/vis/types';

const modalRequestStart = state => ({
  ...state,
  modal_request: {
    isOpen: true,
    resizing: Math.random(),
  },
});

const modalRequestClose = state => ({
  ...state,
  modal_request: {
    isOpen: false,
  },
});

const modalRequestResizing = state => ({
  ...state,
  modal_request: {
    ...state.modal_request,
    resizing: Math.random(),
  },
});

const fetchVehicleHistoryModal = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    vehicle_history_specs: {
      ...state.vehicle_history_specs,
      modalComponents: payload,
    },
  };
};

const modalMapper = {
  [MODAL_REQUEST_START]: modalRequestStart,
  [MODAL_REQUEST_CLOSE]: modalRequestClose,
  [MODAL_REQUEST_RESIZING]: modalRequestResizing,
  [FETCH_VEHICLE_HISTORY_MODAL]: fetchVehicleHistoryModal,
};

export { modalRequestStart, modalRequestClose, modalRequestResizing, fetchVehicleHistoryModal };
export default modalMapper;
