import get from 'lodash/get';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import BillboardAd from '../../../../components/advertising';

const FEATURE_NAME = 'billboard_ad';
const className = 'ui-billboard-ad';

const mapStateToProps = (
  {
    components: {
      [FEATURE_NAME]: {
        google_ad: googleAd,
        slot_id: slotId,
        caption,
        segmentation,
        viewport_margin,
        personal_data_ads_denied,
        track,
      },
    },
    userConsentCookie,
  },
  ownProps,
) => ({
  slotId,
  googleAd: {
    ...googleAd,
    size: googleAd.size || googleAd.dimensions,
  },
  segmentation: {
    custID: segmentation ? segmentation.cust_id : '',
    Posiciones: segmentation ? segmentation.position : '',
    platform: segmentation ? segmentation.platform : '',
    businessUnit: segmentation ? segmentation.business_unit : '',
  },
  caption,
  viewportMargin: viewport_margin,
  personalDataAdsDenied: personal_data_ads_denied,
  ...ownProps,
  className,
  melidataEvent: get(track, 'melidata_event', null),
  userConsentCookie,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(BillboardAd);
