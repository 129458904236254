import componentEnhance from '../../../../lib/component-enhance';

export const getLabelElementSelector = (index, quantitySelector, hasOptions) => {
  if (hasOptions) {
    const {
      selector: {
        template: { options },
      },
    } = quantitySelector;
    return componentEnhance.jsx(options[index - 1].text, options[index - 1].values);
  }
  return quantitySelector.selector?.template[(index === 1 ? 'singular' : 'plural')]?.replace('{quantity}', index);
};
