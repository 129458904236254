import {
  FETCH_GET_UNITS_START,
  FETCH_GET_UNITS_COMPLETE,
  UPDATE_GET_UNITS_CURRENT_STATE,
  FETCH_GET_UNITS_ERROR,
} from '../../../../actions/vip/vis/types';
import { messagesErrorScreen } from '../../../../lib/get-message-error';

const fetchGetUnitsStart = state => ({
  ...state,
  multifamily_modal: {},
});

const fetchGetUnitsComplete = (state, action) => ({
  ...state,
  multifamily: {
    ...state.multifamily,
    ...action.payload,
  },
});

const updateGetUnitsCurrentState = (state, action) => ({
  ...state,
  multifamily: {
    ...state.multifamily,
    currentState: action.payload,
  },
});

const fetchGetUnitsError = state => {
  const { title, description } = messagesErrorScreen(state.siteId);
  const errorText = `${title} - ${description}`;
  return {
    ...state,
    multifamily: {},
    multifamily_modal: {
      message: {
        text: errorText,
        type: 'error',
      },
    },
  };
};

const multifamilyLTRMapper = {
  [FETCH_GET_UNITS_START]: fetchGetUnitsStart,
  [FETCH_GET_UNITS_COMPLETE]: fetchGetUnitsComplete,
  [UPDATE_GET_UNITS_CURRENT_STATE]: updateGetUnitsCurrentState,
  [FETCH_GET_UNITS_ERROR]: fetchGetUnitsError,
};

export { fetchGetUnitsStart, fetchGetUnitsComplete, updateGetUnitsCurrentState, fetchGetUnitsError };
export default multifamilyLTRMapper;
