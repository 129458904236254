import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Card, CardContent } from '@andes/card';
import ExtendedMenu from '@seo-frontend-components/extended-menu';

const namespace = 'ui-pdp-extended-menu';

const ExtendedMenuDesktop = ({ menu, title, seeLess, seeMore }) =>
  menu?.length > 0 && (
    <div className={namespace}>
      <Card paddingSize={24}>
        <CardContent>
          <ExtendedMenu title={title} menu={menu} seeLess={seeLess} seeMore={seeMore} />
        </CardContent>
      </Card>
    </div>
  );

ExtendedMenuDesktop.namespace = namespace;

ExtendedMenuDesktop.propTypes = {
  title: string.isRequired,
  menu: arrayOf(
    shape({
      title: string.isRequired,
      url: string,
      children: arrayOf(
        shape({
          text: string.isRequired,
          url: string.isRequired,
        }),
      ),
    }),
  ).isRequired,
  seeLess: string,
  seeMore: string,
};

ExtendedMenuDesktop.defaultProps = {
  classNames: '',
  menu: [],
  seeLess: '',
  seeMore: '',
};

export default ExtendedMenuDesktop;
