import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import * as actions from '../../../../actions/vip';

const FEATURE_NAME = 'price';

const mapStateToProps = ({ components: { [FEATURE_NAME]: price } }) => price;

const mapDispatchToProps = dispatch => ({
  showPaymentsSplitModal: ({ target }) => {
    dispatch(actions.showPaymentsSplitModal({ target }));
  },
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const price = Price => enhance(Price);

export default price;
