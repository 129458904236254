import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import classnames from 'classnames';
import StyledLabel from '../styled-label';
import componentEnhance from '../../lib/component-enhance';

const namespace = 'ui-pdp-list';

const List = ({ items, className }) => {
  const pairedItems = [];

  for (let i = 0; i < items.length; i += 2) {
    pairedItems.push([items[parseInt(i, 10)], items[parseInt(i + 1, 10)]]);
  }
  return (
    <div className={classnames(namespace, className)}>
      {pairedItems.map(([first, second]) => {
        if (!first && !second) {
          return null;
        }
        return (
          <ul key={`${first?.id}-${second?.id}`} className="ui-pdp-list__wrapper">
            {first && (
              <li className={`${namespace}__item`} key={first.id}>
                <StyledLabel
                  className={`${namespace}__text`}
                  key={first.text}
                  as="p"
                  {...first}
                  text={componentEnhance.jsx(first.text, first.values)}
                />
              </li>
            )}
            {second && (
              <li className={`${namespace}__item`} key={second.id}>
                <StyledLabel
                  className={`${namespace}__text`}
                  key={second.text}
                  as="p"
                  {...second}
                  text={componentEnhance.jsx(second.text, second.values)}
                />
              </li>
            )}
          </ul>
        );
      })}
    </div>
  );
};

List.propTypes = {
  className: string,
  items: arrayOf(
    shape({
      id: string,
      text: string.isRequired,
      values: shape({
        value_text: shape({
          text: string,
          color: string,
          font_size: string,
          font_family: string,
        }),
      }),
    }),
  ).isRequired,
};

List.defaultProps = {
  className: '',
};

export default List;
