import React, { useContext } from 'react';
import { node, string, bool, shape, number } from 'prop-types';
import classnames from 'classnames';
import useStickyScroll from './hooks/use-sticky';
import StaticPropsContext from '../context/static-props';

const namespace = 'ui-pdp--sticky-wrapper';

const StickyWrapper = ({ children, dynamicScroll, columnName, wrapperMargin }) => {
  const { isIE } = useContext(StaticPropsContext);
  const ref = useStickyScroll({ isIE, dynamicScroll, columnName, wrapperMargin });

  return (
    <>
      {dynamicScroll?.enabled ? (
        <div
          ref={ref}
          className={classnames(`${namespace}`, {
            [`${namespace}-${columnName}`]: columnName,
          })}
        >
          {children}
        </div>
      ) : (
        children
      )}
    </>
  );
};

StickyWrapper.propTypes = {
  children: node,
  dynamicScroll: shape({
    enabled: bool,
  }),
  columnName: string,
  wrapperMargin: number,
};

StickyWrapper.defaultProps = {
  children: null,
  dynamicScroll: null,
  columnName: null,
  wrapperMargin: 16,
};

export default StickyWrapper;
