import React, { useEffect, forwardRef } from 'react';
import classnames from 'classnames';
import customOptions from '../../../../../utils/quantity-custom-options';
import { namespace, quantityInputPropTypes } from './model';
import useQuantityInputError from '../../hooks/use-quantity-input-error';
import useQuantityValue from '../../hooks/use-quantity-input-value';
import QuantityInputLabels from './quantity-input-labels';
import QuantityInputField from './quantity-input-field';

const QuantityInput = forwardRef(
  (
    {
      confirm,
      length,
      min,
      listPickerRef = null,
      max = null,
      minErrorMessage,
      noStockMessage,
      setQuantity,
      bulkSale = false,
      quantity,
      subtitles = null,
      placeholder = null,
      title = null,
      mobile = false,
      selectorTemplate = {},
    },
    ref,
  ) => {
    const { custom_options } = selectorTemplate;
    const hasCustomOptions = customOptions.has(custom_options);
    const keyboardInputMode = bulkSale || hasCustomOptions ? 'decimal' : 'text';

    const [error, setError, validate] = useQuantityInputError(bulkSale, min, max, minErrorMessage, noStockMessage);
    const [value, setValue] = useQuantityValue(hasCustomOptions, custom_options, quantity, bulkSale, length);

    const quantityText = customOptions.getQuantityText(value, selectorTemplate);
    const amount = customOptions.getAmountByQuantity(custom_options, value);

    const onSubmit = e => {
      if (e) {
        e.persist();
      }

      if (validate(value)) {
        setQuantity(Number(value.replace(',', '.')), { trackSourceElement: 'input' });
      }
    };

    useEffect(() => {
      if (hasCustomOptions) {
        setValue(customOptions.verifyQuantity(custom_options, quantity));
        setError(null);
      }
    }, [quantity, hasCustomOptions, custom_options, setError, setValue]);

    useEffect(() => {
      if (error?.modifier === 'error' && bulkSale && !mobile && listPickerRef?.current) {
        listPickerRef.current.scrollTop = listPickerRef.current.scrollHeight;
      }
    }, [error, listPickerRef, mobile, bulkSale]);

    const onInputChange = e => {
      setError(null);

      if (bulkSale) {
        // eslint-disable-next-line security/detect-unsafe-regex
        const regex = /^\d+(?:[,.]\d{0,2})?$/;
        if (regex.test(e.target.value) || e.target.value === '') {
          setValue(e.target.value);
        }
      } else {
        setValue(e.target.value.replace(/[^0-9]/g, '').replace(/\b0+/g, ''));
      }
    };

    return (
      <div
        data-testid={hasCustomOptions ? 'input-container' : undefined}
        className={classnames(`${namespace}__input`, { [`${namespace}__input__custom-options`]: hasCustomOptions })}
      >
        <QuantityInputLabels subtitles={subtitles} bulkSale={bulkSale} hasCustomOptions={hasCustomOptions} />
        <QuantityInputField
          ref={ref}
          value={value}
          placeholder={placeholder}
          onInputChange={onInputChange}
          onSubmit={onSubmit}
          error={error}
          title={title}
          keyboardInputMode={keyboardInputMode}
          mobile={mobile}
          hasCustomOptions={hasCustomOptions}
          quantityText={quantityText}
          amount={amount}
          confirm={confirm}
          bulkSale={bulkSale}
        />
      </div>
    );
  },
);

QuantityInput.propTypes = quantityInputPropTypes;

export default QuantityInput;
