import {
  SHOW_RECAPTCHA,
  REMOVE_RECAPTCHA,
  FETCH_SELLER_INFO_PHONES,
  UPDATE_TOKEN_RESPONSE,
} from '../../../../actions/vip/vis/types';

const showRecaptcha = (state, action) => {
  const { recaptcha, component, recaptchaActionType } = action.payload;
  return {
    ...state,
    [component]: {
      ...state[component],
      recaptchaPublicKey: recaptcha.public_key,
      key: Math.random(),
      recaptchaActionType,
    },
  };
};

const removeRecaptcha = (state, action) => {
  const { component } = action.payload;
  return {
    ...state,
    [component]: {
      ...state[component],
      recaptchaPublicKey: null,
    },
  };
};

const fetchSellerInfoPhones = (state, action) => ({
  ...state,
  seller_profile: {
    ...state.components.seller_profile,
    phones: action.payload.phones,
  },
  seller_profile_rex: {
    ...state.components.seller_profile_rex,
    phones: action.payload.phones,
  },
});

const updateTokenResponse = (state, action) => {
  const { component, token } = action.payload;
  return {
    ...state,
    [component]: {
      ...state[component],
      responseToken: token,
    },
  };
};

const actionModalMapper = {
  [SHOW_RECAPTCHA]: showRecaptcha,
  [REMOVE_RECAPTCHA]: removeRecaptcha,
  [FETCH_SELLER_INFO_PHONES]: fetchSellerInfoPhones,
  [UPDATE_TOKEN_RESPONSE]: updateTokenResponse,
};

export { showRecaptcha, removeRecaptcha, fetchSellerInfoPhones, updateTokenResponse };
export default actionModalMapper;
