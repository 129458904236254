import snackbarMapper from './snackbar';
import fetchStatusMapper from './fetch-status';
import bookmarkMapper from './bookmark';
import quantityMapper from './quantity';
import shippingMapper from './shipping';
import variationsMapper from './variations';
import hiQuantityMapper from './hi-quantity';

const mainMappers = {
  ...snackbarMapper,
  ...fetchStatusMapper,
  ...bookmarkMapper,
  ...quantityMapper,
  ...hiQuantityMapper,
  ...shippingMapper,
  ...variationsMapper,
};

export default mainMappers;
