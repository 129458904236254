import React from 'react';
import useGalleryHandlers from '../hooks/use-gallery-handlers';
import GallerySlide from '../components/desktop/gallery.slide';
import { PROVIDER } from '../../figure/constants';
import { trackEvent } from '../../../lib/tracking';
import { constants } from '../../../utils/constants';

const { DEVICE_TYPE } = constants;

const getGallerySlide = ({
  deviceType,
  figures,
  zoomOptions,
  limit,
  namespace,
  clipsConfig,
  zoomRef,
  position,
  title,
  clipsRef,
  app,
  track,
  overlayAlpha,
  setModalVisibility,
  setCurrentIndex,
}) =>
  figures.map((figure, index) => {
    const { zoom, provider } = figure;
    const pictureZoomOptions = {
      ...zoomOptions,
      enableZoom: !!zoom && deviceType === DEVICE_TYPE.DESKTOP,
    };
    const isOutLimit = figures.length > limit && index + 1 === limit;
    const seeMoreAmount = isOutLimit ? figures.length - limit + 1 : 0;
    const keyGallery = `${namespace}-${index}`;
    const { handleFullScreen, handleClip } = useGalleryHandlers({
      clipsRef,
      setModalVisibility,
    });

    return (
      <GallerySlide
        key={keyGallery}
        autoplay={clipsConfig?.autoplay}
        index={index}
        container={zoomRef}
        zoomOptions={pictureZoomOptions}
        selected={position.current === index}
        title={title}
        onMouseHover={() => setCurrentIndex(index)}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            setCurrentIndex(index);
          }
        }}
        onClick={() => {
          if (provider === PROVIDER.CLIP) {
            handleClip();
          } else {
            setModalVisibility(true);
            if (track) {
              trackEvent(track);
            }
          }
        }}
        onMute={(...params) => clipsRef.current && clipsRef.current?.onMute(...params)}
        onFullscreen={handleFullScreen}
        showThumbnail={index < limit}
        seeMoreAmount={seeMoreAmount}
        provider={provider}
        figure={figure}
        overlayAlpha={overlayAlpha}
        app={app}
      />
    );
  });

export default getGallerySlide;
