import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import * as actions from '../../../../actions/vip';
import PricePerQuantityShortcuts from '../../../../components/price-per-quantity-shortcuts/price-per-quantity-shortcuts';

const FEATURE_NAME = 'price_per_quantity_shortcuts';

const mapStateToProps = ({ components: { [FEATURE_NAME]: price_per_quantity_shortcuts } }, { className }) => ({
  ...price_per_quantity_shortcuts,
  className,
});

const mapDispatchToProps = dispatch => ({
  onSetQuantity: quantity => dispatch(actions.fetchItemOnSetQuantity(quantity)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(PricePerQuantityShortcuts);
