import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import jsonToProps from './json-to-props';

const FEATURE_NAME = 'buy_benefits';

const mapStateToProps = ({ components: { [FEATURE_NAME]: buyBenefits } }) => jsonToProps(buyBenefits);

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

const buyBenefits = BuyBenefits => enhance(BuyBenefits);

export default buyBenefits;
