const requestQueue = {
  pendingRequest: null,
  isRequestInProgress: false,
};

const processRequest = callback => {
  requestQueue.isRequestInProgress = false;
  if (requestQueue.pendingRequest != null) {
    requestQueue.pendingRequest();
    requestQueue.pendingRequest = null;
  } else {
    callback();
  }
};

export { processRequest, requestQueue };
