import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import jsonToProps from '../generic-summary/payments-mapper';

const FEATURE_NAME = 'payment_summary';

const mapStateToProps = ({ components: { [FEATURE_NAME]: paymentSummary }, stylesSmall }) =>
  jsonToProps({ ...paymentSummary, stylesSmall });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

const paymentSummary = PaymentSummary => enhance(PaymentSummary);

export default paymentSummary;
