import { func, number, shape, string, arrayOf, bool } from 'prop-types';

export const namespace = 'ui-pdp-buybox__quantity';

export const quantityInputPropTypes = {
  confirm: string.isRequired,
  length: number.isRequired,
  listPickerRef: shape({ current: shape({}) }),
  min: number,
  max: number,
  bulkSale: bool,
  minErrorMessage: shape({
    text: string.isRequired,
    color: string.isRequired,
  }).isRequired,
  noStockMessage: shape({
    text: string.isRequired,
    color: string.isRequired,
  }).isRequired,
  quantity: number.isRequired,
  setQuantity: func.isRequired,
  subtitles: arrayOf(shape({ text: string.isRequired })),
  placeholder: string,
  title: string,
  mobile: bool,
  selectorTemplate: shape({
    custom_options: arrayOf(
      shape({
        type: string,
        quantity: number,
        display_value: shape({
          amount: shape({
            type: string,
            value: number,
            currency_id: string,
            decimal_style: string,
            suffix: shape({
              text: string,
              accessibility_text: string,
            }),
          }),
        }),
      }),
    ),
  }),
};

// QuantityInputButton
export const quantityInputButtonPropTypes = {
  confirm: string.isRequired,
  onSubmit: func.isRequired,
  error: string,
  value: string,
  bulkSale: bool,
  hasCustomOptions: bool,
};

// QuantityInputField
export const quantityInputFieldPropTypes = {
  ref: shape({}),
  value: string,
  placeholder: string,
  onInputChange: func.isRequired,
  onSubmit: func.isRequired,
  error: string,
  title: string,
  keyboardInputMode: string,
  mobile: bool,
  hasCustomOptions: bool,
  quantityText: string,
  amount: shape({}),
  confirm: string.isRequired,
  bulkSale: bool,
};

// QuantityInputLabels
export const quantityInputLabelsPropTypes = {
  subtitles: arrayOf({}),
  bulkSale: bool,
  hasCustomOptions: bool,
};
