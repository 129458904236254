import compose from '../../../../lib/compose';
import baseConnect from '../../../../store/base-connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';

const FEATURE_NAME = 'reviews';

const mapStateToProps = ({ components: { [FEATURE_NAME]: reviews }, id }, ownProps) => ({
  className: ownProps.className,
  key: id,
  title: reviews.title,
  emptySubtitle: { ...reviews.empty_reviews_subtitle },
  rating: {
    average: reviews.rating,
    label: reviews.subtitle,
    levels: reviews.rating_levels,
    stars: reviews.stars,
  },
  comments: {
    tabs: reviews.tabs,
    label: reviews.all_reviews && reviews.all_reviews.label,
  },
  allReviews: reviews.all_reviews,
  newReview: reviews.new_review,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  baseConnect(mapStateToProps),
);

const component = Component => enhance(Component);

export default component;
