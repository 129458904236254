import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';

const FEATURE_NAME = 'onboarding';

const mapStateToProps = ({ components: { [FEATURE_NAME]: onboarding } }) => ({ ...onboarding });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

const onboarding = Onboarding => enhance(Onboarding);

export default onboarding;
