import React from 'react';
import classNames from 'classnames';
import { namespace, quantitySelectedLabelPropTypes } from './model';

const QuantitySelectedLabel = ({ selectedLabel, description, showError = false }) => (
  <span
    className={classNames({
      [`${namespace}__selected`]: !showError,
      [`${namespace}__selected-error`]: showError,
    })}
  >
    {selectedLabel}
    {description}
  </span>
);

QuantitySelectedLabel.propTypes = quantitySelectedLabelPropTypes;

export default QuantitySelectedLabel;
