import React, { useContext } from 'react';
import { string, shape } from 'prop-types';
import { Iframe } from '@vpp-frontend-components/iframe';
import { ActionModalUI, withBusinessLogic } from '@vpp-frontend-components/action-modal';
import { Icon } from '@vpp-frontend-components/icon';
import BottomSheetWithClose from '../bottom-sheet-with-close/bottom-sheet-with-close';
import onIframeModalResize from '../../lib/on-iframe-modal-resize';
import StaticPropsContext from '../context/static-props';
import { getActionModalConfig } from './utils/getActionModalConfig';

const ActionModal = withBusinessLogic(ActionModalUI, getActionModalConfig);

const namespace = 'ui-pdp-gradings';

const ModalGradings = ({ label, target, track }) => {
  const { deviceType } = useContext(StaticPropsContext);
  const size = 'small';
  const btnProps = {
    size,
    hierarchy: 'transparent',
    text: label.text,
  };
  const ActionModalRef = React.useRef(null);

  const classNameContainer = `${namespace}-wrapper`;

  return deviceType === 'desktop' ? (
    <div className={classNameContainer}>
      <button type="button" className={`${namespace}-modal__button`} onClick={() => ActionModalRef.current.openModal()}>
        <span>{label.text}</span>
      </button>
      <ActionModal
        modalUrl="#gradings"
        url={target}
        modalClassName={`ui-pdp-iframe-modal ${namespace}-modal`}
        autoHeight={false}
        track={track}
        customRef={ActionModalRef}
      >
        <Iframe
          src={target}
          scrolling="no"
          data-testid="gradings-modal__iframe"
          onMessage={onIframeModalResize(
            'vip:modal-content:loaded',
            'height',
            '.ui-pdp-iframe-modal',
            '.andes-modal__header',
            'width',
            'deviceType',
            0,
          )}
        />
      </ActionModal>
    </div>
  ) : (
    <div className={classNameContainer}>
      <BottomSheetWithClose
        className={`${namespace}-bottom-sheet`}
        initialHeight="50%"
        labelValues={{ btnProps }}
        closeIconColor="black"
      >
        <Iframe
          src={target}
          data-testid="gradings-bottom-sheet__iframe"
          onMessage={onIframeModalResize(
            'vip:modal-content:loaded',
            'height',
            `.${namespace}-bottom-sheet`,
            '.andes-bottom-sheet__header',
            '',
            'deviceType',
            0,
          )}
        />
      </BottomSheetWithClose>
      <Icon id="chevron" className={`ui-pdp-icon--chevron-down ${namespace}-bottom-sheet__icon`} />
    </div>
  );
};

ModalGradings.propTypes = {
  label: shape({
    text: string.isRequired,
  }).isRequired,
  target: string.isRequired,
  track: shape({}).isRequired,
};

export default ModalGradings;
