import React from 'react';
import { string, shape, bool } from 'prop-types';
import { SizeChartDesktop } from 'apparel-sizechart-web-lib';
import classNames from 'classnames';
import ActionModal from '../action-modal';
import StyledLabel from '../styled-label';
import Iframe from '../iframe';
import onIframeModalResize from '../../lib/on-iframe-modal-resize';
import useTrackView from '../../hooks/use-track-view';

const namespace = 'ui-vpp-apparel-size-specs-preview';

const ApparelSizeSpecsPreview = ({
  state,
  header,
  action,
  chartPreviewData,
  sizeColumnName,
  isNewSizeChart,
  className,
  viewport_track,
}) => {
  const viewRef = useTrackView(viewport_track, { threshold: 0.45 });

  if (state === 'HIDDEN' && !action) {
    return null;
  }

  return (
    <div ref={viewRef} className={classNames(namespace, className)}>
      <h2 className={`${namespace}__title`}>{header}</h2>
      <div className={`${namespace}__table`}>
        <div className="sizechart">
          <SizeChartDesktop
            {...chartPreviewData}
            sizes={chartPreviewData.sizes || chartPreviewData.rows}
            sizeColumnName={sizeColumnName}
            isNew={isNewSizeChart}
          />
        </div>
      </div>
      <ActionModal
        className={`${namespace}__action-modal`}
        modalClassName="ui-pdp-iframe-modal"
        componentAsLabel={<StyledLabel as="span" className={`${namespace}__label`} {...action.label} />}
        url={action.target}
        modalTitle={header}
        closeModalLabel={action.close_modal_label}
      >
        <Iframe
          scrolling="no"
          src={action.target}
          title={action.label.text}
          data-testid="apparel-size-specs-preview__iframe"
          onMessage={onIframeModalResize(
            'vip:modal-content:loaded',
            'height',
            '.ui-pdp-iframe-modal',
            '.andes-modal__header',
          )}
        />
      </ActionModal>
    </div>
  );
};

ApparelSizeSpecsPreview.propTypes = {
  state: string.isRequired,
  action: shape({}),
  header: string.isRequired,
  chartPreviewData: shape().isRequired,
  sizeColumnName: string.isRequired,
  viewport_track: shape().isRequired,
  isNewSizeChart: bool,
  className: string,
};

ApparelSizeSpecsPreview.defaultProps = {
  action: null,
  isNewSizeChart: false,
  className: null,
};

export default ApparelSizeSpecsPreview;
