import React, { useContext } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import StripedSpecs from '../../../specs/striped-specs';
import StaticPropsContext from '../../../context/static-props';

const namespace = 'ui-vpp-highlighted-specs__striped-specs';

const HSStripedSpecs = ({ specs }) => {
  const { deviceType } = useContext(StaticPropsContext);

  const leftSpecs = specs.filter(({ column }) => column === 'LEFT');

  const rightSpecs = specs.filter(({ column }) => column === 'RIGHT');

  return (
    <div className={namespace}>
      {deviceType === 'desktop' ? (
        <>
          {leftSpecs && leftSpecs.length > 0 && <StripedSpecs specs={leftSpecs} />}
          {rightSpecs && rightSpecs.length > 0 && <StripedSpecs specs={rightSpecs} />}
        </>
      ) : (
        <StripedSpecs specs={specs} />
      )}
    </div>
  );
};

HSStripedSpecs.propTypes = {
  specs: arrayOf(shape({ column: string })).isRequired,
};

export default HSStripedSpecs;
