import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import jsonToProps from '../generic-summary/pickup-mapper';

const FEATURE_NAME = 'returns_summary';
const GENERIC_BANNER_SHIPPING = 'generic_banner_shipping';

const mapStateToProps = ({
  components: { [FEATURE_NAME]: returnsSummary, [GENERIC_BANNER_SHIPPING]: genericBannerShipping },
}) => ({ banner: genericBannerShipping, ...jsonToProps(returnsSummary) });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

const pickUpSummary = PickUpSummary => enhance(PickUpSummary);

export default pickUpSummary;
