import React from 'react';
import { number, string, func, shape, objectOf, bool } from 'prop-types';
import classnames from 'classnames';
import customPropTypes from '../utils/proptypes';
import ThumbnailWithTooltip from './thumbnail-tooltip';
import { DECORATION_BLOCKED } from '../utils/types';
import { onMouseEnter, onMouseLeave, handleOnSelectVariation, namespace } from '../utils';

const renderVariation = (
  product,
  picker,
  pickerLabelId,
  pickerSelectedSubtitleId,
  platform,
  onSelectVariation,
  selectedAttributes,
  selectedQuantity,
  hasDeferredErrors,
  setHovered,
  index,
) => (
  <>
    <ThumbnailWithTooltip
      className={`${namespace}--thumbnail`}
      data-testid="thumbnail-variarions-desktop"
      dataTestId="thumbnail-variarions-desktop"
      onMouseLeave={() =>
        onMouseLeave(
          pickerLabelId,
          pickerSelectedSubtitleId,
          picker.error_message && !picker.error_message.is_deferred ? null : picker.selected_option,
          picker.selected_subtitle,
          !!product.picture,
          setHovered,
        )
      }
      onMouseEnter={() =>
        onMouseEnter(
          pickerLabelId,
          pickerSelectedSubtitleId,
          product.label,
          product.selected_subtitle,
          product.picture?.src,
          product.picture?.src2x,
          setHovered,
        )
      }
      blocked={product.decoration_type === DECORATION_BLOCKED} // BLOCKED UNTIL "SIN STOCK" GOES CLICKEABLE
      modifier={product.decoration_type}
      modifierTooltip={product.disabled_text}
      label={product.label ? product.label.text : null}
      ariaLabel={
        product.label?.accessibility_text
          ? `${product.label.accessibility_text}, Boton ${index + 1} de ${picker.products.length}`
          : null
      }
      size={picker.products.length > 20 ? 'SMALL' : 'LARGE'}
      picture={
        product.picture
          ? {
              id: product?.picture.id,
              alt: product.label.text,
              src: product?.picture?.thumbnail?.src,
              src2x: product?.picture?.thumbnail?.src2x,
            }
          : null
      }
      href={product.permalink}
      title={product.label.text}
      subtitle={product.subtitle}
      price={product.price}
      onClick={e => {
        e.preventDefault();
        handleOnSelectVariation(
          product,
          selectedQuantity,
          selectedAttributes,
          picker.id,
          hasDeferredErrors,
          onSelectVariation,
          platform,
        );
      }}
      style={{ minWidth: picker.min_width, maxWidth: picker.max_width }}
    />
  </>
);

const ProductsPicker = ({
  picker,
  pickerLabelId,
  pickerSelectedSubtitleId,
  limitedVariations,
  platform,
  onSelectVariation,
  selectedQuantity,
  selected_attributes,
  hasDeferredErrors,
  setHovered,
}) => (
  <div
    className={classnames(`${namespace}__picker-default-container`, {
      [`${namespace}__picker-default-container-error`]: !!picker.error_message && !picker.error_message.is_deferred,
    })}
  >
    {picker.products
      .slice(0, limitedVariations?.is_collapsed ? limitedVariations.show_limit : picker.products.length)
      .map((product, index) => (
        <React.Fragment key={`${product.id}-${product?.label?.text}-${product?.picture?.id}`}>
          {renderVariation(
            product,
            picker,
            pickerLabelId,
            pickerSelectedSubtitleId,
            platform,
            onSelectVariation,
            selected_attributes,
            selectedQuantity,
            hasDeferredErrors,
            setHovered,
            index,
          )}
        </React.Fragment>
      ))}
  </div>
);

ProductsPicker.propTypes = {
  picker: customPropTypes.picker,
  pickerLabelId: string,
  pickerSelectedSubtitleId: string,
  platform: string,
  selectedQuantity: number,
  onSelectVariation: func.isRequired,
  limitedVariations: shape(),
  selected_attributes: objectOf(string),
  hasDeferredErrors: bool,
  setHovered: func,
};

export default ProductsPicker;
