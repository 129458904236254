import {
  FETCH_ON_COUPON_UPDATE_START,
  FETCH_ON_COUPON_UPDATE_COMPLETE,
  FETCH_ON_COUPON_UPDATE_ERROR,
} from '../../../../actions/vip/types';

const fetchOnCouponUpdateStart = state => ({
  ...state,
  components: {
    ...state.components,
    coupon_summary: {
      ...state.components.coupon_summary,
      isFetching: true,
    },
  },
});

const fetchOnCouponUpdateComplete = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    coupon_summary: {
      ...action.payload.components.coupon_summary,
      isFetching: false,
    },
  },
});

const fetchOnCouponUpdateError = state => ({
  ...state,
  components: {
    ...state.components,
    coupon_summary: {
      ...state.components.coupon_summary,
      isFetching: false,
    },
  },
});

const couponsMapper = {
  [FETCH_ON_COUPON_UPDATE_START]: fetchOnCouponUpdateStart,
  [FETCH_ON_COUPON_UPDATE_COMPLETE]: fetchOnCouponUpdateComplete,
  [FETCH_ON_COUPON_UPDATE_ERROR]: fetchOnCouponUpdateError,
};

export { fetchOnCouponUpdateStart, fetchOnCouponUpdateComplete, fetchOnCouponUpdateError };
export default couponsMapper;
