import {
  SET_COMPATIBILITIES_INPUT_VALUE,
  FETCH_COMPATIBILITIES_WITH_USER_SELECTION_START,
  FETCH_COMPATIBILITIES_WITH_USER_SELECTION_ERROR,
  FETCH_COMPATIBILITIES_WITH_USER_SELECTION_COMPLETE,
} from '../../../../actions/vip/types';

const setCompatibilitiesInputValue = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    compatibilities: {
      ...state.components.compatibilities,
      isFetchingFilter: false,
      successFilter: true,
      inputs: Array.from(state.components.compatibilities.inputs, input => {
        if (input.label.id === action.labelId) {
          input.selected_value = action.value;
        }
        return input;
      }),
    },
  },
});

const fetchCompatibilitiesWithUserSelectionStart = state => ({
  ...state,
  components: {
    ...state.components,
    compatibilities: {
      ...state.components.compatibilities,
      isFetching: true,
      success: null,
    },
  },
});

const fetchCompatibilitiesWithUserSelectionError = state => ({
  ...state,
  components: {
    ...state.components,
    compatibilities: {
      ...state.components.compatibilities,
      isFetching: false,
      success: false,
    },
  },
});

const fetchCompatibilitiesWithUserSelectionComplete = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    compatibilities: {
      ...state.components.compatibilities,
      isFetching: false,
      success: true,
      action: action.payload.action,
      message: null,
      modal: {
        ...state.components.compatibilities.modal,
        user_selection: action.payload.modal.user_selection,
        message: action.payload.modal.message,
        table: action.payload.modal.table,
      },
    },
  },
});

const compatibilitiesMapper = {
  [SET_COMPATIBILITIES_INPUT_VALUE]: setCompatibilitiesInputValue,
  [FETCH_COMPATIBILITIES_WITH_USER_SELECTION_START]: fetchCompatibilitiesWithUserSelectionStart,
  [FETCH_COMPATIBILITIES_WITH_USER_SELECTION_ERROR]: fetchCompatibilitiesWithUserSelectionError,
  [FETCH_COMPATIBILITIES_WITH_USER_SELECTION_COMPLETE]: fetchCompatibilitiesWithUserSelectionComplete,
};

export {
  setCompatibilitiesInputValue,
  fetchCompatibilitiesWithUserSelectionStart,
  fetchCompatibilitiesWithUserSelectionError,
  fetchCompatibilitiesWithUserSelectionComplete,
};
export default compatibilitiesMapper;
