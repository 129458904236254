import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import * as actions from '../../../../actions/vip';

const FEATURE_NAME = 'technical_specifications';

const mapStateToProps = ({ components: { [FEATURE_NAME]: technicalSpecifications } }) => technicalSpecifications;

const mapDispatchToProps = dispatch => ({
  onSubmitForm: (selectedInput, updateState, deviceType) =>
    new Promise(resolve => {
      resolve(
        dispatch(actions.getCompatsProduct({ selectedInput, updateState, feature_name: FEATURE_NAME, deviceType })),
      );
    }),
  onRemove: deviceType =>
    new Promise(resolve => {
      resolve(dispatch(actions.cleanFormCompats({ feature_name: FEATURE_NAME, deviceType })));
    }),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const component = Component => enhance(Component);

export default component;
