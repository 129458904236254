import classnames from 'classnames';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import * as actions from '../../../../actions/vip';

const FEATURE_NAME = 'compats_widget';

const mapStateToProps = ({ components: { [FEATURE_NAME]: compats_widget, header_store, header_top_brand_vip } }) => {
  const isTopBrand = header_top_brand_vip && header_top_brand_vip.state === 'VISIBLE';
  return {
    ...compats_widget?.widget_data,
    class_name: classnames(compats_widget?.widget_data?.class_name, 'ui-vip-compats--head'),
    isOfficialStore: !!header_store || isTopBrand,
    isWidgetSticky: compats_widget?.type === 'compats_widget_sticky',
  };
};

const mapDispatchToProps = dispatch => ({
  onSubmitForm: (selectedInput, updateState, deviceType) =>
    new Promise(resolve => {
      resolve(
        dispatch(
          actions.getCompatsProduct({ selectedInput, updateState, feature_name: FEATURE_NAME, deviceType }, 'sticky'),
        ),
      );
    }),
  onRemove: (deviceType, updateState) =>
    new Promise(resolve => {
      resolve(dispatch(actions.cleanFormCompats({ feature_name: FEATURE_NAME, deviceType, updateState })));
    }),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const component = Component => enhance(Component);

export default component;
