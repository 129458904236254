import {
  searchQADB,
  searchQADBClose,
  searchBlur,
  searchFocus,
  inputValue,
  makeQuestion,
  leaveFeedback,
  searchCallToActionFocus,
  closeHowToMakeQuestionTooltip,
  closeSmartAnswerModal,
  openQuestionsFromSmartAnswer,
} from '../actions/index';
import * as actions from '../../../actions';

const { showVariationsError } = actions;

const mapDispatchToProps = dispatch => ({
  showVariationsError: (deviceType, actionKey) => dispatch(showVariationsError(deviceType, actionKey)),
  onSearchQuery: ({ query, item_id, product_id }) => {
    dispatch(searchQADB({ query, item_id, product_id }));
  },

  onCancelSearchQuery: ({ item_id, product_id }) => {
    dispatch(searchQADBClose({ item_id, product_id }));
  },

  onSearchBlur: () => {
    dispatch(searchBlur());
  },

  onSearchFocus: () => {
    dispatch(searchFocus());
  },

  onChangeQuestion(question) {
    dispatch(inputValue({ question }));
  },

  onCreateQuestion: ({ item_id, question, failure, success, app = 'pdp', parameters, form }) => {
    dispatch(makeQuestion({ item_id, question, failure, success, app, parameters, form }));
  },

  onLeaveFeedback: track => {
    dispatch(leaveFeedback(track));
  },

  onClickHowToMakeQuestionButton: ({ tooltip }) => {
    dispatch(searchCallToActionFocus({ tooltip }));
  },

  onCloseHowToMakeQuestionTooltip: () => {
    dispatch(closeHowToMakeQuestionTooltip());
  },

  onCloseSmartAnswerModal: () => {
    dispatch(closeSmartAnswerModal());
  },

  onOpenQuestionsFromSmartAnswer: () => {
    dispatch(openQuestionsFromSmartAnswer());
  },
});

export default mapDispatchToProps;
