import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import * as actions from '../../../../actions/vip';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import transformActionTarget from '../../../../utils/transformActionTarget';

const FEATURE_NAME = 'questions';

const mapStateToProps = ({
  [FEATURE_NAME]: {
    id,
    form,
    question_message,
    quick_access,
    track,
    show_track,
    title,
    messages,
    disabled,
    url,
    stockModal,
    ...rest
  },
  baseUrl,
  id: itemId,
  attributes,
}) => {
  const incomingQuestionsModel = rest.questions;

  if (quick_access && quick_access.actions) {
    quick_access.actions.forEach(element => {
      transformActionTarget(element, { controlled: 'true' });
    });
  }

  const newUrl = `${baseUrl}/questions/${itemId}`.replace('/p/questions', '/noindex/questions');

  return {
    id,
    form,
    question_message,
    quick_access,
    title,
    track,
    show_track,
    messages,
    disabled,
    url: newUrl,
    stockModal,
    attributes,
    ...rest,
    ...incomingQuestionsModel,
  };
};

const mapDispatchToProps = dispatch => ({
  onCreateQuestion: ({ itemId, text, track }) => dispatch(actions.onCreateQuestion({ itemId, text, track })),
  redirectToLogin: (itemId, loginType, featureName) =>
    dispatch(actions.redirectToLogin({ itemId, loginType, featureName })),
  showQuestionsAiModal: ({ target, disabled, called_from, source }) =>
    dispatch(actions.showQuestionsAiModal({ target, disabled, called_from, source })),
  postQuestionFromAi: ({ productId, itemId, text, track, attributes, quantity, source }) =>
    dispatch(actions.postQuestionFromAi({ productId, itemId, text, track, attributes, quantity, source })),
  isUseful: ({ itemId, useful, id, response, track, source }) =>
    dispatch(actions.isUseful({ itemId, useful, id, response, track, source })),
  showSnackbar: ({ message, type, delay }) => dispatch(actions.showSnackbar({ message, type, delay })),
  onCreateQuestionFromAi: ({ text, snackbar_message, suggestionTrack, source }) => {
    dispatch(actions.onCreateQuestionFromAi({ text, snackbar_message, suggestionTrack, source }));
  },
  saveFrontendStatsd: data => dispatch(actions.saveFrontendStatsd(data)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME, { pathToComponent: FEATURE_NAME }),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const component = Component => enhance(Component);

export default component;
