import React, { useRef, useEffect } from 'react';
import { node, string, number } from 'prop-types';
import classnames from 'classnames';

const namespace = 'ui-pdp-modal-content-autoheight';
const headerSelector = '.andes-modal__header';

// @Todo: Refactor / unify logic with modal-content.js

const ModalContentAutoheight = ({ children, className, modalId, extra, viewportHeightRatio }) => {
  const ref = useRef();

  useEffect(() => {
    const contentHeight = ref.current.clientHeight;
    const container = document.querySelector(`.${modalId}`);
    let headerDiff = 0;
    if (container) {
      const viewportHeight = window.innerHeight;
      const header = container.querySelector(headerSelector);
      headerDiff = header ? header.clientHeight : 0;
      const availableHeight = viewportHeight * viewportHeightRatio - headerDiff;
      if (contentHeight < availableHeight) {
        container.style.height = `${contentHeight + headerDiff + extra}px`;
      }
    }
  }, [extra, modalId, ref, viewportHeightRatio]);

  return (
    <div className={classnames(namespace, className, `${namespace}__container`)} ref={ref}>
      {children}
    </div>
  );
};

ModalContentAutoheight.propTypes = {
  children: node,
  className: string,
  extra: number,
  modalId: string.isRequired,
  viewportHeightRatio: number,
};
ModalContentAutoheight.defaultProps = {
  children: null,
  className: null,
  extra: 10,
  viewportHeightRatio: 0.7,
};

export default ModalContentAutoheight;
