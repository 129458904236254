import {
  CREATE_QUOTATION_SHOW_PRICE,
  CREATE_QUOTATION_START,
  CREATE_QUOTATION_COMPLETE,
  CREATE_QUOTATION_ERROR,
} from '../../../../actions/vip/vis/types';

const createQuotationStart = state => ({
  ...state,
  quotation: {
    ...state.quotation,
    isPostingQuotation: true,
  },
  quotation_modal: {},
});

const createQuotationShowPrice = (state, action) => ({
  ...state,
  quotation: {
    ...state.quotation,
    isShowPrice: true,
  },
  quotation_modal: {
    message: {
      text:
        action.payload === 'congrats'
          ? state.quotation.snackbar.congrats.message
          : state.quotation.snackbar.show_price.message,
      type: 'success',
    },
  },
});

const createQuotationComplete = state => ({
  ...state,
  quotation: {
    ...state.quotation,
    isPostingQuotation: false,
  },
});

const createQuotationError = state => {
  const errorText = `Algo salió mal, por favor inténtalo de nuevo.`;
  return {
    ...state,
    quotation: {
      ...state.quotation,
      isPostingQuotation: false,
    },
    quotation_modal: {
      message: {
        text: errorText,
        type: 'error',
      },
    },
  };
};

const createReQuotationMapper = {
  [CREATE_QUOTATION_START]: createQuotationStart,
  [CREATE_QUOTATION_SHOW_PRICE]: createQuotationShowPrice,
  [CREATE_QUOTATION_COMPLETE]: createQuotationComplete,
  [CREATE_QUOTATION_ERROR]: createQuotationError,
};

export { createQuotationComplete, createQuotationError };
export { createQuotationStart, createQuotationShowPrice };
export default createReQuotationMapper;
