import React from 'react';
import { node, string, shape } from 'prop-types';
import { ImageProvider } from 'nordic/image';
import { I18nProvider } from 'nordic/i18n';
import getDisplayName from '../../lib/get-display-name';

const withProviders = (WrappedComponent, assetPrefix, i18n) => {
  const withProvidersComponent = props => (
    <ImageProvider prefix={assetPrefix}>
      <I18nProvider i18n={i18n}>
        <WrappedComponent {...props} />
      </I18nProvider>
    </ImageProvider>
  );

  withProvidersComponent.propTypes = {
    asset: shape({}),
  };

  withProvidersComponent.defaultProps = {
    asset: null,
  };

  withProvidersComponent.displayName = `withProvidersComponent(${getDisplayName(WrappedComponent)})`;

  return withProvidersComponent;
};

withProviders.propTypes = {
  WrappedComponent: node.isRequired,
  assetPrefix: string,
};

withProviders.defaultProps = {
  assetPrefix: null,
};

export default withProviders;
