import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';

const ICON_ID = 'poly_star';
const namespace = 'ui-pdp-icon ui-pdp-icon--star-empty';

const IconStarEmpty = ({ className }) => (
  <svg className={classnames(namespace, className)} width="10" height="10" viewBox="0 0 10 10" fill="none">
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconStarEmpty.propTypes = {
  className: string,
};

IconStarEmpty.defaultProps = {
  className: null,
};

IconStarEmpty.ICON_ID = ICON_ID;

export default React.memo(IconStarEmpty);
export { IconStarEmpty };
