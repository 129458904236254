const FETCH_START = 'FETCH_START';
const FETCH_ERROR = 'FETCH_ERROR';

/* Contact Info */
const FETCH_CONTACT_INFO_ERROR = 'FETCH_CONTACT_ERROR';
const FETCH_COMPONENT = 'FETCH_COMPONENT';
const RENDER_FRAUD_MODAL = 'RENDER_FRAUD_MODAL';
const SHOW_FRAUD_SNACKBAR = 'SHOW_FRAUD_SNACKBAR';

/* Question */
const CREATE_QUESTIONS_UNREGISTERED_START = 'CREATE_QUESTIONS_UNREGISTERED_START';
const CREATE_QUESTIONS_UNREGISTERED_COMPLETE = 'CREATE_QUESTIONS_UNREGISTERED_COMPLETE';
const CREATE_QUESTIONS_UNREGISTERED_ERROR = 'CREATE_QUESTIONS_UNREGISTERED_ERROR';
const QUESTIONS_UNREGISTERED_ON_CHANGE_VALUE = 'QUESTIONS_UNREGISTERED_ON_CHANGE_VALUE';
const QUESTIONS_UNREGISTERED_ERROR_FORM = 'QUESTIONS_UNREGISTERED_ERROR_FORM';
const FETCH_MODAL_QUESTIONS_START = 'FETCH_MODAL_QUESTIONS_START';
const FETCH_MODAL_QUESTIONS_COMPLETE = 'FETCH_MODAL_QUESTIONS_COMPLETE';
const FETCH_MODAL_QUESTIONS_ERROR = 'FETCH_MODAL_QUESTIONS_ERROR';
const FETCH_MODAL_UNREGISTERED_QUESTIONS_START = 'FETCH_MODAL_UNREGISTERED_QUESTIONS_START';
const FETCH_MODAL_UNREGISTERED_QUESTIONS_COMPLETE = 'FETCH_MODAL_UNREGISTERED_QUESTIONS_COMPLETE';
const FETCH_MODAL_UNREGISTERED_QUESTIONS_ERROR = 'FETCH_MODAL_UNREGISTERED_QUESTIONS_ERROR';
const QUESTIONS_UNREGISTERED_EDIT_DATASTORED = 'QUESTIONS_UNREGISTERED_EDIT_DATASTORED';

/* Budget Demand */
const CREATE_BUDGET_START = 'CREATE_BUDGET_START';
const CREATE_BUDGET_ERROR = 'CREATE_BUDGET_ERROR';

/* Modal */
const MODAL_REQUEST_START = 'MODAL_REQUEST_START';
const MODAL_REQUEST_CLOSE = 'MODAL_REQUEST_CLOSE';
const MODAL_REQUEST_RESIZING = 'MODAL_REQUEST_RESIZING';

const SHOW_RECAPTCHA = 'SHOW_RECAPTCHA';
const REMOVE_RECAPTCHA = 'REMOVE_RECAPTCHA';
const FETCH_SELLER_INFO_PHONES = 'FETCH_SELLER_INFO_PHONES';
const UPDATE_TOKEN_RESPONSE = 'UPDATE_TOKEN_RESPONSE';

/* Quote modal */
const FETCH_BUDGET_COMPONENTS = 'FETCH_BUDGET_COMPONENTS';

const SCROLL_TO_COMPONENT = 'SCROLL_TO_COMPONENT';

const TOGGLE_BOOKMARKED = 'TOGGLE_BOOKMARKED';

/* RE Quotation */
const FETCH_QUOTATION_START = 'FETCH_QUOTATION_START';
const FETCH_QUOTATION_COMPLETE = 'FETCH_QUOTATION_COMPLETE';
const FETCH_QUOTATION_PRICE = 'FETCH_QUOTATION_PRICE';
const FETCH_QUOTATION_ERROR = 'FETCH_QUOTATION_ERROR';
const UPDATE_QUOTATION_FORM = 'UPDATE_QUOTATION_FORM';
const CREATE_QUOTATION_START = 'CREATE_QUOTATION_START';
const CREATE_QUOTATION_SHOW_PRICE = 'CREATE_QUOTATION_SHOW_PRICE';
const CREATE_QUOTATION_COMPLETE = 'CREATE_QUOTATION_COMPLETE';
const CREATE_QUOTATION_ERROR = 'CREATE_QUOTATION_ERROR';
const UPDATE_QUOTATION_CURRENT_STATE = 'UPDATE_QUOTATION_CURRENT_STATE';
const QUOTATION_ERROR_EXCEEDS_LIMIT = 'QUOTATION_ERROR_EXCEEDS_LIMIT';

/* LTR Multifamily */
const FETCH_GET_UNITS_START = 'FETCH_GET_UNITS_START';
const FETCH_GET_UNITS_COMPLETE = 'FETCH_GET_UNITS_COMPLETE';
const FETCH_GET_UNITS_ERROR = 'FETCH_GET_UNITS_ERROR';
const UPDATE_GET_UNITS_CURRENT_STATE = 'UPDATE_GET_UNITS_CURRENT_STATE';

const FETCH_VEHICLE_HISTORY_MODAL = 'FETCH_VEHICLE_HISTORY_MODAL';

export {
  FETCH_START,
  FETCH_ERROR,
  FETCH_CONTACT_INFO_ERROR,
  FETCH_COMPONENT,
  CREATE_QUESTIONS_UNREGISTERED_START,
  CREATE_QUESTIONS_UNREGISTERED_COMPLETE,
  CREATE_QUESTIONS_UNREGISTERED_ERROR,
  QUESTIONS_UNREGISTERED_ON_CHANGE_VALUE,
  QUESTIONS_UNREGISTERED_ERROR_FORM,
  FETCH_MODAL_QUESTIONS_START,
  FETCH_MODAL_QUESTIONS_COMPLETE,
  FETCH_MODAL_QUESTIONS_ERROR,
  FETCH_MODAL_UNREGISTERED_QUESTIONS_START,
  FETCH_MODAL_UNREGISTERED_QUESTIONS_COMPLETE,
  FETCH_MODAL_UNREGISTERED_QUESTIONS_ERROR,
  RENDER_FRAUD_MODAL,
  QUESTIONS_UNREGISTERED_EDIT_DATASTORED,
  SHOW_RECAPTCHA,
  SCROLL_TO_COMPONENT,
  REMOVE_RECAPTCHA,
  FETCH_SELLER_INFO_PHONES,
  MODAL_REQUEST_START,
  MODAL_REQUEST_CLOSE,
  MODAL_REQUEST_RESIZING,
  SHOW_FRAUD_SNACKBAR,
  UPDATE_TOKEN_RESPONSE,
  FETCH_BUDGET_COMPONENTS,
  CREATE_BUDGET_START,
  CREATE_BUDGET_ERROR,
  TOGGLE_BOOKMARKED,
  FETCH_QUOTATION_START,
  FETCH_QUOTATION_COMPLETE,
  FETCH_QUOTATION_PRICE,
  FETCH_QUOTATION_ERROR,
  UPDATE_QUOTATION_FORM,
  CREATE_QUOTATION_START,
  CREATE_QUOTATION_SHOW_PRICE,
  CREATE_QUOTATION_COMPLETE,
  CREATE_QUOTATION_ERROR,
  UPDATE_QUOTATION_CURRENT_STATE,
  FETCH_GET_UNITS_START,
  FETCH_GET_UNITS_ERROR,
  UPDATE_GET_UNITS_CURRENT_STATE,
  FETCH_GET_UNITS_COMPLETE,
  FETCH_VEHICLE_HISTORY_MODAL,
  QUOTATION_ERROR_EXCEEDS_LIMIT,
};
