// This is for hardcode critical components
// We do this while backend add this to the response

// CORE
const VPP_CORE_CRITICAL_COMPONENTS = [
  // 'header',
  // 'price',
  // 'gallery',
  // 'fixed.gallery',
  // 'variations',
  // 'outside_variations',
  // 'buy_box_offers',
  // 'available_quantity',
  // 'main_actions',
];

// VERTICALS:
const MISC_CRITICAL_COMPONENTS = [];
// const RECOMMENDATIONS_CRITICAL_COMPONENTS = []; // Example

const criticalComponents = [
  ...VPP_CORE_CRITICAL_COMPONENTS,
  ...MISC_CRITICAL_COMPONENTS,
  //   ...RECOMMENDATIONS_CRITIC_COMPONENTS, // Example
];

export default criticalComponents;
