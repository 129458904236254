import React from 'react';
import { number, bool, string, shape, arrayOf } from 'prop-types';
import { ActionCollapsable } from '../../../action-collapsable';
import HSStripedSpecs from '../hs-striped-specs';
import updateComponentPositionWhenSticky from '../../../../utils/update-component-position-when-sticky/update-component-position-when-sticky';

const namespace = 'ui-vpp-highlighted-specs__striped-collapsed';

const HSActionCollapsible = ({ action, specs, maxCollapsed, showAction, collapse, forceShowAction }) => {
  if (!action) {
    return null;
  }

  const handleClick = () => {
    updateComponentPositionWhenSticky('center');
  };

  return (
    <ActionCollapsable
      className={`${namespace}__action`}
      collapseBy="pixels"
      maxCollapsed={maxCollapsed}
      action={action}
      collapse={collapse}
      scrollToTop={false}
      onClickAction={handleClick}
      showAction={showAction}
      allowRecollapse={!!collapse}
      useCanSeeEverything
      forceShowAction={forceShowAction}
    >
      <HSStripedSpecs specs={specs} />
    </ActionCollapsable>
  );
};

HSActionCollapsible.propTypes = {
  specs: arrayOf(shape({ column: string })).isRequired,
  action: shape({
    label: shape({
      text: string.isRequired,
      color: string,
    }).isRequired,
    target: string,
  }),
  collapse: shape({
    label: shape({
      text: string.isRequired,
      color: string,
    }).isRequired,
    target: string,
  }),
  maxCollapsed: number,
  showAction: bool,
  forceShowAction: bool,
};

HSActionCollapsible.defaultProps = {
  action: null,
  showAction: true,
  maxCollapsed: 1,
  forceShowAction: false,
};

export default HSActionCollapsible;
