import { loadable } from 'nordic/lazy';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';

const Promotions = loadable(() => import('../../../../components/promotions/index'));

const FEATURE_NAME = 'promotions';

const mapStateToProps = ({ components: { [FEATURE_NAME]: promotions } }) => promotions;

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(Promotions);
