import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import jsonToProps from '../../../../components/message/jsonToProps';

const FEATURE_NAME = 'returns_notification_up';

const mapStateToProps = ({ components: { [FEATURE_NAME]: returnsNotificationUp } }) => ({
  ...jsonToProps(returnsNotificationUp),
  className: FEATURE_NAME,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

const returnsNotificationUp = ReturnsNotificationUp => enhance(ReturnsNotificationUp);

export default returnsNotificationUp;
export { mapStateToProps };
