import React from 'react';
import { string } from 'prop-types';

const LikeFull = ({ className }) => (
  <svg
    aria-hidden
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M6.25811 5.07832L5.02406 5.54732L5.12152 13.4921L6.36114 13.4769L9.91485 14.0834C11.9108 14.424 13.8656 13.2921 14.5638 11.3915L15.5672 8.6645C16.1201 7.16179 14.9959 5.57117 13.3948 5.59081L10.0365 5.63201L10.656 3.73357C11.1722 2.15138 10.2446 0.462057 8.63253 0.0485436L6.25811 5.07832Z"
      fill="white"
    />
    <path d="M3.93633 14.7066L3.81121 4.50736L0.862071 4.54354L0.987192 14.7428L3.93633 14.7066Z" fill="white" />
  </svg>
);

LikeFull.propTypes = {
  className: string,
};

LikeFull.defaultProps = {
  className: null,
};

export default React.memo(LikeFull);
