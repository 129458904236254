import React, { useRef, useState } from 'react';
import { node, shape, string } from 'prop-types';
import classnames from 'classnames';
import Button from './components/button';

const namespace = 'ui-pdp-collapsable';

const ActionCollapsableCss = ({ action, children, className }) => {
  const ref = useRef();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const onClick = e => {
    e.preventDefault();
    setIsCollapsed(!isCollapsed);
    ref.current.scrollIntoView();
  };

  return (
    <div className={classnames(namespace, className, { [`${namespace}--is-collapsed`]: isCollapsed })}>
      <div
        className={classnames(`${namespace}__container`, { [`${namespace}__container-items-hidden`]: isCollapsed })}
        ref={ref}
      >
        {children}
      </div>
      {isCollapsed ? <Button action={action} namespace={namespace} onClick={onClick} /> : null}
    </div>
  );
};

ActionCollapsableCss.propTypes = {
  action: shape({
    label: shape({
      text: string.isRequired,
      color: string,
    }).isRequired,
    target: string,
  }).isRequired,
  children: node,
  className: string,
};

ActionCollapsableCss.defaultProps = {
  children: null,
  className: null,
};

export default ActionCollapsableCss;
