import { useState } from 'react';

const useQuantityInputError = (bulkSale, min, max, minErrorMessage, noStockMessage) => {
  const [error, setError] = useState(null);

  const validate = value => {
    const numericValue = Number(value.replace(',', '.'));
    if (max && numericValue > max) {
      setError({
        modifier: 'error',
        message: bulkSale ? noStockMessage.text.replace('&nbsp;', '\u00A0') : noStockMessage.text,
      });
      return false;
    }
    if (min && numericValue < min) {
      setError({
        modifier: 'error',
        message: bulkSale ? minErrorMessage.text.replace('&nbsp;', '\u00A0') : minErrorMessage.text,
      });
      return false;
    }
    setError(null);
    return true;
  };

  return [error, setError, validate];
};

export default useQuantityInputError;
