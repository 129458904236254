/**
 * Module dependencies
 */
import React from 'react';

import { StaticPropsConsumer } from './static-props';
import getDisplayName from '../../../lib/get-display-name';

/**
 * withStaticProps
 */
const withStaticProps = Component => {
  const WithStaticProps = props => (
    <StaticPropsConsumer>{staticProps => <Component {...props} {...staticProps} />}</StaticPropsConsumer>
  );
  WithStaticProps.displayName = `WithStaticProps(${getDisplayName(Component)})`;
  return WithStaticProps;
};

/**
 * Expose withStaticProps
 */
export default withStaticProps;
