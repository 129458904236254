export const INSTALATION_WIDGET_ID = 'installations_widget';
export const INSTALATION_ID = 'installations';
export const UPDATE_ACTION = 'UPDATE_ACTION';
export const INSTALLATION_SERVICE = 'instalation_service';
export const SHIPPING_ID_MESSAGE_MAP = 'shipping-agencies-maps-fe';
export const REMOVE_ACTION = 'REMOVE_ACTION';
export const HELP_ACTION = 'HELP_ACTION';
export const OPERATION_FILL = 'FILL';
export const OPERATION_REMOVE = 'REMOVE';
export const POSITION_BODY = 'BODY';
export const POSITION_HEADER = 'HEADER';
export const ICON = 'ICON';
export const LABEL = 'LABEL';
export const COOKIE_REFERER = 'compats-referer';
