import React from 'react';
import { string, number, shape, arrayOf, bool, func } from 'prop-types';
import classnames from 'classnames';
import SellerStore from '../../seller-info-label/components/seller-store';
import Reputation from '../../seller-info-label/components/reputation';

import IconFactory from '../../icons/factory-builder';
import colornames from '../../../lib/colornames';
import useTrackView from '../../../hooks/use-track-view';
import StatusInfo from './status-info';
import StatusInfoVis from './status-info-vis';
import { generateKeyToComponent } from '../../../utils/validators';

const namespace = 'ui-pdp-seller';

const Container = ({
  className,
  title,
  logo,
  subtitles,
  sellerStatusInfo,
  thermometer,
  extra_info,
  showLabelDto,
  fetchContactInfo,
  statusInfoVisProps,
  viewport_track,
  icon,
  isExclusiveOfficialStore,
}) => {
  const viewRef = useTrackView(viewport_track);

  return (
    <div ref={viewRef} className={className}>
      {title && (
        <SellerStore
          className={classnames({
            [`${namespace}__header--margin`]:
              (sellerStatusInfo && sellerStatusInfo.length > 0) || thermometer || (extra_info && extra_info.length > 0),
          })}
          titleValue={title}
          logo={logo}
          subtitles={subtitles}
          showLabelDto={showLabelDto}
          sellerName={title}
          icon={icon}
          iconOnTitle={isExclusiveOfficialStore}
        />
      )}
      {sellerStatusInfo &&
        sellerStatusInfo.map(info => {
          const isLocation = info.icon.id === 'LOCATION';
          return (
            <StatusInfo
              icon={IconFactory(info.icon)}
              iconClassName={classnames(colornames(info.icon))}
              title={info.title.text}
              titleClassName={classnames({ [`${namespace}__status-title`]: !isLocation })}
              subtitle={info.subtitle.text}
              key={info.title.text}
            />
          );
        })}
      {thermometer && <Reputation thermometer={thermometer} />}
      {extra_info && extra_info.length > 0 && (
        <ul className={`${namespace}__list-extra-info`}>
          {extra_info.map(info => (
            <StatusInfoVis
              key={generateKeyToComponent(info)}
              icon={IconFactory(info.icon)}
              iconClassName={classnames(colornames(info.icon), 'ui-seller-info__location-pin')}
              title={info.title}
              titleClassName={`${namespace}__title`}
              subtitle={info.subtitle}
              actionLink={info.action_dto}
              fetchContactInfo={fetchContactInfo}
              {...statusInfoVisProps}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

Container.propTypes = {
  className: string,
  icon: string,
  title: string,
  logo: string,
  sellerStatusInfo: arrayOf(
    shape({
      icon: shape({ id: string, color: string }),
      subtitle: shape({
        text: string,
      }),
      title: shape({
        text: string,
      }),
    }),
  ),
  thermometer: shape({
    rank: number,
    info: arrayOf(
      shape({
        icon: shape({ id: string }),
        subtitle: string,
        title: string,
      }),
    ),
    defaultInfo: shape({
      subtitle: shape({
        color: string,
        text: string,
      }),
      title: shape({
        color: string,
        text: string,
      }),
    }),
  }),
  subtitles: arrayOf(
    shape({
      text: string,
      color: string,
      weight: string,
      size: string,
    }),
  ),
  extra_info: arrayOf(
    shape({
      icon: shape({ id: string, color: string }),
      subtitle: string,
      title: string,
    }),
  ),
  isExclusiveOfficialStore: bool,
  showLabelDto: bool,
  fetchContactInfo: func,
  statusInfoVisProps: shape({}),
  viewport_track: shape({
    melidata_event: shape({
      path: string,
      event_data: shape({}),
    }),
  }),
};

Container.defaultProps = {
  className: null,
  title: null,
  logo: null,
  subtitles: null,
  sellerStatusInfo: null,
  extra_info: null,
  showLabelDto: null,
  thermometer: null,
  fetchContactInfo: null,
  statusInfoVisProps: null,
  viewport_track: null,
};

export default Container;
