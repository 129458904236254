import React from 'react';
import PropTypes from 'prop-types';

/**
 * @typedef {Object} GiftRegistry
 * @property {string} id
 */

/**
 * @typedef {Object} WithVisibilityProps
 * @property {GiftRegistry} gift_registry
 */

/**
 * @template P
 * @param {React.ComponentType<P>} Component
 * @returns {React.ComponentType<P>}
 */
const withVisibility = Component => {
  const WrappedComponent = props => {
    const { gift_registry } = props;

    if (!gift_registry || !gift_registry?.id) {
      return null;
    }
    return <Component {...props} />;
  };

  WrappedComponent.propTypes = {
    gift_registry: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  };

  return WrappedComponent;
};

export default withVisibility;
