import { number, string } from 'prop-types';

export const namespace = 'ui-pdp-buybox__quantity';

export const quantityNativePropTypes = {
  className: string,
  label: string.isRequired,
  message: string,
  quantity: number.isRequired,
};
