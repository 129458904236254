const galleryMapper = ({ accessibility_text, picture_config = {}, pictures = [] } = {}) => {
  const template = picture_config?.template;
  const id = pictures[0]?.id;

  if (!template || !id) {
    return null;
  }

  return {
    accessibility_text,
    picture: { template, id },
  };
};

export default galleryMapper;
