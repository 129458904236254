import { TOGGLE_FOLLOW_OPTIMISTICALLY, FETCH_ON_SET_FOLLOW_COMPLETE } from '../../../../actions/vip/types';

const toggleFollowOptimistically = state => {
  const sellerDataComponents = Array.from(state.components.seller_data.components);
  const indexItem = sellerDataComponents.findIndex(el => el.id === 'seller_header');
  const newCtaStatus =
    sellerDataComponents[indexItem].followers.cta_status === 'stop_follow' ? 'do_follow' : 'stop_follow';
  sellerDataComponents[indexItem] = {
    ...sellerDataComponents[indexItem],
    followers: {
      ...sellerDataComponents[indexItem].followers,
      cta_status: newCtaStatus,
      isFetching: true,
    },
  };

  return {
    ...state,
    components: {
      ...state.components,
      seller_data: {
        ...state.components.seller_data,
        components: sellerDataComponents,
      },
    },
  };
};

const fetchOnSetFollowComplete = (state, action) => {
  const sellerDataComponents = Array.from(state.components.seller_data.components);
  const indexItem = sellerDataComponents.findIndex(el => el.id === 'seller_header');
  let newCtaStatus = sellerDataComponents[indexItem].followers.cta_status;

  if (action.payload && action.payload.status !== 'SUCCESS') {
    newCtaStatus = newCtaStatus === 'stop_follow' ? 'do_follow' : 'stop_follow';
  }

  sellerDataComponents[indexItem] = {
    ...sellerDataComponents[indexItem],
    followers: {
      ...sellerDataComponents[indexItem].followers,
      cta_status: newCtaStatus,
      isFetching: false,
    },
  };

  return {
    ...state,
    components: {
      ...state.components,
      seller_data: {
        ...state.components.seller_data,
        components: sellerDataComponents,
      },
    },
  };
};

const followersMapper = {
  [TOGGLE_FOLLOW_OPTIMISTICALLY]: toggleFollowOptimistically,
  [FETCH_ON_SET_FOLLOW_COMPLETE]: fetchOnSetFollowComplete,
};

export { toggleFollowOptimistically, fetchOnSetFollowComplete };
export default followersMapper;
