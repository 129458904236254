import {
  CREATE_QUESTIONS_UNREGISTERED_START,
  CREATE_QUESTIONS_UNREGISTERED_COMPLETE,
  CREATE_QUESTIONS_UNREGISTERED_ERROR,
} from '../../../../actions/vip/vis/types';
import { messagesErrorScreen } from '../../../../lib/get-message-error';

const createQuestionsUnregisteredStart = (state, action) => {
  const { component, form } = action;
  return {
    ...state,
    [component]: {
      ...state[component],
      form,
      messages: null,
      disabled: true,
      isFetching: true,
    },
  };
};

const createQuestionsUnregisteredComplete = (state, action) => {
  if (state.unregistered_questions) {
    delete state.unregistered_questions.recaptchaPublicKey;
  }
  const { component } = action;
  let newState = {};
  if (component === 'unregistered_questions_modal') {
    newState = {
      ...state,
      [component]: {
        ...state[component],
        disabled: false,
        form: {
          ...action.payload.unregistered_questions.form,
          question: {
            ...action.payload.unregistered_questions.form.question,
            value: action.payload.success === true ? '' : action.payload.unregistered_questions.form.question.value,
          },
        },
        isFetching: false,
        empty_state: action.payload.model ? action.payload.model.empty_state : null,
        ...action.payload.model,
      },
      unregistered_questions: {
        ...state.unregistered_questions,
        messages: {
          ...action.payload.display_message,
          type: action.payload.success === true ? 'success' : 'error',
        },
        key: Math.random(),
      },
    };
  } else {
    newState = {
      ...state,
      [component]: {
        ...state[component],
        disabled: false,
        form: {
          ...action.payload.unregistered_questions.form,
          question: {
            ...action.payload.unregistered_questions.form.question,
            value: action.payload.success === true ? '' : action.payload.unregistered_questions.form.question.value,
          },
        },
        messages: {
          ...action.payload.display_message,
          type: action.payload.success === true ? 'success' : 'error',
        },
        isFetching: false,
        empty_state: action.payload.model ? action.payload.model.empty_state : null,
        key: Math.random(),
        ...action.payload.model,
      },
    };
  }
  return newState;
};

const createQuestionsUnregisteredError = (state, action) => {
  const { title, description } = messagesErrorScreen(state.siteId);
  const errorText = `${title} - ${description}`;
  const { component } = action;
  let newState = {};
  if (component === 'unregistered_questions_modal') {
    newState = {
      ...state,
      [component]: {
        ...state[component],
        errorBackupForm: action.form,
        disabled: false,
        isFetching: false,
      },
      unregistered_questions: {
        ...state.unregistered_questions,
        messages: {
          text: errorText,
          type: 'error',
        },
        key: Math.random(),
      },
    };
  } else {
    newState = {
      ...state,
      [component]: {
        ...state[component],
        errorBackupForm: action.form,
        messages: {
          text: errorText,
          type: 'error',
        },
        key: Math.random(),
        disabled: false,
        isFetching: false,
      },
    };
  }
  return newState;
};

const questionsMapper = {
  [CREATE_QUESTIONS_UNREGISTERED_START]: createQuestionsUnregisteredStart,
  [CREATE_QUESTIONS_UNREGISTERED_COMPLETE]: createQuestionsUnregisteredComplete,
  [CREATE_QUESTIONS_UNREGISTERED_ERROR]: createQuestionsUnregisteredError,
};

export { createQuestionsUnregisteredStart };
export { createQuestionsUnregisteredError };
export { createQuestionsUnregisteredComplete };
export default questionsMapper;
