import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import RelatedSearches from '../../../../components/related-searches';
import jsonToProps from '../../../../components/related-searches/related-searches-mapper';

const FEATURE_NAME = 'related_searches';

const mapStateToProps = ({ components: { [FEATURE_NAME]: relatedSearches } }) => jsonToProps(relatedSearches);

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(RelatedSearches);
