import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import * as actions from '../../../../actions/vip/vis';

const FEATURE_NAME = 'header';

const mapStateToProps = ({ components: { [FEATURE_NAME]: header }, id, history }, ownProps) => ({
  ...header,
  ...ownProps,
  reviewsIframeId: history && history.parent_product_id ? history.parent_product_id : id,
});

const mapDispatchToProps = dispatch => ({
  onScrollComponent: ({ component, focus }) => dispatch(actions.onScrollComponent({ component, focus })),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const component = Component => enhance(Component);

export default component;
