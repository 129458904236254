/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { number, string, func, objectOf, bool } from 'prop-types';
import debounce from 'lodash/debounce';
import classnames from 'classnames';
import { namespace } from '../utils';
import customPropTypes from '../utils/proptypes';
import ApparelSizeSpecsComponents from './apparel-size-specs-components';
import PickerTitle from './picker-title';
import ProductsPicker from './products-picker';
import SeeMoreVariations from './see-more-variations';
import ModalGradings from '../../grading-button-item/modal-gradings';

const DefaultPicker = ({
  picker,
  pickerLabelId,
  pickerSelectedSubtitleId,
  platform,
  onSelectVariation,
  selectedQuantity,
  setPickersCollapse,
  selected_attributes,
  hasDeferredErrors,
}) => {
  const limitedVariations = picker.display_info;
  const [isHovered, setIsHovered] = React.useState(false);
  const setHovered = React.useCallback(debounce(setIsHovered, 200), []);
  return (
    <div
      key={picker.id}
      className={classnames(`${namespace}__picker`, {
        [`${namespace}__picker-size-specs-chart`]: picker.size_specs_chart,
      })}
      data-testid="picker-container"
    >
      <PickerTitle {...{ picker, pickerLabelId, pickerSelectedSubtitleId, isHovered }} />
      <ProductsPicker
        {...{
          picker,
          pickerLabelId,
          pickerSelectedSubtitleId,
          limitedVariations,
          platform,
          onSelectVariation,
          selectedQuantity,
          selected_attributes,
          hasDeferredErrors,
          setHovered,
        }}
      />

      {!!limitedVariations?.is_collapsed && (
        <SeeMoreVariations {...{ picker, limitedVariations, setPickersCollapse }} />
      )}

      {picker.apparel_size_specs && <ApparelSizeSpecsComponents picker={picker} />}

      {picker.grading_button_item && (
        <ModalGradings
          label={picker.grading_button_item.action.label}
          target={picker.grading_button_item.action.target}
          track={picker.grading_button_item.action.track}
        />
      )}
    </div>
  );
};

DefaultPicker.propTypes = {
  picker: customPropTypes.picker,
  pickerLabelId: string,
  pickerSelectedSubtitleId: string,
  platform: string,
  selectedQuantity: number,
  onSelectVariation: func.isRequired,
  setPickersCollapse: func,
  selected_attributes: objectOf(string),
  hasDeferredErrors: bool,
};

export default DefaultPicker;
