import React from 'react';
import Textfield from '@andes/textfield';
import classnames from 'classnames';
import { namespace, quantityNativePropTypes } from './model';

const QuantityNative = ({ label, message, quantity, className }) => (
  <div className={classnames(`${namespace}__lowend`, className)}>
    <Textfield
      className={`${namespace}__lowend__input`}
      message={message}
      messageFixed
      name="quantity"
      label={label}
      labelFixed
      defaultValue={quantity}
      autoComplete="off"
      type="number"
      pattern="[0-9]*"
    />
  </div>
);

QuantityNative.propTypes = quantityNativePropTypes;

export default React.memo(QuantityNative);
