import APIService from '../../../services/api';

import {
  FETCH_ERROR,
  LIKE_REVIEW_OPTIMISTICALLY,
  LIKE_REVIEW_ROLLBACK,
  DISLIKE_REVIEW_OPTIMISTICALLY,
  DISLIKE_REVIEW_ROLLBACK,
} from './actions';

const likeReview = reviewId => dispatch => {
  dispatch({
    type: LIKE_REVIEW_OPTIMISTICALLY,
    payload: {
      reviewId,
    },
  });

  APIService.likeReview(reviewId)
    .then(payload => payload)
    .catch(e => {
      try {
        dispatch({ type: FETCH_ERROR, error: { message: e.response.data.displayMessage } });
      } catch (eMessage) {
        dispatch({ type: FETCH_ERROR, error: { message: 'Algo salió mal' } });
      }
      dispatch({ type: LIKE_REVIEW_ROLLBACK, payload: { reviewId } });
    });
};

const dislikeReview = reviewId => dispatch => {
  dispatch({
    type: DISLIKE_REVIEW_OPTIMISTICALLY,
    payload: {
      reviewId,
    },
  });

  APIService.dislikeReview(reviewId)
    .then(payload => payload)
    .catch(e => {
      try {
        dispatch({ type: FETCH_ERROR, error: { message: e.response.data.displayMessage } });
      } catch (eMessage) {
        dispatch({ type: FETCH_ERROR, error: { message: 'Algo salió mal' } });
      }
      dispatch({ type: DISLIKE_REVIEW_ROLLBACK, payload: { reviewId } });
    });
};

export { likeReview, dislikeReview };
