import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import SellYourItem from '../../../../components/sell-your-item/sell-your-item';

const FEATURE_NAME = 'sell_your_item';
const SHARE_FEATURE_NAME = 'share';

const mapStateToProps = ({ components: { [FEATURE_NAME]: syi, [SHARE_FEATURE_NAME]: share } }, ownProps) => ({
  action: syi.action,
  shouldShowSeparator: !!share && share.state === 'VISIBLE',
  ...ownProps,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME, { loader: false }),
  connect(mapStateToProps),
);

export default enhance(SellYourItem);
