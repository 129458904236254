import { SHOW_VARIATIONS_ERROR_DESKTOP, SHOW_VARIATIONS_ERROR_MOBILE } from '../../../../actions/vip/types';
import findActionSelected from '../../../helpers/main-actions';
import { hasVariationsPickers, hasAnyPickers, hasVisibleVariations } from '../../../helpers/components';

const showVariantionsErrorDesktop = state => {
  if (!hasVariationsPickers(state)) {
    return state;
  }
  return {
    ...state,
    components: {
      ...state.components,
      variations: {
        ...state.components.variations,
        pickers: state.components.variations.pickers.map(picker => {
          if (!picker.error_message) {
            return picker;
          }
          return {
            ...picker,
            error_message: {
              ...picker.error_message,
              is_deferred: picker.error_message.is_deferred ? null : picker.error_message.is_deferred,
            },
          };
        }),
      },
    },
  };
};

const showVariantionsErrorMobile = (state, action) => {
  const variationsComponent = hasVisibleVariations(state);
  if (!hasAnyPickers(state, variationsComponent)) {
    return state;
  }
  return {
    ...state,
    components: {
      ...state.components,
      [variationsComponent]: {
        ...state.components[variationsComponent],
        pickers: state.components[variationsComponent].pickers.map(picker => {
          if (!picker.error_message) {
            return picker;
          }
          return {
            ...picker,
            error_message: {
              ...picker.error_message,
              is_deferred: picker.error_message.is_deferred ? null : picker.error_message.is_deferred,
            },
          };
        }),
        requestKey: Math.random(),
        actionSelected: findActionSelected(state, action.payload.actionKey),
      },
    },
  };
};

const variationsMapper = {
  [SHOW_VARIATIONS_ERROR_DESKTOP]: showVariantionsErrorDesktop,
  [SHOW_VARIATIONS_ERROR_MOBILE]: showVariantionsErrorMobile,
};

export { showVariantionsErrorDesktop, showVariantionsErrorMobile };
export default variationsMapper;
