import React from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { Button } from '@andes/button';
import debounce from 'lodash/debounce';
import componentEnhance from '../../../lib/component-enhance';
import StaticPropsContext from '../../context/static-props';

import StyledLabel from '../../styled-label';
import withCardTooltip from '../../card-tooltip/with-card-tooltip';
import withTooltip from '../../tooltip/with-tooltip';
import CounterButton from '../counter-button/component';
import { getLabelPosition, updateCartCallback } from './utils';

const { useCallback, useContext, useEffect, useRef, useState } = React;

const ACTION = {
  ADD: 'add',
  REMOVE: 'remove',
};

const onBlur = () => {
  const decrementButton = document.getElementById('decrement-button');
  const incrementButton = document.getElementById('increment-button');

  if (decrementButton) {
    decrementButton.blur();
  }
  if (incrementButton) {
    incrementButton.blur();
  }
};

const namespace = 'ui-pdp-cart-actions';

const CartActions = ({
  quantity_selector,
  add_track,
  remove_track,
  target,
  should_raise_errors,
  onUpdateQuantity,
  showVariationsError,
}) => {
  const {
    label,
    style_blocked,
    quantity,
    minimum_quantity,
    available_quantity,
    cart_request_delay,
    cart_request_initial_delay,
    accessibility = {},
  } = quantity_selector;

  const { variation_id, item_id } = target;
  const { accessibility_text_add, accessibility_text_remove, accessibility_text_selector_content } = accessibility;

  const { deviceType } = useContext(StaticPropsContext);
  const [count, setCount] = useState(quantity);
  const isDisabled = style_blocked;
  const hasOptions = quantity_selector.options && quantity_selector.options.length > 0;
  /*
    Es necesario guardar estos datos mediante un ref (que permite persistir estos durante el ciclo de
    vida completo del componente) ya que estos se utilizan dentro de una función (updateCart) memoizada
    con un useCallback y no se pueden pasar como dependencias ya que dicha funcion esta contenida dentro de
    un debounce y la recreacion de esta función provoca efectos adversos en el debounce.
    Ej: si se crea un debounce por cada render, va a generar que el debounce se ejecute antes de tiempo.
  */
  const mutableData = useRef({ quantity, prevQuantity: quantity, add_track, remove_track, target });

  const quantityLabels = [];
  let genericQuantityLabel = '';
  let labelStyles = {};

  useEffect(() => {
    const { current } = mutableData;
    // Se actualiza la información del item cuando se cambia de variación
    if (
      (variation_id && variation_id !== current.target.variation_id) ||
      (item_id && item_id !== current.target.item_id)
    ) {
      setCount(quantity);
      current.quantity = quantity;
      current.prevQuantity = quantity;
      current.target = target;
      current.add_track = add_track;
      current.remove_track = remove_track;
    }
  }, [quantity, variation_id, item_id, target, add_track, remove_track]);

  // Recibo la info para mostrar pesables
  if (hasOptions) {
    const { options } = quantity_selector;
    const {
      label: { text, values, ...rest },
    } = options[0];
    labelStyles = { ...rest };

    options.forEach(option => {
      const { label: labelOption } = option;
      quantityLabels.push(componentEnhance.jsx(labelOption.text, labelOption.values));
    });
  } else {
    // Muestro texto generico
    const { text, values, ...rest } = quantity_selector.value_label_template;
    labelStyles = { ...rest };

    values.quantity.text = count.toString();
    genericQuantityLabel = componentEnhance.jsx(text, values);
  }

  const hasOptionLabel = newQuantity => {
    if (hasOptions) {
      return getLabelPosition(quantity_selector.options, newQuantity) !== -1;
    }
    return false;
  };

  const onSuccessCallback = track => {
    const { current } = mutableData;
    current.prevQuantity = current.quantity;
    updateCartCallback(current.quantity, track);
    onBlur();
  };

  const onErrorCallback = () => {
    const { current } = mutableData;
    setCount(current.prevQuantity);
    current.quantity = current.prevQuantity;
    onBlur();
  };

  // Función memoizada contenida dentro del debounce
  const updateCart = () => {
    const { current } = mutableData;

    const action = current.prevQuantity < current.quantity ? ACTION.ADD : ACTION.REMOVE;
    const track = action === ACTION.ADD ? current.add_track : current.remove_track;

    onUpdateQuantity({
      action,
      quantity: current.quantity,
      target: current.target,
      onSuccess: () => onSuccessCallback(track),
      onError: onErrorCallback,
    });
  };

  const updateCartFromZero = () => {
    if (mutableData.current.quantity === 1) {
      updateCart();
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const asyncDebouncedUpdateCart = useCallback(debounce(updateCart, cart_request_delay), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const asyncDebouncedUpdateCartFromZero = useCallback(debounce(updateCartFromZero, cart_request_initial_delay), []);

  const updateQuantity = newQuantity => {
    setCount(newQuantity);
    mutableData.current.quantity = newQuantity;

    if (!cart_request_delay || !cart_request_initial_delay || (hasOptions && !hasOptionLabel(newQuantity))) {
      updateCart();
    } else if (count === 0 && newQuantity === 1) {
      asyncDebouncedUpdateCartFromZero();
    } else {
      asyncDebouncedUpdateCart();
    }
  };

  const onHandleIncrement = e => {
    e.preventDefault();
    if (should_raise_errors) {
      showVariationsError(deviceType);
    } else if (!isDisabled) {
      const parsedCount = parseInt(count, 10) + 1;
      const quantityPlusOne = parsedCount <= minimum_quantity ? minimum_quantity : parsedCount;
      updateQuantity(quantityPlusOne);
    }
  };

  const onHandleDecrement = e => {
    e.preventDefault();
    if (!isDisabled) {
      const parsedCount = parseInt(count, 10) - 1;
      const quantityMinusOne = parsedCount >= minimum_quantity ? parsedCount : 0;
      updateQuantity(quantityMinusOne);
    }
  };

  const quantityLabel = hasOptions
    ? quantityLabels[getLabelPosition(quantity_selector.options, count)]
    : genericQuantityLabel;

  return (
    <div className={namespace}>
      {count < 1 ? (
        <Button onClick={onHandleIncrement} fullWidth disabled={isDisabled} data-testid="action-button-increment">
          {label}
        </Button>
      ) : (
        <CounterButton
          label={<StyledLabel as="p" text={quantityLabel} {...labelStyles} />}
          value={count}
          maxValue={available_quantity}
          decrementProps={{
            'aria-label': accessibility_text_remove,
            onClick: onHandleDecrement,
            id: 'decrement-button',
          }}
          incrementProps={{
            'aria-label': accessibility_text_add,
            onClick: onHandleIncrement,
            id: 'increment-button',
          }}
          aria-label={accessibility_text_selector_content}
        />
      )}
    </div>
  );
};

CartActions.propTypes = {
  quantity_selector: shape({
    label: string,
    value_label_template: shape({
      text: string,
      color: string,
      font_family: string,
      values: shape(),
    }),
    style_blocked: bool,
    quantity: number,
    minimum_quantity: number,
    available_quantity: number,
    cart_request_delay: number,
    cart_request_initial_delay: number,
    options: arrayOf(
      shape({
        quantity: number,
        label: shape({
          text: string,
          color: string,
          font_family: string,
          values: shape(),
        }),
      }),
    ),
    accessibility: shape({
      accessibility_text_add: string,
      accessibility_text_remove: string,
      accessibility_text_selector_content: string,
    }),
  }),
  add_track: shape({
    melidata_event: shape({}).isRequired,
    analytics_event: shape({}).isRequired,
  }).isRequired,
  remove_track: shape({
    melidata_event: shape({}).isRequired,
    analytics_event: shape({}).isRequired,
  }).isRequired,
  target: shape({
    item_id: string,
    product_id: string,
    variation_id: string,
    category_id: string,
  }),
  should_raise_errors: bool,
  onUpdateQuantity: func.isRequired,
  showVariationsError: func,
};

CartActions.defaultProps = {
  target: null,
  should_raise_errors: false,
  showVariationsError: null,
};

export default React.memo(
  withCardTooltip(withTooltip(CartActions, `${namespace}__tooltip`), `${namespace}__card-tooltip`),
);
