import { loadable } from 'nordic/lazy';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';

const CardTooltip = loadable(() => import('../../../../components/card-tooltip'));

const FEATURE_NAME = 'loyalty_tooltip';
const LOYALTY_TOOLTIP = 'loyalty-tooltip';

const TOOLTIP_NAMES = [
  'loyalty_first_tooltip',
  'loyalty_change_level_1_tooltip',
  'loyalty_change_level_2_tooltip',
  'loyalty_change_level_3_tooltip',
  'loyalty_change_level_4_tooltip',
  'loyalty_change_level_5_tooltip',
  'loyalty_change_level_6_tooltip',
];

const mapStateToProps = ({ components: { [FEATURE_NAME]: loyaltyTooltip } }) => loyaltyTooltip;

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(LOYALTY_TOOLTIP),
  connect(mapStateToProps),
);
const LOYALTY_TOOLTIP_NAMES = TOOLTIP_NAMES;

export default enhance(CardTooltip);
export { LOYALTY_TOOLTIP_NAMES };
