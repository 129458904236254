import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';

const FEATURE_NAME = 'payment_methods';

const mapStateToProps = ({ components: { [FEATURE_NAME]: paymentMethods }, stylesMedium }) => ({
  ...paymentMethods,
  stylesMedium,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

const paymentMethods = PaymentMethods => enhance(PaymentMethods);

export default paymentMethods;
