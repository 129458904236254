const jsonToProps = ({
  id,
  app,
  share,
  bookmark,
  wishlist,
  showOnlyForWishlistVariant = false,
  publishMyVehicleButton,
}) => ({
  id,
  app,
  share: share
    ? {
        action: share.action,
        permalink: share.permalink,
        title: share.share_title,
        shareActions: share.share_actions,
      }
    : null,
  bookmark: bookmark
    ? {
        isBookmarked: bookmark.is_bookmarked,
        showLabel: true,
        href: `${id}/bookmark?method=${bookmark.isBookmarked ? 'remove' : 'add'}&item_id=${id}`,
        remove: bookmark.delete,
        add: bookmark.add,
        isFetching: bookmark.isFetching,
        label: bookmark.label,
      }
    : null,
  wishlist: wishlist
    ? {
        label: wishlist.action?.label?.text,
        target: wishlist.action?.target,
        show: wishlist.show,
        title: wishlist?.title,
        loading_text: wishlist?.action?.loading_text,
        wishlistVariantVisible: wishlist.state === 'VISIBLE',
        gift_registry: wishlist?.gift_registry || null,
        saveInListTrack: wishlist?.action?.track,
        track: wishlist?.track,
      }
    : null,
  showOnlyForWishlistVariant,
  publishMyVehicleButton: publishMyVehicleButton
    ? {
        publishMyVehicleButtonVariantVisible: publishMyVehicleButton.state === 'VISIBLE',
      }
    : null,
});

export default jsonToProps;
