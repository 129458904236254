import React from 'react';
import { string } from 'prop-types';

const ICON_ID = 'like_full';

const LikeFull = ({ className }) => (
  <svg
    data-testid="like-svg"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

LikeFull.propTypes = {
  className: string,
};

LikeFull.defaultProps = {
  className: null,
};

export default React.memo(LikeFull);
export { ICON_ID };
