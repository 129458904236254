import React from 'react';
import { namespace } from '../utils';
import customPropTypes from '../utils/proptypes';
import FitAsExpected from '../../apparel-size-specs/fit_as_expected.desktop';
import ApparelSizeSpecs from '../../apparel-size-specs/apparel-size-specs.desktop';

const ApparelSizeSpecsComponents = ({ picker }) => (
  <div className={`${namespace}__picker-size-spec-sizechart-fit`}>
    {picker.apparel_size_specs.size_chart && (
      <ApparelSizeSpecs size_chart={picker.apparel_size_specs.size_chart} state="SHOW" inPicker />
    )}
    {picker.apparel_size_specs.fit_as_expected_action && (
      <FitAsExpected
        fit_as_expected={picker.apparel_size_specs.fit_as_expected_action}
        reviews_attribute_summary={picker.apparel_size_specs.reviews_attribute_summary}
        reviews_attribute_title={picker.apparel_size_specs.reviews_attribute_title}
      />
    )}
  </div>
);

ApparelSizeSpecsComponents.propTypes = {
  picker: customPropTypes.picker,
};

export default ApparelSizeSpecsComponents;
