import { useEffect } from 'react';
import { dispatchSellerPixelsInfo, getFormattedTrackingData } from '../utils/mercadoShopsTracking';

const useMShopsTrackingInfo = (trackingData, variationId) => {
  useEffect(() => {
    if (trackingData && window.tracking_mediator) {
      const shopsTrackingData = getFormattedTrackingData(trackingData, variationId);
      dispatchSellerPixelsInfo(window.tracking_mediator, shopsTrackingData);
    }
  }, [trackingData, variationId]);
};

export default useMShopsTrackingInfo;
