const getPictureConfig = (product, options) => {
  const { alt } = options || {};
  return product.picture
    ? {
        alt: alt ?? product.label?.text,
        id: product.picture.id,
        src: product.picture.thumbnail?.src,
        src2x: product.picture.thumbnail?.src2x,
      }
    : null;
};

export default getPictureConfig;
