import React from 'react';
import { shape, string } from 'prop-types';
import componentEnhance from '../../../../lib/component-enhance';
import StyledLabel from '../../../styled-label';
import HSFilledIcon from '../hs-filled-icon';

const namespace = 'ui-vpp-highlighted-specs__key-value';

const HSKeyValue = ({ title, picture }) => (
  <div className={namespace}>
    <HSFilledIcon
      picture={{
        bg_color: 'GRAY_40',
        bg_type: 'ROUNDED',
        url: picture.url,
      }}
    />
    <div className={`${namespace}__labels`}>
      {title && (
        <StyledLabel
          text={componentEnhance.jsx(title.text, title.values)}
          className={`${namespace}__labels__key-value`}
        />
      )}
    </div>
  </div>
);

HSKeyValue.propTypes = {
  title: shape({
    color: string,
    text: string,
    values: shape({}),
  }),
  picture: shape({
    url: shape({}),
  }).isRequired,
};

HSKeyValue.defaultProps = {
  title: null,
};

export default HSKeyValue;
