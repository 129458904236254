const mapJsonToProps = ({
  message_type,
  body,
  title,
  closeable,
  hierarchy,
  bg_color,
  className,
  state,
  primary_action,
}) => ({
  type: message_type,
  text: body?.text ?? '',
  title: title?.text ?? '',
  hierarchy,
  closeable,
  bgColor: bg_color,
  className: `ui-vpp-message ${className || ''}`,
  state,
  primary_action,
});

export default mapJsonToProps;
