import React from 'react';
import { string } from 'prop-types';
import CompatsHighlightNotes from './compats-highlight-card';
import CompatsHighlight from './compats-highlight';

const namespace = 'ui-compats-highlight';

const CompatsHighlightWrapper = props => {
  const { type } = props;

  switch (type) {
    case 'highlight_notes':
      return <CompatsHighlightNotes classname={namespace} {...props} />;
    case 'composed_highlight':
      return <CompatsHighlight classname={namespace} {...props} />;
    default:
      return null;
  }
};

CompatsHighlightWrapper.propTypes = {
  type: string,
};

CompatsHighlightWrapper.defaultProps = {
  type: null,
};

export default CompatsHighlightWrapper;
