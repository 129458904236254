import { useState, useEffect, useRef } from 'react';
import useIntersectionObserver from './use-intersection-observer';
import { trackEvent, trackEventOnComponentPrint } from '../lib/tracking';

const DEFAULT_OPTIONS = { threshold: 0.5 };

/**
 * Custom hook to track a component using Intersection Observer.
 * @param {Object} track - Element to be tracked using custom lib for vpp-frontend
 * @param {Object} options - Custom configuration for each component
 * @param {string} options.threshold - Percentage of the component that should be in viewport to consider it as "seen"
 * @param {Boolean} as_component_print - Determine if the track go to component_prints insted track
 */
const useTrackView = (track, options) => {
  const trackMethod = options && options.as_component_print ? trackEventOnComponentPrint : trackEvent;

  const [isTracked, setIsTracked] = useState(false);
  const refView = useRef();
  const [isIntersecting] = useIntersectionObserver(refView, { ...DEFAULT_OPTIONS, ...options });

  useEffect(() => {
    if (isIntersecting && !isTracked) {
      trackMethod(track);
      setIsTracked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntersecting, isTracked, track]);
  return refView;
};

export default useTrackView;
