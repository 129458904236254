import React from 'react';
import { string, arrayOf, shape } from 'prop-types';

// Highlighted Specs Components
import Grid from '../../../grid';

import HSContinousBar from '../hs-continous-bar';
import HSKeyValue from '../hs-key-value';
import { generateKeyToComponent } from '../../../../utils/validators';

const HSAttributes = ({ namespace, dataList }) => (
  <div className={`${namespace}__attribute-columns__column`}>
    {dataList.map(component => (
      <Grid.Row key={generateKeyToComponent(component)} className={`${namespace}__attribute-columns__row`}>
        {component.type === 'continuous_bar' ? <HSContinousBar {...component} /> : null}
        {component.type === 'key_value' ? <HSKeyValue {...component} /> : null}
      </Grid.Row>
    ))}
  </div>
);

HSAttributes.propTypes = {
  namespace: string,
  dataList: arrayOf(shape()).isRequired,
};

export default HSAttributes;
