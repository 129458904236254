import { string, shape, number } from 'prop-types';

export const quantityInputCustomLabelPropTypes = {
  label: string.isRequired,
  amount: shape({
    type: string,
    value: number,
    currency_id: string,
    decimal_style: string,
    suffix: shape({
      text: string,
      accessibility_text: string,
    }),
  }).isRequired,
  namespace: string.isRequired,
};
