import { constants } from '../../../utils/constants';

const { LAYOUT } = constants;

/* istanbul ignore next */
const setZoomLeftPosition = ({ left, layout }) => {
  switch (layout) {
    case LAYOUT.UPP:
    case LAYOUT.PDP:
    case LAYOUT.SHOPS_PDP:
      return left + 460;
    case LAYOUT.VIP_CORE:
      return left + 784;
    case LAYOUT.SHOPS_VIP_CORE:
      return left + 800;
    case LAYOUT.VIP_REAL_ESTATE:
    case LAYOUT.VIP_SERVICES:
    case LAYOUT.QUOTATION:
    case LAYOUT.PORTAL_INMOBILIARIO:
      return left + 768;
    case LAYOUT.VIP_MOTORS:
    default:
      return left + 764;
  }
};

export default setZoomLeftPosition;
