import { loadable } from 'nordic/lazy';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';

const CardTooltip = loadable(() => import('../../../../components/card-tooltip'));

const FEATURE_NAME = 'shipping_cart_benefits_tooltip';

const mapStateToProps = ({ components: { [FEATURE_NAME]: shippingCartBenefitsTooltip } }) =>
  shippingCartBenefitsTooltip;

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(CardTooltip);
