import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import jsonToProps from './json-to-props';

const FEATURE_NAME = 'extended_menu';

const mapStateToProps = ({ components: { [FEATURE_NAME]: props } }) => jsonToProps(props);

const enhance = compose(connect(mapStateToProps));

const component = Component => enhance(Component);

export default component;
