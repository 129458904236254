import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import EditYourItem from '../../../../components/edit-your-item/edit-your-item';

const FEATURE_NAME = 'edit_your_item';

const mapStateToProps = ({ components: { [FEATURE_NAME]: eyi } }, ownProps) => ({
  action: eyi.action,
  ...ownProps,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME, { loader: false }),
  connect(mapStateToProps),
);

export default enhance(EditYourItem);
