import React from 'react';
import { string, arrayOf, shape } from 'prop-types';
import StyledLabel from '../styled-label';
import componentEnhance from '../../lib/component-enhance';

const namespace = 'ui-pdp-questions';

const NoQuestions = ({ empty_state, className }) => (
  <div className={className}>
    {empty_state.map(label => (
      <StyledLabel
        as="p"
        key={label.text}
        className={`${namespace}__no-questions--text`}
        {...label}
        text={componentEnhance.jsx(label.text, label.values)}
      />
    ))}
  </div>
);

NoQuestions.propTypes = {
  empty_state: arrayOf(shape({ text: string, color: string, font_family: string, font_size: string })).isRequired,
  className: string,
};

NoQuestions.defaultProps = {
  className: null,
};

export default NoQuestions;
