const LIKE_REVIEW_OPTIMISTICALLY = 'LIKE_REVIEW_OPTIMISTICALLY';
const LIKE_REVIEW_ROLLBACK = 'LIKE_REVIEW_ROLLBACK';
const DISLIKE_REVIEW_OPTIMISTICALLY = 'DISLIKE_REVIEW_OPTIMISTICALLY';
const DISLIKE_REVIEW_ROLLBACK = 'DISLIKE_REVIEW_ROLLBACK';
const FETCH_ERROR = 'FETCH_ERROR';

export {
  LIKE_REVIEW_OPTIMISTICALLY,
  LIKE_REVIEW_ROLLBACK,
  DISLIKE_REVIEW_OPTIMISTICALLY,
  DISLIKE_REVIEW_ROLLBACK,
  FETCH_ERROR,
};
