import React from 'react';
import { Button } from '@andes/button';
import classnames from 'classnames';
import { namespace, quantityInputButtonPropTypes } from './model';

const QuantityInputButton = ({
  confirm,
  onSubmit,
  error = null,
  value = '',
  bulkSale = false,
  hasCustomOptions = false,
  ...props
}) => (
  <Button
    data-testid="quantity-input-button"
    className={classnames({
      [`${namespace}__button`]: !bulkSale,
      [`${namespace}__button-bulk`]: bulkSale,
      [`${namespace}__button-custom-options`]: hasCustomOptions,
    })}
    onClick={onSubmit}
    disabled={!!error || value === ''}
    {...props}
  >
    {confirm}
  </Button>
);

QuantityInputButton.propTypes = quantityInputButtonPropTypes;

export default QuantityInputButton;
