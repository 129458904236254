/* eslint-disable no-undef */
const setNewRelicMutationAttribute = () => {
  if (
    typeof newrelic !== 'undefined' &&
    typeof newrelic.setCustomAttribute === 'function' &&
    window.__PRELOADED_STATE__.initialState.hydrate === 'defer'
  ) {
    newrelic.setCustomAttribute('mutation', true);
  }
};

export default setNewRelicMutationAttribute;
