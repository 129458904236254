import { FETCH_ON_SHIPPING_UPDATE_COMPLETE, FETCH_ON_SHIPPING_UPDATE_START } from '../../../../actions/vip/types';

const fetchOnShippingUpdateComplete = (state, action) => ({
  ...state,
  ...action.payload,
  isFetching: false,
});

const fetchOnShippingUpdateStart = (state, action) => ({
  ...state,
  isFetching: true,
  error: null,
  selectedShippingOptionId: action.shippingOptionId,
});

const shippingMapper = {
  [FETCH_ON_SHIPPING_UPDATE_COMPLETE]: fetchOnShippingUpdateComplete,
  [FETCH_ON_SHIPPING_UPDATE_START]: fetchOnShippingUpdateStart,
};

export { fetchOnShippingUpdateComplete, fetchOnShippingUpdateStart };
export default shippingMapper;
