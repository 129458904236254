import React from 'react';
import { shape, string } from 'prop-types';
import classnames from 'classnames';
import colornames from '../../../lib/colornames';
import { setLabel, namespace } from '../utils';
import IconFactory from '../../icons/factory-builder';

const ErrorMessage = ({ error, pickerId }) => {
  const errorMessageRef = React.useRef(null);

  React.useEffect(() => {
    if (errorMessageRef.current) {
      errorMessageRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, []);

  setLabel(pickerId, null);
  return (
    <span
      ref={errorMessageRef}
      data-testid="picker-error-message"
      aria-live="assertive"
      className={classnames(`${namespace}__alert`, colornames(error.color))}
    >
      {error.text_icon && IconFactory(error.text_icon.id, colornames(error.text_icon.color))}
      <span className={`${namespace}__alert-text`}>{error.text}</span>
    </span>
  );
};

ErrorMessage.propTypes = {
  error: shape({
    color: string,
    text_icon: shape({
      id: string,
      color: string,
    }),
    text: string,
  }).isRequired,
  pickerId: string,
};

export default ErrorMessage;
