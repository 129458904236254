const DECORATION_NONE = 'NONE';
const DECORATION_BLOCKED = 'BLOCKED';
const DECORATION_SELECTED = 'SELECTED';
const PICKER_TYPE = {
  GRADING: 'GRADING',
  COLOR: 'COLOR',
  INTERNAL_MEMORY: 'INTERNAL_MEMORY',
};

export { DECORATION_NONE, DECORATION_BLOCKED, DECORATION_SELECTED, PICKER_TYPE };
