const PRE_STEPS = {
  PRELOGIN: 'prelogin',
  SHIELD: 'unregisteredShield',
  ACCESS: 'unregisteredAccess',
};

const IS_POSTING = 'isPosting';

const QUOTATION_ERRORS = {
  PRECONDITION_FAILED: 'precondition_failed',
  QUOTATION_LIMIT_REACHED: 'quotation_limit_reached',
};

export { PRE_STEPS, IS_POSTING, QUOTATION_ERRORS };
