import transformActionTarget from '../../../../utils/transformActionTarget';

// @TODO: Reuse this mapper in PDP Benefits feature: app/pages/vpp/features/benefits/index.js
const jsonToProps = props => {
  props.benefits.forEach(benefit => {
    if (benefit.values && benefit.values.action && benefit.values.action.type === 'modal') {
      transformActionTarget(benefit.values.action, { controlled: 'true' });
    }
  });

  return props;
};

export default jsonToProps;
