import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import * as actions from '../../../../actions/vip';

const FEATURE_NAME = 'available_quantity';
const FEATURE_NAME_LAST = 'last_available_quantity';

const mapStateToProps = ({
  components: { [FEATURE_NAME]: availableQuantity, [FEATURE_NAME_LAST]: lastAvailableQuantity },
}) => lastAvailableQuantity || availableQuantity;

const mapDispatchToProps = dispatch => ({
  onSetQuantity: quantity => dispatch(actions.fetchItemOnSetQuantity(quantity)),
  onShowInput: show => dispatch(actions.onQuantityShowInput(show)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const availableQuantity = AvailableQuantity => enhance(AvailableQuantity);

export default availableQuantity;
