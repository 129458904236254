const updateComponentPositionWhenSticky = name => {
  const stickyWrapper = document.querySelector(`.ui-pdp--sticky-wrapper-${name}`);
  if (stickyWrapper) {
    stickyWrapper.style.top = '10px';
    stickyWrapper.style.marginTop = '0px';
    stickyWrapper.style.transform = 'translateY(0px)';
  }
};

export default updateComponentPositionWhenSticky;
