import React from 'react';
import { arrayOf, bool, shape, string, number, oneOfType } from 'prop-types';
import HotjarComponent from 'nordic/hotjar';

const Hotjar = ({ id, tags, tagPrefix, enabled, triggers, extraData, traffic }) => {
  const hotjarConfig = {
    id,
    tags: tags || [],
    tagPrefix,
    enabled,
    triggers,
    extraData,
  };

  if (!hotjarConfig.id || !hotjarConfig.enabled) {
    return null;
  }

  let triggersConfig = hotjarConfig.triggers.length ? hotjarConfig.triggers : [];
  triggersConfig =
    hotjarConfig.extraData && hotjarConfig.extraData.triggers
      ? triggers.concat(hotjarConfig.extraData.triggers)
      : triggers;

  let tagsConfig = hotjarConfig.tags.length ? hotjarConfig.tags : [];
  tagsConfig =
    hotjarConfig.extraData && hotjarConfig.extraData.triggers ? tags.concat(hotjarConfig.extraData.triggers) : tags;

  const isValidTraffic = (lowerBound, upperBound) => {
    try {
      const lower = parseInt(lowerBound, 10);
      const upper = parseInt(upperBound, 10);

      if (lower < 0 || lower > 99 || upper < 0 || upper > 99) {
        return false;
      }

      if (lower > upper) {
        return false;
      }

      return true;
    } catch (e) {
      return false;
    }
  };

  const trafficConfig = traffic && isValidTraffic(traffic.from, traffic.to) ? traffic : { from: 0, to: 10 };

  const hotjarConfigId = Number.parseInt(hotjarConfig.id, 10);

  return <HotjarComponent id={hotjarConfigId} tags={tagsConfig} triggers={triggersConfig} traffic={trafficConfig} />;
};

Hotjar.propTypes = {
  enabled: bool,
  id: oneOfType([string, number]),
  tagPrefix: string,
  tags: arrayOf(string),
  triggers: arrayOf(string),
  extraData: shape({ triggers: arrayOf(string) }),
  traffic: shape({ from: number, to: number }),
};

Hotjar.defaultProps = {
  enabled: true,
  id: undefined,
  tagPrefix: '',
  tags: [],
  triggers: [],
  extraData: null,
  traffic: null,
};

export default Hotjar;
