import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Table, TableBody, TableRow, TableData, TableHeader } from '@andes/table';
import classnames from 'classnames';

const namespace = 'ui-vpp-striped-specs';

const StripedSpecs = ({ className, specs }) => (
  <div className={classnames(namespace, className)}>
    {specs.map(({ title, attributes }) => (
      <div key={`striped-spec-${title}`} className={`${namespace}__table`}>
        {title && <h3 className={`${namespace}__header`}>{title}</h3>}
        <Table>
          <TableBody>
            {attributes.map(attribute => (
              <TableRow className={`${namespace}__row`} selected={false} key={attribute.id}>
                <TableHeader
                  className={classnames(`${namespace}__row__column`, `${namespace}__row__column--id`)}
                  scope="row"
                >
                  {attribute.id}
                </TableHeader>
                <TableData className={`${namespace}__row__column`}>{attribute.text}</TableData>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    ))}
  </div>
);

StripedSpecs.propTypes = {
  className: string,
  specs: arrayOf(
    shape({
      title: string.isRequired,
      attributes: arrayOf(
        shape({
          id: string,
          text: string.isRequired,
        }),
      ),
    }),
  ).isRequired,
};

StripedSpecs.defaultProps = {
  className: '',
};

export default StripedSpecs;
