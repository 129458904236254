import React from 'react';
import { string } from 'prop-types';
import Script from 'nordic/script';

const isReady = () =>
  typeof window !== 'undefined' &&
  typeof window.grecaptcha !== 'undefined' &&
  typeof window.grecaptcha.enterprise !== 'undefined';

const Markup = ({ lang, siteKey }) =>
  !isReady() && (
    <Script>{`
        var recaptchaScript = document.createElement('script');
        recaptchaScript.src = 'https://www.google.com/recaptcha/enterprise.js?hl=${lang}&render=${siteKey}';
        document.head.appendChild(recaptchaScript);
      `}</Script>
  );

Markup.propTypes = {
  siteKey: string,
  lang: string,
};

Markup.defaultProps = {
  siteKey: null,
  lang: 'es-419',
};

export default Markup;
