import jsonToPropsQuotation from '../models-carousel/jsonToProps';

const groupedModelUnits = (units, picture_config) =>
  jsonToPropsQuotation({ elements: units, picture_config }).reduce((r, a) => {
    r[a.model_id] = r[a.model_id] || [];
    r[a.model_id].push(a);
    return r;
  }, Object.create(null));

export { groupedModelUnits };
