import React from 'react';
import { shape, string } from 'prop-types';
import classnames from 'classnames';
import applyClasses from '../../../../lib/apply-classes';
import ImageComponent from '../../../image/image-component';

const namespace = 'ui-vpp-highlighted-specs__filled-icon';

const HSFilledIcon = ({ className, picture }) => (
  <div
    aria-hidden="true"
    className={applyClasses({
      bg_color: picture.bg_color,
      className: classnames(className, namespace, { [`${namespace}--${picture.bg_type}`]: picture.bg_type }),
    })}
  >
    {picture.url && picture.url.src ? <ImageComponent image_type="picture" picture={picture} /> : null}
  </div>
);

HSFilledIcon.propTypes = {
  className: string,
  picture: shape({
    bg_color: string,
    bg_type: string,
    url: shape({ src: string }),
  }).isRequired,
};

HSFilledIcon.defaultProps = {
  className: null,
};

export default HSFilledIcon;
