/**
 * Module dependencies
 */
import React from 'react';

import { string } from 'prop-types';
import FeatureFlag from './feature-flag';
import getDisplayName from '../../lib/get-display-name';

/**
 * withFeatureFlag
 */
const withFeatureFlag = (name, options = {}) => WrappedComponent => {
  const WithFeatureFlag = ({ featureName, ...props }) => (
    <FeatureFlag name={name || featureName} {...options}>
      <WrappedComponent {...props} featureName={name || featureName} />
    </FeatureFlag>
  );

  WithFeatureFlag.displayName = `WithFeatureFlag(${getDisplayName(WrappedComponent)})`;

  WithFeatureFlag.propTypes = {
    featureName: string,
  };
  WithFeatureFlag.defaultProps = {
    featureName: null,
  };

  return WithFeatureFlag;
};

/**
 * Expose withFeatureFlag
 */
export default withFeatureFlag;
