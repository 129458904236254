import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import SellerInfoMapJsonToProps from '../../../../components/seller-info-label/json-to-props';

const FEATURE_NAME = 'seller_experiment';

const mapStateToProps = ({ components: { [FEATURE_NAME]: seller }, isFetching }, ownProps) =>
  SellerInfoMapJsonToProps({ ...seller, isFetching, ...ownProps });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

const component = Component => enhance(Component);

export default component;
