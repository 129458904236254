import React from 'react';
import { arrayOf, bool, shape, string, func } from 'prop-types';
import classnames from 'classnames';
import SpecOther from './spec-other';
import Button from '../action-collapsable/components/button';

const namespace = 'ui-pdp-specs-groups-collapsable';

const SpecGroupsCollapsable = ({ className, title, attributes, action, onClick, isCollapsed, restGroups }) => (
  <div className={classnames(namespace, className, { [`${namespace}--is-collapsed`]: isCollapsed })}>
    <SpecOther className={className} title={title} attributes={attributes} key={title} />
    {isCollapsed && <Button namespace={className} onClick={onClick} action={action} />}
    {!isCollapsed &&
      restGroups &&
      restGroups.map(spec => (
        <SpecOther className={namespace} title={spec.title} attributes={spec.attributes} key={spec.title} />
      ))}
  </div>
);

SpecGroupsCollapsable.propTypes = {
  className: string,
  title: string,
  attributes: arrayOf(
    shape({
      id: string,
      text: string.isRequired,
    }),
  ),
  action: shape({
    label: shape({
      text: string.isRequired,
      color: string,
    }),
  }),
  isCollapsed: bool,
  restGroups: arrayOf(
    shape({
      title: string.isRequired,
      attributes: arrayOf(
        shape({
          id: string,
          text: string.isRequired,
        }),
      ),
    }),
  ),
  onClick: func,
};

SpecGroupsCollapsable.defaultProps = {
  className: '',
  title: '',
  attributes: null,
  action: null,
  isCollapsed: false,
  restGroups: null,
  onClick: null,
};

export default SpecGroupsCollapsable;
