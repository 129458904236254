const jsonToProps = ({ available_quantity, bulkSaleQuantity, item_id, ...rest }) => {
  let selectedQuantity;

  if (bulkSaleQuantity?.state === 'VISIBLE' && Number.isInteger(bulkSaleQuantity.selected)) {
    selectedQuantity = bulkSaleQuantity.selected;
  } else {
    selectedQuantity = (available_quantity?.state !== 'HIDDEN' && available_quantity?.picker?.selected) || 1;
  }

  return {
    itemId: item_id || rest.form?.item_id,
    selectedQuantity,
    ...rest,
    form: {
      ...rest.form,
    },
  };
};

export default jsonToProps;
