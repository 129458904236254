import {
  FETCH_MODAL_UNREGISTERED_QUESTIONS_START,
  FETCH_MODAL_UNREGISTERED_QUESTIONS_ERROR,
  FETCH_MODAL_UNREGISTERED_QUESTIONS_COMPLETE,
} from '../../../../actions/vip/vis/types';
import { messagesErrorScreen } from '../../../../lib/get-message-error';

const fetchModalUnregisteredQuestionsStart = state => {
  if (state.unregistered_questions_modal) {
    delete state.unregistered_questions_modal.recaptchaPublicKey;
  }
  return {
    ...state,
    unregistered_questions: {
      ...state.unregistered_questions,
      recaptchaPublicKey: null,
    },
    message_modal: {},
  };
};

const fetchModalUnregisteredQuestionsComplete = (state, action) => ({
  ...state,
  unregistered_actions: action.payload.unregistered_actions,
  unregistered_questions_modal: { ...action.payload.unregistered_questions_modal },
  message_modal: {},
});

const fetchModalUnregisteredQuestionsError = state => {
  const { title, description } = messagesErrorScreen(state.siteId);
  const errorText = `${title} - ${description}`;
  return {
    ...state,
    unregistered_questions_modal: {},
    message_modal: {
      text: errorText,
      type: 'error',
    },
  };
};

const questionsModalUnregisteredMapper = {
  [FETCH_MODAL_UNREGISTERED_QUESTIONS_START]: fetchModalUnregisteredQuestionsStart,
  [FETCH_MODAL_UNREGISTERED_QUESTIONS_COMPLETE]: fetchModalUnregisteredQuestionsComplete,
  [FETCH_MODAL_UNREGISTERED_QUESTIONS_ERROR]: fetchModalUnregisteredQuestionsError,
};

export { fetchModalUnregisteredQuestionsStart };
export { fetchModalUnregisteredQuestionsComplete };
export { fetchModalUnregisteredQuestionsError };
export default questionsModalUnregisteredMapper;
