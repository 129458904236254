import {
  FETCH_CONTACT_INFO_ERROR,
  FETCH_COMPONENT,
  RENDER_FRAUD_MODAL,
  SHOW_FRAUD_SNACKBAR,
} from '../../../../actions/vip/vis/types';
import { messagesErrorScreen } from '../../../../lib/get-message-error';

const fetchContactInfoError = (state, action) => {
  const { component } = action;
  const { title, description } = messagesErrorScreen(state.siteId);
  const errorText = `${title} - ${description}`;
  return {
    ...state,
    components: {
      ...state.components,
      [component]: {
        ...state.components[component],
        message: {
          show: true,
          text: errorText,
          type: 'error',
          key: Math.random(),
        },
      },
    },
  };
};

const fetchComponent = (state, action) => {
  const { component, isFetchingData } = action;
  Object.keys(state).forEach(key => {
    if (state[key]) {
      if (state[key].messages) {
        delete state[key].messages;
      }
      if (state[key].recaptchaPublicKey) {
        delete state[key].recaptchaPublicKey;
      }
    }
  });

  return {
    ...state,
    [component]: {
      ...state[component],
      isFetchingData,
    },
    doContactAction: null,
  };
};

const renderFraudModal = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    onboarding: action.modal,
  },
});

const showFraudSnackbar = (state, action) => {
  const { component, display_message, success } = action.payload;

  Object.keys(state).forEach(key => {
    if (state[key] && state[key].messages) {
      delete state[key].messages;
    }
  });

  return {
    ...state,
    [component]: {
      ...state[component],
      messages: {
        text: display_message,
        type: success === true ? 'success' : 'error',
        key: Math.random(),
      },
    },
  };
};

const contactMapper = {
  [FETCH_CONTACT_INFO_ERROR]: fetchContactInfoError,
  [FETCH_COMPONENT]: fetchComponent,
  [RENDER_FRAUD_MODAL]: renderFraudModal,
  [SHOW_FRAUD_SNACKBAR]: showFraudSnackbar,
};
export { fetchContactInfoError, fetchComponent, renderFraudModal, showFraudSnackbar };
export default contactMapper;
