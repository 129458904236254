import { useMemo, startTransition, useCallback } from 'react';
import classnames from 'classnames';
import { bool, func, number, shape, string, arrayOf } from 'prop-types';
import { getFormattedIntFraction, getFormattedDecimalFraction } from '@vpp-frontend-components/price';
import * as Tracking from '../../lib/tracking';
import { Text } from '@andes/typography';
import { Pill } from '@andes/badge';
import { MoneyAmount } from '@andes/money-amount';
import HistoryService from '../../services/history';
import { runSchedulerTask } from '../../utils/validators';

const namespace = 'ui-vpp-price-per-quantity-shortcuts';
const shortcutNamespace = `${namespace}__shortcut`;

const QUANTITY_DEFAULT = 1;

const PricePerQuantityShortcuts = ({ className, id, onSetQuantity, shortcuts }) => {
  const handleClickShortcut = useCallback(shortcut => {
    const quantitySelected = shortcut.selected ? QUANTITY_DEFAULT : shortcut.quantity;

    startTransition(() => {
      onSetQuantity(quantitySelected);
      Tracking.trackQuantityEvent(shortcut.track, quantitySelected);
      runSchedulerTask(() => {
        HistoryService.pushParam('quantity', quantitySelected, true);
      }, 'background');
    });
  }, [onSetQuantity]);

  const handleKeydownShortcut = useCallback((e, shortcut) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      handleClickShortcut(shortcut);
    }
  }, [handleClickShortcut]);

  const validPrices = useMemo(
    () =>
      shortcuts?.every(shortcut => {
        const value = shortcut.price?.value;
        return typeof value === 'number' && value > 0;
      }),
    [shortcuts],
  );

  if (!validPrices) {
    return null;
  }

  return (
    <div className={classnames(namespace, className)} id={id}>
      {shortcuts.map(shortcut => (
        <div
          className={classnames(`${shortcutNamespace}`, { [`${shortcutNamespace}--selected`]: shortcut.selected })}
          key={shortcut.header.text}
          role="button"
          tabIndex="0"
          onClick={() => handleClickShortcut(shortcut)}
          onKeyDown={e => handleKeydownShortcut(e, shortcut)}
          data-testid={`shortcut-${shortcut.quantity}-units`}
        >
          <Text size="xs">{shortcut.header.text}</Text>
          <div className={classnames(`${shortcutNamespace}--price`)}>
            <MoneyAmount
              className={classnames(`${shortcutNamespace}--price-value`)}
              size={18}
              weight="semibold"
              centsType="superscript"
              symbol={shortcut.price.currency_symbol}
              currency_id={shortcut.price.currency_id}
              value={{
                fraction: getFormattedIntFraction(shortcut.price.value, '.'),
                cents: getFormattedDecimalFraction(shortcut.price.value),
              }}
            />
            <Text size="xs">{shortcut.price.suffix.text}</Text>
            <Text className={classnames(`${shortcutNamespace}--price-discount`)} size="s" color="positive">
              {` ${shortcut.discount_label.value}% off`}
            </Text>
          </div>
          {shortcut.best_offer && (
            <Pill
              className={classnames(`${shortcutNamespace}--best-offer`)}
              size="small"
              color="green"
              hierarchy="quiet"
              contentType="text"
              roundedCorners={['bottom-left']}
              data-testid={`shortcut-${shortcut.quantity}-best-offer`}
            >
              {shortcut.best_offer.text}
            </Pill>
          )}
        </div>
      ))}
    </div>
  );
};

PricePerQuantityShortcuts.propTypes = {
  className: string,
  shortcuts: arrayOf(
    shape({
      selected: bool,
      quantity: number,
      header: shape({
        text: string,
      }),
      best_offer: shape({
        text: string,
      }),
      price: shape({
        type: string,
        value: number,
        currency_symbol: string,
        currency_id: string,
        decimal_style: string,
        suffix: shape({
          text: string,
          accessibility_text: string,
        }),
      }),
      discount_label: shape({
        value: number,
      }),
      track: shape({
        melidata_event: shape({
          path: string,
          event_data: shape({
            item_id: string,
            quantity: string,
          }),
        }),
      }),
    }),
  ),
  id: string,
  onSetQuantity: func.isRequired,
};

PricePerQuantityShortcuts.defaultProps = {
  className: null,
  id: null,
  shortcuts: null,
};

export default PricePerQuantityShortcuts;
