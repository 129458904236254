import IconFactory from '../../../../components/icons/factory-builder';
import transformActionTarget from '../../../../utils/transformActionTarget';
import Iframe from '../../../../components/iframe';
import onIframeModalResize from '../../../../lib/on-iframe-modal-resize';

const jsonToProps = ({ ...props }) => {
  const actionTarget = props.action.target;
  const lowEndUrl = { ...props.action, target: actionTarget };

  transformActionTarget(lowEndUrl, { modal: false });
  return {
    url: lowEndUrl?.target,
    label: props.action?.label,
    modalClassName: 'ui-pdp-iframe-modal-top-quantity-summary',
    modalTitle: props.noTitle ? false : props.action?.modal_title,
    autoHeight: false,
    icon: props.action?.icon ? IconFactory(props.action.icon) : null,
    track: props.action?.track,
    closeTrack: props.action?.close_track,
    closeModalLabel: props.action?.close_modal_label,
    componentId: props.component_id,
    controlModalHeader: true,
    showCloseButton: true,
    actionTooltip: props.action_tooltip,
    onCloseModal: null,
    children: (
      <Iframe
        title={props.action?.label?.text}
        src={actionTarget}
        onMessage={onIframeModalResize(
          'vip:modal-content:loaded',
          'height',
          '.ui-pdp-iframe-modal',
          '.andes-modal__header',
          'width',
        )}
        scrolling="yes"
      />
    ),
  };
};

export default jsonToProps;
