/* eslint-env browser */
import React from 'react';
import { shape, arrayOf, number, string, func, bool, objectOf } from 'prop-types';
import classnames from 'classnames';
import customPropTypes from './utils/proptypes';
import useMShopsTrackingInfo from '../../hooks/use-mshops-tracking-info';
import { namespace } from './utils';
import DropdownPicker from './components/dropdown-picker';
import ExtraDecorationHiddenPicker from './components/extra-decoration-hidden-picker';
import DefaultPicker from './components/default-picker';
import { PICKER_TYPE } from './utils/types';

const VariationsDesktop = ({
  className,
  platform,
  pickers,
  selected_attributes,
  onSelectVariation,
  selectedQuantity,
  setPickersCollapse,
  hasDeferredErrors,
  shopsDefaultTrackingData,
  variation_id,
}) => {
  useMShopsTrackingInfo(shopsDefaultTrackingData, variation_id);

  const renderPicker = picker => {
    const pickerLabelId = `picker-label-${picker.id}`;
    const pickerSelectedSubtitleId = picker.selected_subtitle && `picker-selected-subtitle-${picker.id}`;

    if (picker.hide_extra_decoration) {
      return <ExtraDecorationHiddenPicker {...{ picker, pickerLabelId }} />;
    }
    if (picker.is_dropdown) {
      return (
        <DropdownPicker
          {...{
            picker,
            pickerLabelId,
            selected_attributes,
            platform,
            onSelectVariation,
            selectedQuantity,
            hasDeferredErrors,
          }}
        />
      );
    }

    return (
      <DefaultPicker
        {...{
          picker,
          pickerLabelId,
          pickerSelectedSubtitleId,
          platform,
          onSelectVariation,
          selectedQuantity,
          setPickersCollapse,
          selected_attributes,
          hasDeferredErrors,
        }}
      />
    );
  };

  const isTypeRefurbished = pickers.some(picker => picker.id === PICKER_TYPE.GRADING);
  return (
    <div className={classnames(namespace, className, { [`${namespace}--refurbished`]: isTypeRefurbished })}>
      {pickers.map(picker => (
        <React.Fragment key={picker.id}>{renderPicker(picker)}</React.Fragment>
      ))}
    </div>
  );
};

VariationsDesktop.propTypes = {
  className: string,
  platform: string,
  selected_attributes: objectOf(string),
  pickers: arrayOf(customPropTypes.picker).isRequired,
  selectedQuantity: number,
  onSelectVariation: func.isRequired,
  setPickersCollapse: func,
  hasDeferredErrors: bool,
  shopsDefaultTrackingData: shape({
    item_id: string,
    currency: string,
    price: number,
    title: string,
  }),
  variation_id: string,
};

VariationsDesktop.defaultProps = {
  selectedQuantity: 1,
  className: null,
  platform: null,
  selected_attributes: null,
  hasDeferredErrors: null,
  setPickersCollapse: null,
  variation_id: null,
  shopsDefaultTrackingData: null,
};

export default VariationsDesktop;
