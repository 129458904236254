import React from 'react';
import { ListItem } from '@andes/list';
import QuantityInput from '../../quantity-input/quantity-input';
import { quantityInputItemPropTypes } from '../model';

const QuantityInputItem = ({
  showInput = false,
  hasCustomOptions = false,
  listPickerRef,
  picker,
  quantitySelector,
  minimumQuantity,
  bulkSale = false,
  localQuantity,
  formatBulkQuantity,
  onClickListItem,
  inputRef = null,
  closeTooltip,
  onShowInput,
  customOptionsItems,
  yieldValue,
}) => {
  const restProps = {};
  if (!showInput) {
    restProps.primary = hasCustomOptions ? undefined : quantitySelector.selector.template.more;
    restProps.onClick = e => {
      if (hasCustomOptions) {
        e.preventDefault();
        return;
      }
      onShowInput(true);
    };
  }

  return (
    <ListItem
      key="link"
      data-testid="quantity-selector-item-more"
      selected={showInput && !hasCustomOptions}
      disabled={false}
      onKeyUp={closeTooltip}
      {...restProps}
      tabIndex={-1}
    >
      {(showInput || hasCustomOptions) && (
        <QuantityInput
          listPickerRef={listPickerRef}
          key={`list-item-quantity-${picker.title}`}
          confirm={quantitySelector.input.confirm_button.label.text}
          length={hasCustomOptions ? customOptionsItems.length : quantitySelector.selector.rows}
          noStockMessage={quantitySelector.input.error_message}
          minErrorMessage={quantitySelector.input.min_error_message}
          min={minimumQuantity}
          max={bulkSale ? quantitySelector.available_quantity * yieldValue : quantitySelector.available_quantity}
          quantity={bulkSale ? formatBulkQuantity(localQuantity) : localQuantity}
          bulkSale={bulkSale}
          placeholder={hasCustomOptions ? quantitySelector.input.placeholder : undefined}
          setQuantity={quantity => onClickListItem({ quantity, selected: localQuantity, typeInput: true })}
          title={hasCustomOptions ? undefined : quantitySelector.selector.template.title ?? picker.title}
          selectorTemplate={quantitySelector?.selector?.template}
          ref={inputRef}
        />
      )}
    </ListItem>
  );
};

QuantityInputItem.propTypes = quantityInputItemPropTypes;

export default QuantityInputItem;
