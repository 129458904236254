import React, { useContext, useState, useMemo, useEffect } from 'react';
import { bool, string, arrayOf, shape, func } from 'prop-types';
import classNames from 'classnames';
import StyledLabel from '../styled-label';
import messageSeeMore from '../../lib/get-see-more-message';
import StaticPropsContext from '../context/static-props';
import maxCharsDevice from '../../utils/max-chars-device';
import addEllipsisEnd from '../../utils/addEllipsisEnd';
import { getHighlightedText } from '../qadb/lib';
import { getRawData, getI18nValues } from '../../utils/TapeablesLinks';
import componentEnhance from '../../lib/component-enhance';

const namespace = 'ui-pdp';
const DEVICES = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
  TABLET: 'tablet',
};

const QuestionsTextCollapse = ({
  className,
  text: plainText,
  color,
  component,
  withDangerous,
  tapeableLinks,
  highlights,
  onClickQuestionsAi,
}) => {
  const { siteId, deviceType } = useContext(StaticPropsContext);
  const MAX_CHARS = maxCharsDevice(deviceType, component);
  const mustUseTapeableLinks = Object.keys(tapeableLinks)?.length > 0;

  const { arrayModel, charsAmount } = useMemo(
    () =>
      mustUseTapeableLinks
        ? getRawData({
            plainText,
            ...tapeableLinks,
            actionConfig: deviceType === DEVICES.DESKTOP ? { html_target: '_blank' } : {},
          })
        : {},
    [deviceType, mustUseTapeableLinks, plainText, tapeableLinks],
  );

  const [isCollapsed, setIsCollapsed] = useState(() => {
    const chars = mustUseTapeableLinks ? charsAmount : plainText.length;
    return chars > MAX_CHARS;
  });

  const textToShow = useMemo(() => {
    if (mustUseTapeableLinks) {
      const { text, values } = getI18nValues({
        maxChars: isCollapsed && MAX_CHARS,
        highlights,
        arrayModel,
      });

      return componentEnhance.jsx(text, values);
    }

    const list = getHighlightedText(plainText, highlights);
    const highlighted = list?.length > 0 && list;
    const colappsedText = addEllipsisEnd(plainText.substring(0, MAX_CHARS));

    return isCollapsed ? colappsedText : highlighted || plainText;
  }, [MAX_CHARS, arrayModel, highlights, isCollapsed, plainText, mustUseTapeableLinks]);

  useEffect(() => {
    if (onClickQuestionsAi && !isCollapsed) {
      onClickQuestionsAi();
    }
  }, [isCollapsed, onClickQuestionsAi]);

  return (
    <div
      className={classNames(
        { [`${namespace}-${component}__questions-list__container-answer__isCollpased`]: isCollapsed },
        { [`${namespace}-${component}__questions-list__container-answer__isNoCollapsed`]: !isCollapsed },
      )}
    >
      <StyledLabel
        className={classNames(`${namespace}-${component}__questions-list__answer-item__${className}`, {
          [`${namespace}-${component}__questions-list__answer-item__with-tapeables-links`]: mustUseTapeableLinks,
        })}
        as="span"
        font_size="SMALL"
        font_family="REGULAR"
        withDangerous={withDangerous}
        text={textToShow}
        color={color}
      />
      {isCollapsed && (
        <StyledLabel
          as="a"
          color="BLUE"
          font_size="XSMALL"
          font_family="REGULAR"
          className="questions-list__see-more-button"
          text={messageSeeMore(siteId)}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setIsCollapsed(false);
          }}
        />
      )}
    </div>
  );
};

QuestionsTextCollapse.propTypes = {
  className: string,
  component: string.isRequired,
  color: string,
  text: string.isRequired,
  withDangerous: bool,
  highlights: arrayOf(),
  tapeableLinks: shape(),
  onClickQuestionsAi: func,
};

QuestionsTextCollapse.defaultProps = {
  className: null,
  color: 'GRAY',
  withDangerous: false,
  tapeableLinks: {},
  onClickQuestionsAi: null,
};

export default QuestionsTextCollapse;
