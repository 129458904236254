import React from 'react';
import BaseImage from 'nordic/image';
import { shape, node } from 'prop-types';
import { PriceInline } from '@vpp-frontend-components/price';
import { StyledLabel } from '@vpp-frontend-components/styled-label';
import { Iframe } from '@vpp-frontend-components/iframe';
import { ActionModal } from '@vpp-frontend-components/action-modal';
import { Icon } from '@vpp-frontend-components/icon';
import { TooltipWrapper } from '@vpp-frontend-components/tooltip';
import { VppProvider as Provider } from '@vpp-frontend-components/vpp-context';
import LoadableIcon from '../../icons';
import APIService from '../../../services/api';
import HistoryService from '../../../services/history';

const deps = {
  StyledLabel,
  Iframe,
  PriceInline,
  ActionModal,
  Icon,
  TooltipWrapper,
  BaseImage,
  APIService,
  HistoryService,
};

const VppProvider = ({ children, ...props }) => (
  <Provider {...props} dependencies={{ ...deps, ...(props?.dependencies || {}) }} loadableIcon={LoadableIcon}>
    {children}
  </Provider>
);

VppProvider.propTypes = {
  children: node.isRequired,
  dependencies: shape({}),
  icons: shape({}),
  staticProps: shape({}),
};

export { VppProvider };
