import React from 'react';
import { bool, func, string, number, shape, oneOf } from 'prop-types';
import { Snackbar } from '@andes/snackbar';
import { validateSnackbarColor } from '../../utils/validators';

const HIDE_RUN_AFTER = 500; // Andes - Snackbar max time of css hide animation: $snackbar-animation-duration: 0.5s;

const SnackbarComponent = ({ delay, hide, message, show, type, style, action, className }) => {
  const onChange = () => {
    if (hide) {
      // eslint-disable-next-line @typescript-eslint/no-implied-eval
      window.setTimeout(hide, HIDE_RUN_AFTER);
      const currentURL = window.location.href;
      const url = new URL(currentURL);
      url.searchParams.delete('snackbar');
      window.history.replaceState({}, document.title, url.toString());
    }
  };

  const color = style ?? type;
  return (
    <Snackbar
      key="general-snackbar"
      className={className}
      show={show}
      message={message}
      delay={delay}
      color={validateSnackbarColor(color)}
      onChange={onChange}
      action={action}
    />
  );
};

SnackbarComponent.propTypes = {
  delay: number,
  hide: func,
  message: string.isRequired,
  show: bool,
  type: string,
  style: string,
  action: shape({
    text: string,
    position: oneOf(['right', 'bottom']),
    onClick: func,
  }),
  className: string,
};

SnackbarComponent.defaultProps = {
  delay: 2000,
  hide: null,
  type: 'success',
  style: null,
  show: false,
  action: null,
  className: null,
};

export default React.memo(SnackbarComponent);
