import { loadable } from 'nordic/lazy';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import jsonToProps from '../../../../components/message/jsonToProps';

const Message = loadable(() => import('../../../../components/message'));

const FEATURE_NAME = 'item_status_message';

const mapStateToProps = ({ components: { [FEATURE_NAME]: itemStatus } }) => jsonToProps(itemStatus);

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(Message);
