import React, { useContext } from 'react';
import { string, shape } from 'prop-types';
import classnames from 'classnames';
import StaticPropsContext from '../../context/static-props';
import Image from '../../image';
import StyledLabel from '../../styled-label';
import componentEnhance from '../../../lib/component-enhance';
import { trackEvent } from '../../../lib/tracking';

const namespace = 'ui-compats-highlight-card';

const Compats = ({ classname, border_color, bg_color, title, action, icon, description }) => {
  const { deviceType } = useContext(StaticPropsContext);
  const { url } = icon;
  const { track, target, label } = action;
  const mobileProps = {};

  /* istanbul ignore next */
  const onClickHandler = e => {
    e.preventDefault();
    const specs = document.querySelector(`#${target}`);
    if (track) {
      trackEvent(track);
    }
    if (specs) {
      specs.scrollIntoView({ behavior: 'smooth' });
    }
  };

  if (deviceType === 'mobile') {
    mobileProps.onClick = e => onClickHandler(e);
    mobileProps.role = 'button';
    mobileProps['data-testid'] = namespace;
  }

  const renderAction = () => (
    <a
      href={`#${target}`}
      role="button"
      data-testid={namespace}
      className={classnames(`${namespace}__action`, {
        [`ui-pdp-color--${label.color}`]: label.color,
        [`ui-pdp-size--${label.font_size}`]: label.font_size,
        [`ui-pdp-family--${label.font_family}`]: label.font_family,
      })}
      onClick={onClickHandler}
    >
      {label.text}
    </a>
  );

  const renderLabel = () => (
    <div
      className={classnames(`${namespace}__action`, {
        [`ui-pdp-color--${label.color}`]: label.color,
        [`ui-pdp-size--${label.font_size}`]: label.font_size,
        [`ui-pdp-family--${label.font_family}`]: label.font_family,
      })}
    >
      {label.text}
    </div>
  );

  const renderActionBlock = () => {
    switch (deviceType) {
      case 'mobile':
        return renderLabel();
      default:
        return renderAction();
    }
  };

  return (
    <div
      {...mobileProps}
      className={classnames(namespace, `${namespace}--${border_color}`, `ui-pdp-background-color--${bg_color}`)}
    >
      <div className={classnames(`${namespace}__title`, classname)}>
        <Image alt="" src={url.src} className={`${classname}-icon`} />
        <StyledLabel
          className={classnames(`${classname}__text`, {
            [`ui-pdp-color--${title.color}`]: title.color,
            [`ui-pdp-size--${title.font_size}`]: title.font_size,
            [`ui-pdp-family--${title.font_family}`]: title.font_family,
          })}
          key={title.text}
          as="p"
          text={componentEnhance.jsx(title.text, title.values)}
        />
      </div>
      <div className={`${namespace}__description`}>
        <StyledLabel
          className={classnames({
            [`ui-pdp-color--${description.color}`]: description.color,
            [`ui-pdp-size--${description.font_size}`]: description.font_size,
            [`ui-pdp-family--${description.font_family}`]: description.font_family,
          })}
          key={description.text}
          as="p"
          text={componentEnhance.jsx(description.text, description.values)}
        />
      </div>
      {action && label && renderActionBlock()}
    </div>
  );
};

Compats.propTypes = {
  classname: string,
  bg_color: string,
  border_color: string,
  title: shape({}).isRequired,
  description: shape({}).isRequired,
  action: shape({}).isRequired,
  icon: shape({}),
};

Compats.defaultProps = {
  classname: null,
  bg_color: 'GRAY_40',
  border_color: 'BLUE',
  icon: null,
};

export default Compats;
