import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import Bookmark from '../../../../components/bookmark';
import * as actions from '../../../../actions/vip';

const FEATURE_NAME = 'bookmark';
const WISHLIST_SAVE_BUTTON = 'wishlist_save_button';

const mapStateToProps = (state, ownProps) => ({
  isBookmarked: state.components[FEATURE_NAME]?.is_bookmarked,
  showLabel: ownProps.showLabel,
  csrfToken: ownProps.csrfToken,
  remove: state.components[FEATURE_NAME]?.delete,
  itemId: state.components[FEATURE_NAME]?.item_id,
  add: state.components[FEATURE_NAME]?.add,
  isFetching: state.components[FEATURE_NAME]?.isFetching,
  label: state.components[FEATURE_NAME]?.label,
  wishlist: {
    action: state.components[WISHLIST_SAVE_BUTTON]?.action,
    label: state.components[WISHLIST_SAVE_BUTTON]?.action?.label?.text,
    target:
      state.components[WISHLIST_SAVE_BUTTON]?.targetDynamic || state.components[WISHLIST_SAVE_BUTTON]?.action?.target,
    show: state.components[WISHLIST_SAVE_BUTTON]?.show,
    title: state.components[WISHLIST_SAVE_BUTTON]?.title,
    loading_text: state.components[WISHLIST_SAVE_BUTTON]?.action?.loading_text,
    gift_registry: state.components[WISHLIST_SAVE_BUTTON]?.gift_registry,
  },
});

const mapDispatchToProps = dispatch => ({
  onClick: e => {
    e.preventDefault();
    return dispatch(actions.toggleBookmark());
  },
  fetchBookmark: () => dispatch(actions.fetchBookmark()),
  showSnackbar: snackbar => dispatch(actions.showSnackbar(snackbar)),
  openWishlistModalBS: () => dispatch(actions.openWishlistModalBS()),
  closeWishlistModalBS: isBookmarked => dispatch(actions.closeWishlistModalBS(isBookmarked)),
  updateGiftRegistry: check => dispatch(actions.fetchUpdatedWishlists(check)),
  toggleGiftRegistryCheckbox: check => dispatch(actions.toggleGiftRegistryCheckbox(check)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(Bookmark);
