import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import HeaderTopBrand from '../../../../components/header-top-brand';

const FEATURE_NAME = 'header_description_top_brand_vip';

const mapStateToProps = ({ components: { [FEATURE_NAME]: props } }) => ({
  ...props,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(HeaderTopBrand);
