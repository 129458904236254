import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';

const FEATURE_NAME = 'apparel_size_specs_preview';

const mapStateToProps = ({ components: { [FEATURE_NAME]: sizechartPreview } }) => {
  const { chart_preview_data, size_column_name, is_new_size_chart, ...rest } = sizechartPreview;
  return {
    ...rest,
    chartPreviewData: chart_preview_data,
    sizeColumnName: size_column_name,
    isNewSizeChart: is_new_size_chart,
  };
};

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

const apparelSizeSpecsPreview = ApparelSizeSpecsPreview => enhance(ApparelSizeSpecsPreview);

export default apparelSizeSpecsPreview;
