const getObjComponent = origin => (nameComponent, payload) =>
  payload
    ? {
        [nameComponent]: {
          ...origin[nameComponent],
          ...payload,
          component_id: nameComponent,
        },
      }
    : {};

const getUpdatedCompatsState = (state, action) => {
  const {
    head: headPayload,
    content_left: contentLeftPayload,
    short_description: shortDescriptionPayload,
  } = action.payload.components;
  const compatsWidgetNewState = headPayload.find(item => item.id === 'compats_widget');
  const getNewState = getObjComponent(state.components);

  return {
    ...state,
    components: {
      ...state.components,
      ...getNewState('compats_feedback', headPayload.find(item => item.id === 'compats_feedback')),
      ...getNewState(
        'technical_specifications',
        contentLeftPayload.find(item => item.id === 'technical_specifications'),
      ),
      ...getNewState('highlighted_specs_attrs', contentLeftPayload.find(item => item.id === 'highlighted_specs_attrs')),
      ...getNewState(
        'highlighted_specs_attrs_swap',
        contentLeftPayload.find(it => it.id === 'highlighted_specs_attrs_swap'),
      ),
      ...getNewState(
        'highlighted_specs_attrs_third_swap',
        contentLeftPayload.find(it => it.id === 'highlighted_specs_attrs_third_swap'),
      ),
      header: {
        ...action.payload.components.short_description.find(item => item.id === 'header'),
      },
      seller: {
        ...state.components.seller,
        components: [],
        ...shortDescriptionPayload.find(item => item.id === 'seller'),
      },
      compats: {
        ...state.components.compats,
        components: [],
        ...shortDescriptionPayload.find(item => item.id === 'compats'),
      },
      compats_widget:
        compatsWidgetNewState === undefined
          ? {
              ...state.components.compats_widget,
              state: 'HIDDEN',
            }
          : {
              ...state.components.compats_widget,
              ...compatsWidgetNewState,
              state: compatsWidgetNewState?.state || 'VISIBLE',
            },
    },
  };
};

const getUpdatedCompatsMobileState = (state, action) => {
  const { components: componentsPayload } = action.payload;
  const compats_widget_payload = action.payload.components.find(item => item.id === 'compats_widget');
  const getNewState = getObjComponent(state.components);

  return {
    ...state,
    components: {
      ...state.components,
      ...getNewState('all_features', action.payload.components.find(item => item.id === 'all_features')),
      ...getNewState('compats_feedback', action.payload.components.find(item => item.id === 'compats_feedback')),
      ...getNewState('highlighted_specs_attrs', componentsPayload.find(item => item.id === 'highlighted_specs_attrs')),
      ...getNewState(
        'highlighted_specs_attrs_swap',
        componentsPayload.find(it => it.id === 'highlighted_specs_attrs_swap'),
      ),
      ...getNewState(
        'highlighted_specs_attrs_third_swap',
        componentsPayload.find(it => it.id === 'highlighted_specs_attrs_third_swap'),
      ),
      ...getNewState('seller', componentsPayload.find(it => it.id === 'seller')),
      ...getNewState('compats', componentsPayload.find(it => it.id === 'compats')),
      header: {
        ...action.payload.components.find(item => item.id === 'header'),
      },
      compats_widget:
        action.payload.components.find(item => item.id === 'compats_widget') === undefined
          ? {
              ...state.components.compats_widget,
              state: 'HIDDEN',
            }
          : {
              ...state.components.compats_widget,
              ...compats_widget_payload,
              state: compats_widget_payload?.state || 'VISIBLE',
            },
    },
  };
};

const getUpdatedCompatsPdpState = (state, action) => {
  const { components } = action.payload;
  const compatsWidgetNewState = components.find(item => item.id === 'compats_widget');
  const getNewState = getObjComponent(state.components);

  return {
    ...state,
    components: {
      ...state.components,
      ...getNewState('compats_feedback', components.find(item => item.id === 'compats_feedback')),
      ...getNewState('technical_specifications', components.find(item => item.id === 'technical_specifications')),
      ...getNewState('highlighted_specs_attrs', components.find(item => item.id === 'highlighted_specs_attrs')),
      ...getNewState('highlighted_specs_attrs_swap', components.find(it => it.id === 'highlighted_specs_attrs_swap')),
      ...getNewState(
        'highlighted_specs_attrs_third_swap',
        components.find(it => it.id === 'highlighted_specs_attrs_third_swap'),
      ),
      header: {
        ...action.payload.components.find(item => item.id === 'header'),
      },
      seller: {
        ...state.components.seller,
        components: [],
        ...action.payload.components.find(item => item.id === 'seller'),
      },
      compats: {
        ...state.components.compats,
        components: [],
        ...components.find(item => item.id === 'compats'),
      },
      compats_widget:
        compatsWidgetNewState === undefined
          ? {
              ...state.components.compats_widget,
              state: 'HIDDEN',
            }
          : {
              ...state.components.compats_widget,
              ...compatsWidgetNewState,
              state: compatsWidgetNewState?.state || 'VISIBLE',
            },
    },
  };
};

export { getUpdatedCompatsState, getUpdatedCompatsMobileState, getUpdatedCompatsPdpState };
