import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import GroupedHeader from '../../../../components/grouped-header/grouped-header';

const FEATURE_NAME = 'grouped_header_item';

const mapStateToProps = (initialState, ownProps) => {
  const { [FEATURE_NAME]: groupedHeader, header_store, header_top_brand_vip } = initialState.components.fixed;
  const { compats_widget, compats_feedback } = initialState.components;
  const hasCompats = (compats_feedback && compats_feedback.state === 'VISIBLE') || !!compats_widget;
  const components = groupedHeader.components.map(id => initialState[id]);
  const isTopBrand = header_top_brand_vip && header_top_brand_vip.state === 'VISIBLE';
  return { components, ...ownProps, withStoreHeader: !!header_store || isTopBrand, withCompatsWidget: hasCompats };
};

const enhance = compose(
  withFeatureFlag(`fixed.${FEATURE_NAME}`),
  connect(mapStateToProps),
);

export default enhance(GroupedHeader);
