export const NAMESPACE = 'ui-vpp-questions-ai';

export const ACTION_TYPES = {
  BUY_NOW: 'BUY_NOW',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  ACTION_ROW: 'action_row',
  HIERARCHY_QUIET: 'quiet',
  HIERARCHY_LOUD: 'loud',
  HIERARCHY_TRANSPARENT: 'transparent',
  HIERARCHY_ROW: 'actionRow',
  ADDITIONAL_INFO: 'additional_info',
  LOYALTY: 'loyalty',
};

export const MAKE_ACTION_TYPES = {
  ASK_SELLER: 'ask_seller',
  ASK_AGAIN: 'ask_again',
};

export const IOS_EVENT_DISPATCHER_TYPE = 'vpp_iframe_bottomsheet';

// Esto deberían validarlo desde la librería de insurtech que se está utilizando para ios, pero como el cambio va a demorar lo hacemos temporalmente en el front
export const HAS_SAFE_AREA = [
  'iPhone10,3',
  'iPhone X Global',
  'iPhone10,6',
  'iPhone X GSM',
  'iPhone11,2',
  'iPhone XS',
  'iPhone11,4',
  'iPhone XS Max',
  'iPhone11,6',
  'iPhone XS Max Global',
  'iPhone11,8',
  'iPhone XR',
  'iPhone12,1',
  'iPhone 11',
  'iPhone12,3',
  'iPhone 11 Pro',
  'iPhone12,5',
  'iPhone 11 Pro Max',
  'iPhone13,1',
  'iPhone 12 Mini',
  'iPhone13,2',
  'iPhone 12',
  'iPhone13,3',
  'iPhone 12 Pro',
  'iPhone13,4',
  'iPhone 12 Pro Max',
  'iPhone14,2',
  'iPhone 13 Pro',
  'iPhone14,3',
  'iPhone 13 Pro Max',
  'iPhone14,4',
  'iPhone 13 Mini',
  'iPhone14,5',
  'iPhone 13',
  'iPhone14,7',
  'iPhone 14',
  'iPhone14,8',
  'iPhone 14 Plus',
  'iPhone15,2',
  'iPhone 14 Pro',
  'iPhone15,3',
  'iPhone 14 Pro Max',
  'iPhone15,4',
  'iPhone 15',
  'iPhone15,5',
  'iPhone 15 Plus',
  'iPhone16,1',
  'iPhone 15 Pro',
  'iPhone16,2',
  'iPhone 15 Pro Max',
];

export const NATIVE_OS_VERSIONS = {
  NEW_ANDROID_VERSION: '10.319.0',
};

export const DOTS_AMOUNT = 3;

export const BAD_FEEDBACK_COMPONENT = {
  TITLE: 'title',
  QUESTION: 'question',
  ACTIONS: 'bad_feedback_actions',
};
